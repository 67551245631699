import React from 'react';
import { TextChecker } from '../../utils/RichTextConverter';
import {
  Wrapper,
  Title,
  Subtitle,
  Text,
  Container,
} from '../../styles/partials/Introduction';

interface IntroductionProps {
  title?: string;
  subtitle?: string;
  introduction?: string;
  alignText?: 'right' | 'left' | 'center';
}

const Introduction = ({
  title,
  subtitle,
  introduction,
  alignText,
}: IntroductionProps) => (
  <Wrapper container xs={12} justify="center" alignContent="center">
    <Container xs={12} item>
      {title && title.length > 3 && <Title>{title}</Title>}
      {subtitle && subtitle.length > 3 && <Subtitle>{subtitle}</Subtitle>}
      {introduction !== null && (
        <Text alignText={alignText}>{TextChecker(introduction)}</Text>
      )}
    </Container>
  </Wrapper>
);

export default Introduction;
