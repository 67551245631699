import { ButtonMapper } from "./partials/ButtonMapper";
import { BackgroundMapper } from "./partials/BackgroundMapper";
import { IntroOutro } from "./partials/IntroOutro";
import { Timestamps } from "./partials/TimestampMapper";

export const IconsMapper = (obj: any) => {
  const mapped = {
    type: "icons",
    ...BackgroundMapper(obj),
    ...IntroOutro(obj),
    ...Timestamps(obj),
    button: ButtonMapper(obj),
    images: [
      {
        icon: obj.icon1,
        text: obj.iconText1,
      },
      {
        icon: obj.icon2,
        text: obj.iconText2,
      },
      {
        icon: obj.icon3,
        text: obj.iconText3,
      },
    ],
  };

  function filterArrayValues(arr: any) {
    arr = arr.filter(isEligible);
    return arr;
  }

  function isEligible(value: any) {
    if (value.icon !== null || value.text !== null) {
      return value;
    }
  }
  mapped.images = filterArrayValues(mapped.images);

  return mapped;
};
