export const CARE_COMPONENT = `
  background1,
  background2,
  background3,
  background4,
  background5,
  example1,
  example2,
  example3,
  example4,
  example5,
  img1,
  img2,
  img3,
  img4,
  img5,
  location1,
  location2,
  location3,
  location4,
  location5,
  text1,
  text2,
  text3,
  text4,
  text5,
  title1,
  title2,
  title3,
  title4,
  title5
 `;
