import React from "react";
import {DialogContent} from "@material-ui/core";
import {
    ContentContainer,
    Title,
    Subtitle,
    ContentText,
    MediaLetterContainer, LogoContainer, MediaLogoContainer,
} from "../../styles/style";
import NewsletterIcon from "../../assets/newsletter-notification.png";
import PflegeLogo from "../../assets/logo-pflegepartner-dunkelblau-tuerkis.svg";

const content = {
    completed: {
        title: "Sie haben eine E-Mail erhalten!",
        approved: {
            subtitle: "Jetzt Ihre Newsletter-Anmeldung bestätigen",
            content: "Nur noch ein kleiner Schritt - und Sie erhalten regelmäßig interessante Informationen " +
                "rund um das Thema Pflege. Klicken Sie dafür bitte auf den Bestätigungs-Link in unserer E-Mail.",
        },
        notApproved: {
            subtitle: "Sie haben keine E-Mail erhalten?",
            content: "Dann schauen Sie bitte in Ihrem Spam-Ordner nach, ob unsere E-Mail dorthin verschoben wurde. " +
                "Wir hoffen, dass Sie mit unserem Newsletter nützliche Antworten auf Ihre Fragen erhalten.",
        },
    }
}

export function NewsletterCompleted() {
    return (
        <>
            <Title id="form-dialog-title">
                {content.completed.title}
                <MediaLetterContainer src={NewsletterIcon} alt="newsletterIcon"/>
            </Title>
            <DialogContent>
                <Subtitle id="form-dialog-title">
                    {content.completed.approved.subtitle}
                </Subtitle>
                <ContentContainer container>
                    <ContentText>
                        {content.completed.approved.content}
                    </ContentText>
                </ContentContainer>
                <Subtitle id="form-dialog-title">
                    {content.completed.notApproved.subtitle}
                </Subtitle>
                <ContentContainer container>
                    <ContentText>
                        {content.completed.notApproved.content}
                    </ContentText>
                </ContentContainer>
                <LogoContainer container>
                    <Subtitle id="form-dialog-title">
                        Ihr
                    </Subtitle>
                    <MediaLogoContainer src={PflegeLogo} alt="logo"/>
                    <Subtitle id="form-dialog-title">
                        - Team
                    </Subtitle>
                </LogoContainer>
            </DialogContent>
        </>
    )
}
