// Import Dependencies
import React from 'react';
import styled, { css } from 'styled-components';
import { Grid } from '@material-ui/core';
// Import Components
import { BackgroundConverter } from '../../utils/BackgroundConverter';
import ButtonsContainer from '../partials/ButtonsContainer';
import TextChecker from '../../utils/RichTextConverter';
import SwiperWrapper from '../partials/SwiperWrapper';
// import Variabel
import {
  colors,
  Fonts,
  MDBreakPoint,
  OuterWrapperWidth,
  XSBreakPoint,
} from '../../Theme';
import { H2, H3, H1 } from '../../styles/Styles';

interface PflegeProps {
  background: { type: string; url: string };
  item: any;
  closing?: any;
  func?: any;
}
interface CareProps {
  title: string;
  introduction: any;
  elements: {
    icon: string;
    title: string;
    text: any;
    example: any;
    content: any;
    background: { src: string; alt: string; location: string };
  }[];
}
interface FinancialProps {
  title: string;
  introduction: any;
  closing: any;
  elements: {
    icon: string;
    title: string;
    text: any;
    monthlyCost: string;
    differenceCost: string;
    differenceText: any;
    content: any;
    background: { src: string; alt: string; location: string };
  }[];
}

interface HeaderInterface {
  title: string;
  introduction: string;
}

interface WrapperProps {
  background: { type: string; url: string };
}
const Wrapper = styled(Grid)<WrapperProps>`
  padding: 50px 0;
  ${({ background }) => BackgroundConverter(background.type, background.url)}}
`;

const margin = `
margin-right: auto;
margin-left: auto;`;

const Title = styled(H2)`
  color: ${colors.darkBlue};
  text-align: center;
  margin-bottom: 10px;
`;

const Outro = styled(Grid)`
  padding: 50px 0 0 0;
  @media (max-width: ${MDBreakPoint}) {
    padding: 0;
  }
  margin: 0 auto;
  color: black;
  font: ${Fonts.fontText};
  text-align: center;
`;

const Text = styled.p`
  text-align: left;
  max-height: 100%;
  ${margin};
  max-width: 1000px;
  margin-bottom: 40px !important;
  text-overflow: ellipsis;
  overflow: hidden;
  color: ${colors.black};
  font: ${Fonts.fontText};
`;

const HeaderWrapper = styled.div`
  max-width: 1080px;
  margin: 0 auto;
`;

const OuterWrapper = styled.div`
  max-width: ${OuterWrapperWidth};
  @media (max-width: ${MDBreakPoint}) {
    display: none;
  }
`;

const ImageWrapper = styled.div`
  ${margin};
  height: 180px;
  max-width: 170px;
  position: relative;
`;

const Separator = styled.div`
  border-bottom: 2px solid ${colors.turkis};
  max-width: 150px;
  ${margin};
`;

const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  z-index: 1;
  bottom: 0;
`;

const PflegeTitle = styled(H3)`
  text-align: center;
  color: ${colors.darkBlue};
  max-width: 100%;
  max-height: 30px;
  ${margin};
`;

const PflegeContent = styled.p<{
  height: number;
  color: string;
}>`
  text-align: center;
  max-width: 1080px;
  height: ${(props) => props.height}px;
  ${margin};
  color: ${(props) => (props.color ? props.color : '')};
  font: ${Fonts.fontText};
  @media (max-width: 1500px) {
    height: ${(props) => props.height + 20}px;
  }
`;

const PflegeDifference = styled(H1)`
  ${margin};
  text-align: center;
  max-width: 100%;
  max-height: 50px;
  color: ${colors.darkBlue};
`;

const PflegeDifferenceText = styled.p`
  ${margin};
  max-width: 100%;
  max-height: 40px;
  text-align: center;
  font: ${Fonts.fontTextSmall};
  color: ${colors.darkBlue};
`;

const CareItem = styled(Grid)`
  margin-left: 40px !important;
`;

interface BackgroundProps {
  src?: string;
  alt?: string;
  location?: string;
}

const Background = styled.img<BackgroundProps>`
  bottom: 10px;
  height: 140px;
  z-index: 0;
  position: absolute;
  ${({ location }) => (location === 'left'
    ? css`
          left: 70px;
        `
    : css`
          right: 120px;
        `)}
`;
const Bold = styled.div`
  font-weight: 800;
`;
const MobileView = styled.div`
  display: none;
  @media (max-width: ${MDBreakPoint}) {
    width: 95vw;
    padding-left: 20px;
    display: block;
  }
  @media (max-width: ${XSBreakPoint}) {
    display: block;
  }
`;

const SwiperWrapperStyled = styled(SwiperWrapper)`
  @media (max-width: ${XSBreakPoint}) {
    width: 350px;
    display: block;
  }
`;

const Header = ({ title, introduction }: HeaderInterface) => (
  <HeaderWrapper>
    <Title>{title}</Title>
    <Text>{TextChecker(introduction)}</Text>
  </HeaderWrapper>
);

const FinancialCare = ({
  title,
  introduction,
  closing,
  elements,
}: FinancialProps) => {
  const tabs = elements.map((element) => ({
    text: element.title,
    content: element.text,
    icon: element.icon,
    costs: {
      monthlyCost: element.monthlyCost,
      differenceCost: element.differenceCost,
      differenceText: element.differenceText,
    },
  }));
  return (
    <>
      <OuterWrapper>
        <Header title={title} introduction={introduction} />
        <Grid
          container
          item
          direction="row"
          alignItems="flex-start"
          justify="center"
          xs={12}
          style={{ margin: '0 auto' }}
        >
          {elements.map((pflege, index) => (
            <CareItem
              container
              item
              key={index}
              direction="row"
              alignItems="center"
              justify="center"
              xs={12}
              lg={2}
            >
              <div>
                <ImageWrapper>
                  <Image src={pflege.icon} />
                  <Background
                    alt={pflege.background.alt}
                    src={pflege.background.src}
                    location={pflege.background.location}
                  />
                </ImageWrapper>
                <PflegeTitle>
                  <PflegeTitle>{pflege.title}</PflegeTitle>
                </PflegeTitle>
                <PflegeContent height={70} color="">
                  {pflege.text}
                </PflegeContent>
                <Separator />
                <PflegeContent height={20} color="">
                  {pflege.monthlyCost}
                </PflegeContent>
                <PflegeDifference>{pflege.differenceCost}</PflegeDifference>
                <PflegeDifferenceText>
                  {pflege.differenceText}
                </PflegeDifferenceText>
              </div>
            </CareItem>
          ))}
        </Grid>
        <Outro>{TextChecker(closing)}</Outro>
      </OuterWrapper>
      <MobileView>
        <Header title={title} introduction={introduction} />
        <SwiperWrapperStyled elements={tabs} isPflege />
        <Outro>{TextChecker(closing)}</Outro>
      </MobileView>
    </>
  );
};

const CareLevel = ({ title, introduction, elements }: CareProps) => {
  const careLevelItems = elements.map((element) => ({
    text: element.title,
    icon: element.icon,
    content: element.example,
    example: element.text,
  }));
  return (
    <>
      <OuterWrapper>
        <Header title={title} introduction={introduction} />
        <Grid
          container
          item
          direction="row"
          alignItems="flex-start"
          justify="center"
        >
          {elements.map((pflege, index) => (
            <CareItem
              container
              item
              key={index}
              direction="row"
              alignItems="center"
              justify="center"
              xs={12}
              lg={2}
            >
              <div>
                <ImageWrapper>
                  <Image src={pflege.icon} />
                  <Background
                    alt={pflege.background.alt}
                    src={pflege.background.src}
                    location={pflege.background.location}
                  />
                </ImageWrapper>
                <PflegeTitle>
                  <PflegeTitle>{pflege.title}</PflegeTitle>
                </PflegeTitle>
                <PflegeContent height={145} color="">
                  {pflege.text}
                </PflegeContent>
                <Separator />
                <PflegeContent height={190} color={colors.darkBlue}>
                  <Bold>Beispiel</Bold>
                  {TextChecker(pflege.example)}
                </PflegeContent>
              </div>
            </CareItem>
          ))}
        </Grid>
      </OuterWrapper>
      <MobileView>
        <Header title={title} introduction={introduction} />
        <SwiperWrapperStyled elements={careLevelItems} isPflege />
      </MobileView>
    </>
  );
};

const Partial = styled(Grid)``;

const PflegeComponent = ({ item, background, func }: PflegeProps) => {
  if (item.button) {
    if (func) item.button[0].func = func;
  }
  return (
    <Wrapper
      container
      direction="column"
      alignItems="center"
      background={background}
    >
      <Partial item>
        {item.variant === 'finance' ? (
          <FinancialCare {...item} background={background} />
        ) : (
          <CareLevel {...item} background={background} />
        )}
      </Partial>
    </Wrapper>
  );
};

export default PflegeComponent;
