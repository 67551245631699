// Import Dependencies
import React, {Dispatch, SetStateAction, useEffect} from 'react';
// Import Components
import { useLocation } from 'react-router';
import { CardComponent } from '../components/CardComponent';
import PflegeComponent from '../components/PflegeComponent';
import SliderComponent from '../components/SliderComponent';
import TabsComponent from '../components/TabsComponent';
import { StepsComponent } from '../components/StepsComponent';
import { TextComponent } from '../components/TextComponent';
import { StageComponent } from '../components/StageComponent';
import { ProcessComponent } from '../components/ProcessComponent';
import { Finanzieren } from '../../graphql/queryTemplate/Finanzieren';
import { metaDataHandler } from '../../utils/MetaInfoHandler';
import { Metadata } from '../../graphql/queryTemplate/Metadata';
import { PagePlaceholder } from '../loaders/PagePlaceholder';
import { Wrapper, Module } from '../../styles/pages/FinanzierenPage';
import { Scroller } from '../../utils/Scroller';

interface FinanzierenProps {
  handleOpenOpenZip: () => void;
  setValue: Dispatch<number>;
  zipFunc: {
    setTitle: any;
    setText: any;
    setButtonText: any;
    setUkv: any;
    setVkb: any;
  };
  setOpenDialog: Dispatch<SetStateAction<boolean>>;
}
const FinanzierenPage = ({ handleOpenOpenZip, zipFunc, setValue, setOpenDialog }: FinanzierenProps) => {
  const PageData = Finanzieren();

  const metaTitle = 'Pflege finanzieren | PflegePartner';
  const metaDescription = 'Jeder Pflegefall ist anders. Ein besonders belastendes Thema: die Kosten für die Pflege. Hier erfahren Sie, welche Finanzierungsmöglichkeiten Sie haben.';
  const metaProps = {
    url: 'https://pflegepartner.de/pflege-finanzieren',
    title: metaTitle,
    description: metaDescription,
  };
  metaDataHandler(
    metaProps,
  );

  const MetaData = Metadata();
  !MetaData.isLoading
  && metaDataHandler(MetaData.finanzieren);

  const handleZipCards = () => {
    zipFunc.setTitle('Beitrag berechnen');
    zipFunc.setText(
      'Um Sie zu dem passenden Angebot weiterzuleiten, benötigen wir noch kurz Ihre Postleitzahl: ',
    );
    zipFunc.setButtonText('Senden');
    zipFunc.setUkv(
      'https://www.ukv.de/content/pflegeversicherung/pflegezusatzversicherung/rechner/',
    );
    zipFunc.setVkb(
      'https://www.vkb.de/content/versicherungen/pflegezusatzversicherung/',
    );
    handleOpenOpenZip();
  };
  const handleZipPflege = () => {
    zipFunc.setTitle('Pflegegrad berechnen');
    zipFunc.setText(
      'Den Pflegegrad-Rechner bieten wir gemeinsam mit unseren Partnern an. Um fortzufahren, geben Sie bitte Ihre Postleitzahl ein:',
    );
    zipFunc.setButtonText('Senden');
    zipFunc.setUkv(
      'https://pflegepartner.ukv.de/ppd/#/vkb/public/rechner/start',
    );
    zipFunc.setVkb(
      'https://pflegepartner.vkb.de/ppd/#/vkb/public/rechner/start',
    );
    handleOpenOpenZip();
  };
  const handleZipRechner = () => {
    zipFunc.setTitle('Pflegebudget berechnen');
    zipFunc.setText(
      'Den Pflegebudget-Rechner bieten wir gemeinsam mit unseren Partnern an. Um fortzufahren, geben Sie bitte Ihre Postleitzahl ein:',
    );
    zipFunc.setButtonText('Senden');
    zipFunc.setUkv(
      'https://pflegepartner.ukv.de/ppd/#/vkb/public/rechnerpb/start',
    );
    zipFunc.setVkb(
      'https://pflegepartner.vkb.de/ppd/#/vkb/public/rechnerpb/start',
    );
    handleOpenOpenZip();
  };
  const handleZipProzess = () => {
    zipFunc.setTitle('Antrag auf Pflegeleistungen');
    zipFunc.setText(
      'Um Sie zu dem richtigen Versicherungsunternehmen weiterzuleiten, benötigen wir noch kurz Ihre Postleitzahl: ',
    );
    zipFunc.setButtonText('Senden');
    zipFunc.setUkv(
      'https://pflegepartner.ukv.de/ppd/#/ukv/portal/landing-antrag',
    );
    zipFunc.setVkb(
      'https://pflegepartner.vkb.de/ppd/#/vkb/portal/landing-antrag',
    );
    handleOpenOpenZip();
  };
  const handleZipStep = () => {
    zipFunc.setTitle('Pflegebudget berechnen');
    zipFunc.setText(
      'Den Pflegebudget-Rechner bieten wir gemeinsam mit unseren Partnern an. Um fortzufahren, geben Sie bitte Ihre Postleitzahl ein: ',
    );
    zipFunc.setButtonText('Senden');
    zipFunc.setUkv(
      'https://pflegepartner.ukv.de/ppd/#/vkb/public/rechnerpb/start',
    );
    zipFunc.setVkb(
      'https://pflegepartner.vkb.de/ppd/#/vkb/public/rechnerpb/start',
    );
    handleOpenOpenZip();
  };
  const handleZipSlider1 = () => {
    zipFunc.setTitle('Kostenlos registrieren');
    zipFunc.setText(
      '„Mein PflegePartner” bieten wir gemeinsam mit unseren Partnern an. Um fortzufahren, geben Sie bitte Ihre Postleitzahl ein:',
    );
    zipFunc.setButtonText('Senden');
    zipFunc.setUkv(
      'https://pflegepartner.ukv.de/ppd/#/meinpflegepartner/public/registrierung',
    );
    zipFunc.setVkb(
      'https://pflegepartner.vkb.de/ppd/#/meinpflegepartner/public/registrierung',
    );
    handleOpenOpenZip();
  };
  const handleZipSlider2 = () => {
    zipFunc.setTitle('Zum Login');
    zipFunc.setText(
      '„Mein PflegePartner” bieten wir gemeinsam mit unseren Partnern an. Um fortzufahren, geben Sie bitte Ihre Postleitzahl ein:',
    );
    zipFunc.setButtonText('Senden');
    zipFunc.setUkv(
      'https://anmeldung.vkb.de/auth/realms/pflegepartner-test/protocol/openid-connect/auth?client_id=pflegeportal-frontend&redirect_uri=https%3A%2F%2Fpflegepartner.ukv.de%2Fppd%2F%23%2Fukv%2Fportal%2Fdashboard&state=b6b7dfb2-75d0-441c-844a-7bc1a40feaa0&nonce=0ebeb72a-6a33-4fc4-99d5-086b5a38b1cc&response_mode=fragment&response_type=code&scope=openid&login_hint=ukv',
    );
    zipFunc.setVkb(
      'https://anmeldung.vkb.de/auth/realms/pflegepartner-test/protocol/openid-connect/auth?client_id=pflegeportal-frontend&redirect_uri=https%3A%2F%2Fpflegepartner.vkb.de%2Fppd%2F%23%2Fvkb%2Fportal%2Fdashboard&state=be2d4501-836d-4972-8c69-b2f51f233638&nonce=89f75c9c-688c-48c5-8b39-d6dfb8d93435&response_mode=fragment&response_type=code&scope=openid&login_hint=vkb',
    );
    handleOpenOpenZip();
  };
  const { pathname, hash } = useLocation();
  useEffect(() => setValue && setValue(1), []);
  return (
    <Wrapper container xs={12} direction="column" id="top">
      {PageData.isLoading ? (
        <PagePlaceholder />
      ) : (
        <>
          <Scroller hash={hash} />
          <StageComponent {...PageData.nav[0]} setOpenDialog={setOpenDialog}/>
          <Wrapper container xs={12} direction="column" alignItems="center">
            <Module id="finanzierung" item xs={12}>
              <TabsComponent {...PageData.tabs[0]} isRound />
            </Module>
            <Module item xs={12}>
              <TextComponent {...PageData.textNew[0]} />
            </Module>
            <Module id="leistungen" item xs={12}>
              <ProcessComponent
                {...PageData.process[0]}
                func={handleZipProzess}
                func2={handleZipStep}
              />
            </Module>
            <Module item xs={12}>
              <TextComponent {...PageData.text[0]} />
            </Module>
            <Module id="pflegegrad" item xs={12}>
              <PflegeComponent {...PageData.care[0]} func={handleZipPflege} />
            </Module>
            <Module id="gutachterbesuch" item xs={12}>
              <StepsComponent {...PageData.steps[0]} />
            </Module>
            <Module item xs={12}>
              <TextComponent {...PageData.textTwo[0]} func={handleZipRechner} />
            </Module>
            <Module id="belastung" item xs={12}>
              <PflegeComponent {...PageData.finance[0]} />
            </Module>
            <Module id="zusatzversicherung" item xs={12}>
              <CardComponent {...PageData.cards[0]} func={handleZipCards} />
            </Module>
            <Module id="zusatzversicherung" item xs={12} />
          </Wrapper>
        </>
      )}
    </Wrapper>
  );
};

export default FinanzierenPage;
