import { makeVar, ReactiveVar } from "@apollo/client";
import { InPageNavMapper } from "../../utils/mapper/InPageNavigation";
import { CardMapper } from "../../utils/mapper/CardMapper";
import { TextMapper } from "../../utils/mapper/TextMapper";

export const ABOUT_US = {
  listInPageNavigation2s: {
    read(value: any) {
      if (value === undefined) return;
      return AboutUs({
        ...AboutUs(),
        nav: value.data.map((data: any) => InPageNavMapper(data)),
      });
    },
  },
  listKachelKomponente2s: {
    read(value: any) {
      if (value === undefined) return;
      return AboutUs({
        ...AboutUs(),
        kachel: value.data.map((data: any) => CardMapper(data)),
      });
    },
  },
  listTextKomponente1s: {
    read(value: any) {
      if (value === undefined) return;
      return AboutUs({
        ...AboutUs(),
        text: value.data.map((data: any) => TextMapper(data)),
      });
    },
  },
};

export const AboutUs: ReactiveVar<any> = makeVar<any>({
  nav: {},
  kachel: {},
  text: {},
  isLoading: true,
});
