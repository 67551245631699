// Import Dependencies
import React, { useState } from 'react';
// Import Components
import ClickableText from '../partials/ClickableText';
import { Clickable, Link, Wrapper } from '../../styles/partials/PageNav';

interface NaviProps {
  defaultVal: string;
  inPage?: boolean;
  elements: { item: { text: string }; index: any; id: string }[];
}

export const PageNav = ({ elements, defaultVal }: NaviProps) => {
  const [value, setValue] = useState(defaultVal);
  const useClickHandler = (text: any) => {
    setValue(text);
  };

  return (
    <Wrapper
      container
      justify="center"
      alignItems="center"
      xs={12}
      elements={elements}
    >
      {elements.length > 0
        && elements.map((element, index: number) => (
          <Link href={`#${element.id}`} datatype="inpageNavigation" key={index}>
            <Clickable item>
              {element && (
                <ClickableText
                  {...element}
                  value={value}
                  clickHandler={useClickHandler}
                  background={{}}
                  navItems={elements.length}
                  inPage
                />
              )}
            </Clickable>
          </Link>
        ))}
    </Wrapper>
  );
};
