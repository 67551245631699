import React, {Dispatch, SetStateAction} from "react";
import {DialogContent} from "@material-ui/core";
import {
    Title,
    ContentContainer,
    ContentText, ButtonContainer, ButtonContained
} from "../../styles/style";

interface NewsletterExpiredProps {
    setOpenDialog: Dispatch<SetStateAction<boolean>>;
    handleCloseDialog: () => void;
}

const content = {
    title: "Ihre Anmeldung war leider nicht erfolgreich.",
    content: "Da Ihr Bestätigungslink nur 30 Tage gültig ist, bitten wir Sie, sich erneut für den Newsletter anzumelden.",
    buttonLabel: "Jetzt neu anmelden",
}

export function NewsletterExpired({setOpenDialog, handleCloseDialog}: NewsletterExpiredProps) {
    return (
        <>
            <Title id="form-dialog-title">
                {content.title}
            </Title>
            <DialogContent>
                <ContentContainer container>
                    <ContentText>
                        {content.content}
                    </ContentText>
                </ContentContainer>
            </DialogContent>
            <ButtonContainer>
                <ButtonContained variant="contained" type="submit" onClick={() => {
                    handleCloseDialog();
                    setOpenDialog(true);
                }}>
                    {content.buttonLabel}
                </ButtonContained>
            </ButtonContainer>
        </>
    )
}
