// Import Dependencies
import React, { useEffect, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import Cookies from 'js-cookie';
/*
 * Import Components
 * Imports Variables
 */
import styled from 'styled-components';
import { TextField } from '@material-ui/core';
import { codes } from '../../mocks/zipcodes';
import { BlueButton } from '../cta/BlueButton';
import { Title, Text, Error } from '../../styles/partials/ZipcodeComponent';

interface ZipcodeProps {
  open: boolean;
  title: string;
  text: string;
  buttonText: string;
  vkb: string;
  ukv: string;
  err: boolean;
  setError: any;
  handleClose: () => void;
}

const Transition = React.forwardRef(
  (props: TransitionProps & { children?: React.ReactElement<any, any> }) => (
    <Slide direction="up" {...props} />
  ),
);

const DialogStyled = styled(Dialog)`
    font-family: Raleway !important;
    input[type="text"] {
      font-family: Raleway;
      font-size: 18px;
    }
  `;

const ZipcodeComponent = ({
  open,
  handleClose,
  title,
  text,
  buttonText,
  vkb,
  ukv,
  err,
  setError,
}: ZipcodeProps) => {
  const [zip, setZip] = useState('');
  const [zipCookie] = useState(Cookies.get('zip') || '');
  const regex = new RegExp('\\d[0-9][0-9][0-9][0-9]');
  const userConsent = (window as any)?.dataLayer?.length > 0 ? (window as any).dataLayer.find((layer: any) => layer?.Ortsbestimmung)?.Ortsbestimmung : false;

  useEffect(() => {
    if (zipCookie !== '' && userConsent) {
      setZip(zipCookie);
    }
  }, []);

  const handleSubmit = () => {
    if (Number.isNaN(parseInt(zip, 10))) {
      setError(true);
    } else if (codes.includes(parseInt(zip, 10))) {
      handleClose();
      if (userConsent) Cookies.set('zip', zip, { expires: 7 });
      window.open(vkb, '_blank');
    } else if (regex.test(zip)) {
      handleClose();
      if (userConsent) Cookies.set('zip', zip, { expires: 7 });
      window.open(ukv, '_blank');
    } else {
      setError(true);
      return 'Not found/invalid ';
    }
  };

  function handleChange(change: any) {
    if (err) {
      setError(false);
    }
    setZip(change.target.value);
  }

  return (
    <DialogStyled
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={handleClose}
      aria-labelledby="alert-dialog-slide-title"
      aria-describedby="alert-dialog-slide-description"
    >
      {open && zipCookie !== '' && userConsent && handleSubmit()}
      <form onSubmit={() => handleSubmit()}>
        <Title id="alert-dialog-slide-title">{title}</Title>
        <DialogContent>
          <Text>{text}</Text>
          {err && (
            <Error>Ups, Sie haben ein ungültige Postleitzahl eingegeben.</Error>
          )}
          <DialogContentText id="alert-dialog-slide-description">
            <TextField
              autoFocus
              id="plz"
              label="PLZ"
              inputProps={{ maxLength: 5 }}
              variant="outlined"
              helperText="z. B. 10243"
              onChange={(e) => handleChange(e)}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <BlueButton
            datatype="outbound"
            text={buttonText}
            url=""
            func={() => handleSubmit()}
            border
          />
        </DialogActions>
      </form>
    </DialogStyled>
  );
};
export default ZipcodeComponent;
