import { ButtonMapper } from "./partials/ButtonMapper";
import { BackgroundMapper } from "./partials/BackgroundMapper";
import { IntroOutro } from "./partials/IntroOutro";

export const GraphicMapper = (obj: any) => {
  const mapped = {
    ...BackgroundMapper(obj),
    ...IntroOutro(obj),
    button: ButtonMapper(obj),
    upper: [
      {
        icon: obj.icon1,
        key: 0,
        text: obj.iconTitle1,
        background: {
          src: obj.background1,
          alt: obj.background1 ? "alt" : "",
          location: obj.backgroundLocation1,
        },
      },
      {
        icon: obj.icon2,
        key: 1,
        text: obj.iconTitle2,
        background: {
          src: obj.background2,
          alt: obj.background2 ? "alt" : "",
          location: obj.backgroundLocation2,
        },
      },
      {
        icon: obj.icon3,
        key: 2,
        text: obj.iconTitle3,
        background: {
          src: obj.background3,
          alt: obj.background3 ? "alt" : "",
          location: obj.backgroundLocation3,
        },
      },
    ],
    lower: [
      {
        icon: obj.icon1,
        key: 3,
        text: obj.iconTitle4,
        background: {
          src: obj.background4,
          alt: obj.background4 ? "alt" : "",
          location: obj.backgroundLocation4,
        },
      },
      {
        icon: obj.icon2,
        key: 4,
        text: obj.iconTitle5,
        background: {
          src: obj.background5,
          alt: obj.background5 ? "alt" : "",
          location: obj.backgroundLocation5,
        },
      },
      {
        icon: obj.icon3,
        key: 5,
        text: obj.iconTitle6,
        background: {
          src: obj.background6,
          alt: obj.background6 ? "alt" : "",
          location: obj.backgroundLocation6,
        },
      },
    ],
    content: [
      obj.iconText1,
      obj.iconText2,
      obj.iconText3,
      obj.iconText4,
      obj.iconText5,
      obj.iconText6,
    ],
  };

  function filterArrayValues(arr: any) {
    arr = arr.filter(isEligible);
    return arr;
  }

  function isEligible(value: any) {
    if (value.icon !== null) {
      return value;
    }
  }
  mapped.upper = filterArrayValues(mapped.upper);
  mapped.lower = filterArrayValues(mapped.lower);
  return mapped;
};
