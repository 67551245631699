import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export const Wrapper = styled(Grid)`
  margin: 0 auto;
`;
export const InnerWrapper = styled(Grid)`
  margin: 0 auto;
`;
export const Module = styled(Grid)`
  width: 100%;
`;

export const useStyles = makeStyles({
  root: {
    '& h1': {
      fontSize: '42px',
    },
    '& h2': {
      fontSize: '34px',
      color: '#002864',
    },
    '& #kontakt': {
      background: '100vw',
      '& .MuiGrid-item': {
        justifyContent: 'space-around',
        marginTop: 0,
      },
      '& .sc-pNWxx, .sc-fujyUd': {
        display: 'none',
      },
    },

    '& .MuiGrid-root': {
      fontSize: '18px',
      lineHeight: '27px',

      '& .sc-ezzayL': {
        color: '#222222',
        fontWeight: 600,
      },
      '& #text': {
        color: '#222222',
        fontWeight: 500,
      },
      '& .messageButton': {
        marginTop: 50,
        marginButtom: 80,
      },
    },
    '& .MuiCard-root': {
      width: '300px',
      height: '250px',
    },

    '& .cuSmBM': {
      maxWidth: '170px',
      maxHeight: '220px',
    },
  },
});
