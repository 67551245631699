import React from 'react';
import SVG from './SVG';
import { Wrapper, TextWrapper } from '../../styles/partials/SmallIconText';

interface IconTextProps {
  icon: string;
  iconText?: string;
}

const SmallIconText = ({ icon, iconText }: IconTextProps) => (
  <Wrapper container>
    <SVG svg={icon} width={56} height={63} />
    <TextWrapper>{iconText}</TextWrapper>
  </Wrapper>
);

export default SmallIconText;
