export const PROCESS_COMPONENT = ` 
                img1
                title1
                subtitle1
                text1
                img11
                imgText11
                link1
                linkText1

                img2
                title2
                subtitle2
                text2
                img21
                imgText21
                link2
                linkText2
                
                img3
                title3
                subtitle3
                text3
                img31
                imgText31
                link3
                linkText3

                img4
                title4
                subtitle4
                text4
                img41
                imgText41
                link4
                linkText4`;

// TODO add linkText1... again
