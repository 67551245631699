import React from 'react';
import styled from 'styled-components';

const Timer = styled.div`
display: none;
`;

// Functional element for scrolling to the right location after loading
export const Scroller = (props: any) => {

  setTimeout(() => {
    const id = props.hash.replace('#', '');
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView();
    }
    return undefined;
  }, 500)

  return (
      <Timer />
  );
}
