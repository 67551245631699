import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import Tab from '@material-ui/core/Tab';
import Tabs from '@material-ui/core/Tabs';
import SwipeableViews from 'react-swipeable-views';
import { BackgroundConverter } from '../../utils/BackgroundConverter';
import {
  colors,
  Fonts,
  InnerWrapperWidth,
  MDBreakPoint,
  OuterWrapperWidth,
  XSBreakPoint,
} from '../../Theme';
import { H2, H3 } from '../Styles';

interface WrapperProps {
  background: { type: string; url: string };
  isBlog?: boolean;
}
export const useStyles = makeStyles({
  root: {
    '& .MuiTab-root': {
      textTransform: 'none',
      borderBottom: '1px solid #69d2e6',
      lineHeight: '18px',
      paddingBottom: '0px',
    },
    '& .Mui-selected': {
      color: colors.darkBlue,
    },
    '& .MuiTabs-flexContainer': {
      marginBottom: '1px',
      display: 'flex',
      justifyContent: 'center',
    },
  },
  indicator: {
    background: '#69d2e6',
    height: '4px',
  },
});
export const Wrapper = styled(Grid)<WrapperProps>`
  padding: ${({ isBlog }) => (isBlog ? '0 16px' : '50px 16px')};  
  @media (max-width: ${XSBreakPoint}) {
    padding: 50px 16px;
  }
  ${({ background }) => BackgroundConverter(background.type, background.url)}}
  .MuiTabs-flexContainer{
    justify-content: left;
    @media (max-width: ${XSBreakPoint}) {
      justify-content: center;
      }
    }
`;

export const InnerWrapper = styled.div`
  flex: 2;
  max-width: ${OuterWrapperWidth};
  @media (max-width: ${MDBreakPoint}) {
    width: ${InnerWrapperWidth};
    margin-left: 40px;
    margin-right: 40px;
  }
  @media (max-width: ${XSBreakPoint}) {
    margin: 0;
  }
`;

export const Slider = styled(Grid)`
  width: 100%;
  max-width: 1600px;
`;
export const Text = styled.div<{ alignLeft?: boolean }>`
  text-align: ${(props) => (props.alignLeft ? 'left' : 'center')};
  color: ${colors.black};
  font: ${Fonts.fontText};
  @media (max-width: ${XSBreakPoint}) {
    padding-left: 10px;
    padding-right: 10px;
  }
`;

export const LeftButton = styled.span`
  margin-left: 64px;
  margin-top: auto !important;
  margin-bottom: auto !important;
  margin-right: auto !important;
  :hover {
    cursor: pointer;
  }
  @media (max-width: ${MDBreakPoint}) {
    display: none;
  }
`;

export const RightButton = styled.span`
  margin-right: 64px;
  margin-top: auto !important;
  margin-bottom: auto !important;
  margin-left: auto;
  :hover {
    cursor: pointer;
  }
  @media (max-width: ${MDBreakPoint}) {
    display: none;
  }
`;

export const StyledTab = styled(Tab)`
  font: ${Fonts.fontTextLink};
  :active {
    color: ${colors.darkBlue};
  }
  .MuiTouchRipple-root {
    display: none;
  }
`;
export const StyledTabs = styled(Tabs)`
  margin: 10px 0;
  @media (max-width: ${XSBreakPoint}) {
    margin: auto !important;
    text-align: center !important;
  }
`;

export const SwipeableContent = styled(SwipeableViews)`
  font: ${Fonts.fontText};
  display: flex;
  justify-content: center;
`;

export const Image = styled.img<{ slides?: any }>`
  display: ${(props) => (props.slides.card.img
    && ((props.slides.card.title && props.slides.card.title.length > 4)
      || (props.slides.card.text && props.slides.card.text.length > 4)
      || (props.slides.card.subltile && props.slides.card.subltile.length > 4))
    ? 'none'
    : 'block')};
  margin: 0 auto;
  @media (max-width: ${XSBreakPoint}) {
    width: 100%;
    display: none;
    margin: auto;
  }
`;
export const ImageXs = styled(Image)`
  display: none;
  @media (max-width: ${XSBreakPoint}) {
    display: block;
  }`;

export const Subtitle = styled(H3)`
  text-align: center;
  color: ${colors.darkBlue};
  margin-top: 60px !important;
`;

export const Title = styled(H2)`
  text-align: center;
  color: ${colors.darkBlue};
  padding-bottom: 20px;
`;
