// Import Dependencies
import React, { useState } from 'react';
// Import Components
import Cards from '../partials/Card';
// Import Variables
import Introduction from '../partials/Introduction';
import Closure from '../partials/Closure';
import {
  CardsContainer,
  InnerWrapper,
  Wrapper,
  StyledGrid,
} from '../../styles/components/CardComponent';

interface PropsInterface {
  background: { type: string; url: string };
  title?: string;
  subtitle?: string;
  introduction?: any;
  func?: () => void;
  func2?: () => void;
  closing?: any;
  cards: { src: string; text: string; background?: any }[];
  buttons?: { title: string; color: string; url: any; func?: () => void }[];
  isBlog?: boolean;
  isAboutUs?: boolean;
}

export const CardComponent = ({
  background,
  title,
  subtitle,
  introduction,
  func,
  func2,
  closing,
  cards,
  buttons,
  isBlog,
  isAboutUs,
}: PropsInterface) => {
  const [flip, setFlip] = useState(-1);
  function flipHandler(flip: number) {
    setFlip(flip);
  }
  if (buttons !== undefined) {
    if (func) buttons[0].func = func;
  }
  return (
    <Wrapper
      container
      alignItems="center"
      direction="column"
      background={background}
      isBlog={isBlog}
    >
      <InnerWrapper>
        <Introduction
          title={title}
          subtitle={subtitle}
          introduction={introduction}
          alignText="left"
        />
        <CardsContainer container item direction="row">
          {cards.map((data, index) => {
            if (
              data.background
              && data.background[0].type
              && (data.src || data.text)
            ) {
              return (
                <StyledGrid
                  key={index}
                  container
                  item
                  justify="center"
                  alignItems="center"
                  xs={12}
                  md={6}
                  lg={cards.length === 2 ? 6 : 4}
                  direction="row"
                >
                  <Cards
                    data={data}
                    flip={flip === index}
                    setFlip={(prop: number) => flipHandler(prop)}
                    index={index}
                    isAboutUs={isAboutUs}
                    openHandler={func2}
                  />
                </StyledGrid>
              );
            }
          })}
        </CardsContainer>
      </InnerWrapper>
    </Wrapper>
  );
};
