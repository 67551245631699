import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import Tab from '@material-ui/core/Tab';
import { makeStyles } from '@material-ui/core/styles';
import { Fonts, MDBreakPoint } from '../../Theme';

export const MobileContainer = styled(Grid)`
  padding: 20px 0 0 0;
  display: none !important;
  @media (max-width: ${MDBreakPoint}) {
    display: block !important;
  }
`;

export const StyledTab = styled(Tab)`
  padding-top: 50px;
  font: ${Fonts.fontTextLink} !important;
  .MuiTouchRipple-root {
    display: none;
  }

  margin-right: 40px !important;
`;

export const useStyles = makeStyles({
  root: {
    '& .MuiTabs-flexContainer': {
      borderBottom: '1px solid #69d2e6',
      justifyContent: 'space-between',
      height: '100%',
      width: 'max-content',
    },
    '& .MuiTab-root': {
      textTransform: 'none',
      color: '#0082ff',
    },
    '& .Mui-selected': {
      color: '#002864 !important',
    },
  },
  indicator: {
    background: '#69d2e6',
    height: '4px',
  },
});

export const Text = styled.p`
  width: 300px;
  text-align: center;
  height: 100%;
  @media (max-width: 1279px) {
    width: 200px;
  }
`;
