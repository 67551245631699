import { makeVar, ReactiveVar } from '@apollo/client';
import { InPageNavMapper } from '../../utils/mapper/InPageNavigation';
import { TabsMapper } from '../../utils/mapper/TabMapper';
import { ProcessMapper } from '../../utils/mapper/ProcessMapper';
import { CardMapper } from '../../utils/mapper/CardMapper';
import { TextMapper } from '../../utils/mapper/TextMapper';
import { SliderMapper } from '../../utils/mapper/SliderMapper';
import { StepsMapper } from '../../utils/mapper/StepsMapper';

export const ZU_HAUSE = {
  listPzInPageNavigations: {
    read(value: any) {
      if (value !== undefined) {
        return Zuhause({
          ...Zuhause(),
          nav: value.data.map((data: any) => InPageNavMapper(data)),
        });
      }
    },
  },
  listPzTabKomponentes: {
    read(value: any) {
      if (value !== undefined) {
        return Zuhause({
          ...Zuhause(),
          tabs: value.data.map((data: any) => TabsMapper(data)),
        });
      }
    },
  },
  listPzTextKomponentes: {
    read(value: any) {
      if (value !== undefined) {
        return Zuhause({
          ...Zuhause(),
          textNew: value.data.map((data: any) => TextMapper(data)),
        });
      }
    },
  },
  listPzProzessKomponentes: {
    read(value: any) {
      if (value !== undefined) {
        return Zuhause({
          ...Zuhause(),
          process: value.data.map((data: any) => ProcessMapper(data)),
        });
      }
    },
  },
  listPflegeUndBetreungsangebote1s: {
    read(value: any) {
      if (value !== undefined) {
        return Zuhause({
          ...Zuhause(),
          cards: value.data.map((data: any) => CardMapper(data)),
        });
      }
    },
  },
  listUnterstutzungFurDenAlltag1s: {
    read(value: any) {
      if (value !== undefined) {
        return Zuhause({
          ...Zuhause(),
          text: value.data.map((data: any) => TextMapper(data)),
        });
      }
    },
  },
  listMedikamenteHeilmittelUndHilfsmittels: {
    read(value: any) {
      if (value !== undefined) {
        return Zuhause({
          ...Zuhause(),
          tabsTwo: value.data.map((data: any) => TabsMapper(data)),
        });
      }
    },
  },
  listMeinPflegePartners: {
    read(value: any) {
      if (value !== undefined) {
        return Zuhause({
          ...Zuhause(),
          slider: value.data.map((data: any) => SliderMapper(data)),
        });
      }
    },
  },
  listWohnungOderHaus: {
    read(value: any) {
      if (value !== undefined) {
        return Zuhause({
          ...Zuhause(),
          steps: value.data.map((data: any) => StepsMapper(data)),
        });
      }
    },
  },
  listUmzugInsPflegeheims: {
    read(value: any) {
      if (value !== undefined) {
        return Zuhause({
          ...Zuhause(),
          textTwo: value.data.map((data: any) => TextMapper(data)),
        });
      }
    },
  },
};

export const Zuhause: ReactiveVar<any> = makeVar<any>({
  nav: {},
  tabs: {},
  process: {},
  cards: {},
  text: {},
  slider: {},
  tabsTwo: {},
  steps: {},
  textTwo: {},
  textNew: {},
  isLoading: true,
});
