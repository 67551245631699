// Import Dependencies
import React from 'react';
import {
  Wrapper,
  Category,
  StyledMoment,
  Tags,
  Title,
  TitleContainer,
  Card,
} from '../../styles/partials/BlogCard';
// Import Components

// Import Variables

export interface BlogCardInterface {
  blogName: string;
  categories: [string];
  metaImage: string;
  publishDate: Date;
  index: number;
  last: boolean;
}

export default ({
  blogCard, getBlog, index, last,
}: any) => (

  <Wrapper
    item
    container
    xs={12}
    md={6}
    lg={last ? 6 : 4}
    onClick={() => getBlog()}
  >
    <Card
      direction="column"
      justify="space-between"
      container
      first={index === 0}
      last={last}
      img={blogCard.metaImage}
    >

      <Tags
        item
        container
        direction="row"
        justify="flex-start"
        alignItems="flex-start"
      >
        <StyledMoment>
          {blogCard.publishDate
            ? blogCard.publishDate.split('T')[0]
            : blogCard.createdOn.split('T')[0]}
        </StyledMoment>
        {blogCard.categories
          && blogCard.categories?.map((cat: string) => (
            <Category>
              {cat === 'Pflege Zu Hause' ? 'Pflege zu Hause' : cat}
            </Category>
          ))}
      </Tags>
      <TitleContainer item>
        <Title>{blogCard.description}</Title>
      </TitleContainer>

    </Card>
  </Wrapper>
);
