import React from 'react';
import Logo from '../../assets/logo-pflegepartner-dunkelblau-tuerkis.svg';
import {
  ContentWrapper, Title, Wrapper, Image,
} from '../../styles/partials/BedarfscheckHeader';

export const BedarfscheckHeaderComponent = () => (
  <Wrapper>
    <ContentWrapper container direction="row">
      <Image src={Logo} alt="Logo" />
      <Title>Pflegebedarfs-Check</Title>
    </ContentWrapper>
  </Wrapper>
);
