import styled from "styled-components";
import {ReactSVG} from "react-svg";
import { Wrapper } from '../../../styles/partials/SwiperWrapper';
import {LGBreakPoint, XSBreakPoint, XXSBreakPoint} from "../../../Theme";

export const ContentWrapper = styled(Wrapper)`
  width: 1556px !important;

  @media (max-width: ${XSBreakPoint}) {
    margin-bottom: 12rem;
  }
`

export const ContentContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  @media (max-width: ${XSBreakPoint}) {
    height: 43%;
    margin-top: 26rem;
    background: #3881f7;
  }
`;

export const NewsletterContainer = styled.div`
  position: absolute;
  width: 86.5rem;
  height: 100%;

  @media (max-width: ${LGBreakPoint}) {
    width: 100%;
  }
`;

// @ts-ignore
export const CheckerCtaSvg = styled(ReactSVG)`
  height: 247px;
  position: absolute;
  bottom: 0;

  @media (max-width: ${XSBreakPoint}) {
    display: none;
  }
`

// @ts-ignore
export const NewsletterButtonSvg = styled(ReactSVG)`
  position: absolute;
  top: 0;
  right: 0;

  @media (max-width: ${XSBreakPoint}) {
    width: auto;
  }

  @media (max-width: ${XXSBreakPoint}) {
    width: 260px;
    height: 100px;
  }
`
