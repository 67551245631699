import React, {Dispatch, SetStateAction, useState} from "react";
import {DialogContent} from "@material-ui/core";
import {ToggleButton} from '@material-ui/lab';
import {Formik} from "formik";
import axios from "axios";
import * as Yup from "yup";
import {
    ContentContainer,
    Title,
    Subtitle,
    Icon,
    ContentText,
    ToggleContainer,
    TextFieldInput,
    ButtonContained,
    ButtonContainer,
    ErrorMessage,
    Link
} from "../../styles/style";

interface INewsletterForm {
    setSubmitionCompleted: Dispatch<SetStateAction<boolean>>;
}

const content = {
    form: {
        title: "Newsletter abonnieren",
        subtitle: "Mit unserem kostenlosen Newsletter erhalten Sie regelmäßig Informationen und Tipps " +
            "rund um das Thema Pflege sowie Hintergrundberichte, Lebensgeschichten oder Fachbeiträge " +
            "aus unserem Blogbereich.",
        gender: {
            male: "Herr",
            female: "Frau"
        },
        firstname: "Vorname",
        surname: "Nachname",
        email: "E-Mail Adresse",
        contentTitle: "Einverständniserklärung",
        contentSubtitle1: "Mit der Anmeldung zum Newsletter erklären Sie sich damit einverstanden, dass " +
            "wir Ihnen per E-Mail in regelmäßigen Abständen redaktionelle und werbliche Informationen zu " +
            "Versicherungs- und Vorsorgeprodukten in Form unseres Newsletters zukommen lassen dürfen und " +
            "Sie akzeptieren die ",
        contentSubtitle2: ". Selbstverständlich können Sie den Newsletter jederzeit wieder abbestellen.",
        link: "Hinweise zum Datenschutz",
        buttonLabel: "Abonnieren"
    }
}

export function NewsletterForm({setSubmitionCompleted}: INewsletterForm) {
    const [gender, setGender] = useState<string | null>();
    const [isGenderInvalid, setIsGenderInvalid] = useState(false);

    function apiCall(data: Object) {
        if (process.env.REACT_APP_INXMAIL_API_URL && process.env.REACT_APP_INXMAIL_API_TOKEN) {
            axios
                .post(process.env.REACT_APP_INXMAIL_API_URL, JSON.stringify(data), {
                    headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": "application/json",
                        "x-api-key": process.env.REACT_APP_INXMAIL_API_TOKEN
                    }
                })
                .then(resp => {
                    setSubmitionCompleted(true);
                });
        }
    }

    function validateUserInput() {
        if (gender === undefined) {
            setIsGenderInvalid(true);
        }
        return Yup.object().shape({
            firstname: Yup.string().min(2, "Der Vorname ist zu kurz").required("Bitte tragen Sie Ihren Vornamen ein"),
            surname: Yup.string().min(2, "Der Nachname ist zu kurz").required("Bitte tragen Sie Ihren Nachnamen ein"),
            email: Yup.string().email("Dies ist keine gültige E-Mail").required("Bitte tragen Sie Ihre E-Mail Adresse ein")
        })
    }

    function handleGender(e: React.MouseEvent<HTMLButtonElement>) {
        setGender(e.currentTarget.value);
        setIsGenderInvalid(false);
    }

    return (
        <>
            <Title id="form-dialog-title">
                {content.form.title}
            </Title>
            <DialogContent>
                <ContentText>
                    {content.form.subtitle}
                </ContentText>
                <Formik
                    initialValues={{firstname: "", surname: "", email: ""}}
                    onSubmit={(values, {setSubmitting}) => {
                        if (gender === undefined) {
                            return setIsGenderInvalid(true);
                        }
                        setSubmitting(true);
                        apiCall(
                            {
                                listId: 1712,
                                email: values.email,
                                attributes: {
                                    Anrede: gender,
                                    Vorname: values.firstname,
                                    Nachname: values.surname,
                                    EWE_Pflegepartner: "true",
                                    NL_Pflegepartner: "true"
                                }
                            });
                    }}
                    validationSchema={validateUserInput}
                >
                    {
                        ({
                             values,
                             touched,
                             errors,
                             isSubmitting,
                             handleChange,
                             handleBlur,
                             handleSubmit,
                         }) => (
                            <form onSubmit={handleSubmit}>
                                <ContentContainer container>
                                    <ToggleContainer
                                        value={gender}
                                        exclusive
                                        color="primary"
                                        isGenderInvalid={isGenderInvalid}
                                    >
                                        <ToggleButton value="Herr" onClick={(e) => handleGender(e)}>
                                            {content.form.gender.male}
                                        </ToggleButton>
                                        <ToggleButton value="Frau" onClick={(e) => handleGender(e)}>
                                            {content.form.gender.female}
                                        </ToggleButton>
                                    </ToggleContainer>
                                </ContentContainer>
                                <ContentContainer container>
                                    {isGenderInvalid &&
                                    <ErrorMessage>
                                      Bitte wählen Sie eine Anrede aus
                                    </ErrorMessage>
                                    }
                                </ContentContainer>
                                <ContentContainer container>
                                    <TextFieldInput
                                        error={touched.firstname && !!errors.firstname}
                                        label={content.form.firstname}
                                        id="outlined-basic"
                                        variant="outlined"
                                        name="firstname"
                                        value={values.firstname}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        helperText={touched.firstname && errors.firstname}
                                    />
                                    <TextFieldInput
                                        error={touched.surname && !!errors.surname}
                                        label={content.form.surname}
                                        id="outlined-basic"
                                        variant="outlined"
                                        name="surname"
                                        value={values.surname}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        helperText={touched.surname && errors.surname}
                                    />
                                    <TextFieldInput
                                        error={touched.email && !!errors.email}
                                        label={content.form.email}
                                        id="outlined-basic"
                                        variant="outlined"
                                        name="email"
                                        value={values.email}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        helperText={touched.email && errors.email}
                                    />
                                </ContentContainer>
                                <Subtitle id="form-dialog-title">
                                    {content.form.contentTitle}
                                </Subtitle>
                                <ContentContainer container>
                                    <ContentText>
                                        {content.form.contentSubtitle1}
                                        <Link target="_blank" href="/privacy-policy">
                                            {content.form.link}
                                        </Link>
                                        {content.form.contentSubtitle2}
                                    </ContentText>
                                </ContentContainer>
                                <ButtonContainer>
                                    <ButtonContained variant="contained" type="submit" onClick={() => isSubmitting}>
                                        {content.form.buttonLabel}
                                    </ButtonContained>
                                </ButtonContainer>
                            </form>
                        )
                    }
                </Formik>
            </DialogContent>
        </>
    )
}
