import React from 'react';
import { TextChecker } from '../../utils/RichTextConverter';
import ButtonsContainer from './ButtonsContainer';
import { Text, Container, Closing } from '../../styles/partials/Introduction';

interface ClosureProps {
  closing?: any;
  buttons?: any;
  buttonsAlign?: 'center' | 'left' | 'right' | undefined;
  setNavigationIndex?: (index: number) => void;
}

const Closure = ({
  closing, buttons, buttonsAlign = 'left', setNavigationIndex,
}: ClosureProps) => (
  <Closing container xs={12} justify="center" alignContent="center">
    <Container item xs={12}>
      {closing && closing[0]?.data?.text !== '' && (
        <Text alignText={buttonsAlign}>{TextChecker(closing)}</Text>
      )}
      {buttons && buttons?.length > 0 && <ButtonsContainer buttons={buttons} setNavigationIndex={setNavigationIndex} />}
    </Container>
  </Closing>
);

export default Closure;
