import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { colors, Fonts, XSBreakPoint } from '../../Theme';
import { H2, H3 } from '../Styles';

export const Title = styled(H2)`
  text-align: center;
  color: ${colors.darkBlue};
  max-width: 1000px;
  margin: 20px 0;
`;

export const Subtitle = styled(H3)`
  text-align: center;
  color: ${colors.darkBlue};
  font: ${Fonts.fontH3};
  max-width: 1000px;
  margin: 60px auto 0 !important;
`;
export const Text = styled.div<{ alignText?: 'right' | 'left' | 'center' }>`
  text-align: left;
  padding-bottom: 0;
  color: ${colors.black};
  font: ${Fonts.fontText};
  max-width: 100%;
`;

export const Wrapper = styled(Grid)`
  padding: 0 0 20px;
`;

export const Closing = styled(Wrapper)`
  @media (max-width: ${XSBreakPoint}) {
    padding: 0 0 20px;
  }
`;

export const Container = styled(Grid)`
  max-width: 1000px !important;
`;
