// Import Dependencies
import React from 'react';
import { useScrollTrigger } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import { useTheme } from '@material-ui/core/styles';
import { AccountCircleOutlined, Close } from '@material-ui/icons';
import { useHistory, useLocation } from 'react-router';
import Slide from '@material-ui/core/Slide';
// Import Components
import SVG from '../partials/SVG';
// import Variabel
import logo from '../../assets/logo-pflegepartner-dunkelblau-tuerkis.svg';
import { NavigationItems, NavigationLogin } from '../../mocks/mock';
import smile from '../../assets/smile-pflegepartner-navigation-xs.svg';
import { colors } from '../../Theme';
import {
  Pflegepartner,
  StlyedListItemText,
  useStyles,
} from '../../styles/components/TopPageNavigation';

interface NavProps {
  setValue: any;
  zipFunc: {
    setTitle: any;
    setText: any;
    setButtonText: any;
    setUkv: any;
    setVkb: any;
  };
  handleOpenOpenZip: () => void;
}

interface Elevation {
  children: React.ReactElement;
}

function ElevatedNavBar({ children }: Elevation) {
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

export default function TopPageNavigation({
  setValue,
  zipFunc,
  handleOpenOpenZip,
}: NavProps) {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleZip = () => {
    if (zipFunc) {
      zipFunc.setTitle('Zum Login');
      zipFunc.setText(
        '„Mein PflegePartner” bieten wir gemeinsam mit unseren Partnern an. Um fortzufahren, geben Sie bitte Ihre Postleitzahl ein:',
      );
      zipFunc.setButtonText('Senden');
      zipFunc.setUkv(
        'https://anmeldung.vkb.de/auth/realms/pflegepartner-test/protocol/openid-connect/auth?client_id=pflegeportal-frontend&redirect_uri=https%3A%2F%2Fpflegepartner.ukv.de%2Fppd%2F%23%2Fukv%2Fportal%2Fdashboard&state=b6b7dfb2-75d0-441c-844a-7bc1a40feaa0&nonce=0ebeb72a-6a33-4fc4-99d5-086b5a38b1cc&response_mode=fragment&response_type=code&scope=openid&login_hint=ukv',
      );
      zipFunc.setVkb(
        'https://anmeldung.vkb.de/auth/realms/pflegepartner-test/protocol/openid-connect/auth?client_id=pflegeportal-frontend&redirect_uri=https%3A%2F%2Fpflegepartner.vkb.de%2Fppd%2F%23%2Fvkb%2Fportal%2Fdashboard&state=be2d4501-836d-4972-8c69-b2f51f233638&nonce=89f75c9c-688c-48c5-8b39-d6dfb8d93435&response_mode=fragment&response_type=code&scope=openid&login_hint=vkb',
      );
      handleOpenOpenZip();
    }
  };

  const handleChange = ({ currentTarget }: any) => {
    history.push(NavigationItems[currentTarget.id].to);
    setValue(currentTarget.id);
    setMobileOpen(false);
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 2);
  };

  const handleIconClick = (newValue: number) => {
    history.push(NavigationItems[newValue].to);
    setValue(newValue);
  };

  const drawer = (
    <div>
      <div className={classes.toolbar}>
        <IconButton
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          className={classes.closeButton}
          style={{ color: `${colors.darkBlue}` }}
        >
          <Close />
        </IconButton>
      </div>
      <Divider />
      <List>
        {NavigationItems.map((item, index) => (
          <ListItem
            button
            key={index}
            id={index.toString()}
            onClick={(props) => handleChange(props)}
          >
            <StlyedListItemText primary={item.text} />
          </ListItem>
        ))}
        <ListItem button>
          <StlyedListItemText
            primary={(
              <>
                <> Login </>
                <Pflegepartner>{NavigationLogin.text}</Pflegepartner>
              </>
            )}
            onClick={() => {
              handleDrawerToggle();
              handleZip();
            }}
          />
        </ListItem>
      </List>
      <SVG svg={smile} width={250} height={80} />
    </div>
  );

  // @ts-ignore
  const HiddenDrawerDesktop = (<Hidden mdUp implementation="css">
    <Drawer
        variant="temporary"
        anchor={theme.direction === 'rtl' ? 'right' : 'left'}
        open={mobileOpen}
        onClose={handleDrawerToggle}
        classes={{
          paper: classes.drawerPaper,
        }}
        ModalProps={{
          keepMounted: true,
        }}
    >
      {drawer}
    </Drawer>
  </Hidden>)

  // @ts-ignore
  const HiddenDrawerMobile = (<Hidden mdDown implementation="css">
    <Drawer
        classes={{
          paper: classes.drawerPaper,
        }}
        variant="permanent"
        open
    >
      {drawer}
    </Drawer>
  </Hidden>);

  // @ts-ignore
  return (
    <div className={classes.root}>
      <CssBaseline />
      {useLocation().pathname !== '/bedarfscheck' && (
      <>
        <ElevatedNavBar>
          <AppBar
            position="fixed"
            className={classes.appBar}
            style={{ backgroundColor: '#E0F5F9' }}
          >
            <Toolbar>
              <IconButton
                aria-label="open drawer"
                edge="start"
                onClick={handleDrawerToggle}
                className={classes.menuButton}
                style={{ color: `${colors.darkBlue}` }}
              >
                <MenuIcon />
              </IconButton>

              <SVG
                svg={logo}
                width={40}
                height={30}
                style={{
                  marginLeft: 'auto',
                  marginRight: 'auto',
                  cursor: 'pointer',
                }}
                onClick={() => handleIconClick(0)}
              />
              <IconButton
                aria-label="login"
                edge="end"
                onClick={() => handleZip()}
                className={classes.pflegePartner}
                style={{
                  color: `${colors.darkBlue}`,
                  marginRight: 0,
                }}
              >
                <AccountCircleOutlined />
              </IconButton>
            </Toolbar>
          </AppBar>
        </ElevatedNavBar>
        <nav className={classes.drawer} aria-label="mailbox folders">
          {HiddenDrawerDesktop}
          {HiddenDrawerMobile}
        </nav>
      </>
      )}
    </div>
  );
}
