import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { colors, Fonts, XSBreakPoint } from '../../Theme';

export const Wrapper = styled(Grid)<{ elements: any }>`
  max-width: 1000px !important;
  width: 1000px !important;
  border-bottom: ${(props) => (props.elements.length > 0 ? `1px solid ${colors.turkis}` : 'none')};
  position: relative;
  flex-flow: nowrap !important;

  a {
    display: inline-block;
    white-space: nowrap;

    @media (max-width: ${XSBreakPoint}) {
      white-space: normal;
    }
  }

  @media (max-width: ${XSBreakPoint}) {
    flex-direction: column !important;
    margin-top: 0px;
    padding-bottom: 8px;
  }
`;
export const Clickable = styled(Grid)`
  padding: 0 10px;
`;
export const Link = styled.a`
  text-decoration: none;
  p {
    color: ${colors.middleBlue};
    display: inline-block;
    font: ${Fonts.fontTextLinkSmallNav};
    @media (max-width: ${XSBreakPoint}) {
      font: ${Fonts.fontTextLinkSmallNavMobile};
      margin: 0 !important;
      padding: 0 !important;
      border: 0;
    }
  }
`;
