import styled from "styled-components";
import {CtaWrapper} from "../../../styles/partials/StickySidebar";
import {MDBreakPoint, XSBreakPoint} from "../../../Theme";

const CheckerContentContainer = styled.div`
  width: fit-content;
  position: absolute;
  bottom: 0;
  margin-left: 1.5rem;

  @media (max-width: ${MDBreakPoint}) {
    margin-left: 1rem;
  }

  @media (max-width: ${XSBreakPoint}) {
    text-align: center;
    width: 100%;
    margin: 0;
  }
`;

const CheckerContactLabel = styled.span`
  color: white;
  font-size: 16px;
  line-height: 24px;
  font-weight: 700;
  display: block;
  margin-bottom: 0.5rem;

  @media (max-width: ${XSBreakPoint}) {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }
  
`;

const CheckerContactText = styled.span`
  display: block;
  color: white;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  margin-bottom: 1rem;

  @media (max-width: ${MDBreakPoint}) {
    font-weight: 400;
  }
  @media (max-width: ${XSBreakPoint}) {
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }
`;

const CheckerButtonWrapper = styled(CtaWrapper)`
  margin-left: 2.5rem !important;
  @media (max-width: ${MDBreakPoint}) {
    margin-left: 3rem !important;
  }
  @media (max-width: ${XSBreakPoint}) {
    width: fit-content;
    margin-left: auto !important;
    margin-right: auto !important;
  }
`;

export {
    CheckerContentContainer,
    CheckerContactLabel,
    CheckerContactText,
    CheckerButtonWrapper
}
