import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import Tab from '@material-ui/core/Tab';
import { BackgroundConverter } from '../../utils/BackgroundConverter';
import {
  colors,
  Fonts,
  InnerWrapperWidth,
  MDBreakPoint,
  XSBreakPoint,
} from '../../Theme';
import { H2 } from '../Styles';

interface WrapperProps {
  background: { type: string; url: string };
  isBlog?: boolean;
}
export const useStyles = makeStyles({
  root: {
    '& .MuiTabs-flexContainer': {
      borderBottom: '1px solid #69d2e6',
      justifyContent: 'space-between',
    },
    '& .MuiTab-root': {
      textTransform: 'none',
      padding: '0 50px',
      paddingBottom: '10px',
      maxWidth: '280px',
    },
    '& .Mui-selected': {
      color: '#002864',
    },
  },
  indicator: {
    background: '#69d2e6',
    height: '4px',
  },
});

export const OuterWrapper = styled(Grid)<WrapperProps>`
  padding: ${({ isBlog }) => (isBlog ? '0 16px' : '50px 16px')};
  .MuiTab-textColorInherit{
    opacity: 1 !important;
  }
  ${({ background }) => BackgroundConverter(background.type, background.url)}}
`;

export const Title = styled(H2)`
  text-align: center;
  color: ${colors.darkBlue};
  padding: 0 0 50px 0;
  @media (max-width: 1280px) {
    padding: 0 0 25px 0;
  }
  @media (max-width: 960px) {
  }
`;

export const Subtitle = styled.div`
  font: ${Fonts.fontText};
  color: ${colors.darkBlue};
  text-align: center;
  margin-bottom: 30px;
`;

export const InnerWrapper = styled.div`
  max-width: ${InnerWrapperWidth};
  @media (max-width: ${MDBreakPoint}) {
    width: 1080px;
  }
  @media (max-width: ${XSBreakPoint}) {
    display: none;
  }
`;

export const MobileWrapper = styled.div`
  display: none;
  @media (max-width: ${XSBreakPoint}) {
    display: block;
    max-width: 360px;
  }
`;
export const StyledTab = styled(Tab)`
  font: ${Fonts.fontTextLink} !important;
  .MuiTouchRipple-root {
    display: none;
  }
`;

export const TabsWrapper = styled(Grid)``;
