import React from 'react';
import ReactDOM from 'react-dom';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter as Router } from 'react-router-dom';
import './index.css';
import App from './components/App';
import client from './graphql/client';
import * as serviceWorker from './serviceWorker';
import { Theme } from './Theme';

ReactDOM.render(
  <ApolloProvider client={client}>
    <Router>
      <Theme />
      <App />
    </Router>
  </ApolloProvider>,
  document.getElementById('root'),
);

/*
 * If you want your app to work offline and load faster, you can change
 * unregister() to register() below. Note this comes with some pitfalls.
 * Learn more about service workers: https://bit.ly/CRA-PWA
 */
serviceWorker.unregister();
