export const FINANCIAL_CARE = ` 
  buttonLink1,
  buttonLink2,
  buttonLink3,
  buttonText1,
  buttonText2,
  buttonText3,
  differenceCost1,
  differenceCost2,
  differenceCost3,
  differenceCost4,
  differenceCost5,
  differenceText1,
  differenceText2,
  differenceText3,
  differenceText4,
  differenceText5,
  background1,
  background2,
  background3,
  background4,
  background5,
  img1,
  img2,
  img3,
  img4,
  img5,
  monthlyCost1,
  monthlyCost2,
  monthlyCost3,
  monthlyCost4,
  monthlyCost5,
  text1,
  text2,
  text3,
  text4,
  text5,
  title1,
  title2,
  title3,
  title4,
  title5
  note`;
