import React, {Dispatch, SetStateAction} from "react";
import {DialogContent} from "@material-ui/core";
import {
    Title,
    Icon,
    ContentContainer,
    ContentText,
    LogoContainer,
    Subtitle,
    MediaLogoContainer
} from "../../styles/style";
import PflegeLogo from "../../assets/logo-pflegepartner-dunkelblau-tuerkis.svg";

const content = {
    title: "Vielen Dank für Ihre Anmeldung zu unserem Newsletter.",
    content: "Mit dem Klick auf den Link in Ihrer Bestätigungs-E-Mail haben Sie bereits zugestimmt. " +
        "Die Datenübermittlung war erfolgreich. Den nächsten Newsletter erhalten Sie in Kürze.",
}

export function NewsletterDone() {
    return (
        <>
            <Title id="form-dialog-title">
                {content.title}
            </Title>
            <DialogContent>
                <ContentContainer container>
                    <ContentText>
                        {content.content}
                    </ContentText>
                </ContentContainer>
                <LogoContainer container>
                    <Subtitle id="form-dialog-title">
                        Ihr
                    </Subtitle>
                    <MediaLogoContainer src={PflegeLogo} alt="logo"/>
                    <Subtitle id="form-dialog-title">
                        - Team
                    </Subtitle>
                </LogoContainer>
            </DialogContent>
        </>
    )
}
