import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import { Card, CardContent } from '@material-ui/core';
import { colors, Fonts } from '../../Theme';

export const useStyles = makeStyles({
  root: {
    '& .MuiPaper-rounded': {
      borderRadius: 40,
    },
  },
});

export const StyledCard = styled(Card)`
  height: 250px;
  width: 300px;
  justify-content: center;
  display: flex;
  align-items: center;
  margin: 0 auto;
  @media (max-width: 1280px) {
    width: 272px;
    height: 227px;
  }
  @media (max-width: 900px) {
    width: 264px;
    height: 220px;
  }
`;

export const InnerWrapper = styled.div`
  display: block;
  width: 100%;
  text-align: center;
`;

export const StyledCardContent = styled(CardContent)`
  font-family: ${Fonts.fontText} !important;
`;

export const StyledCardText = styled.span`
  font-family: ${Fonts.fontText} !important;
  font-size: 18px;
  @media (max-width: 1280px) {
    line-height: 23px;
  }
`;

export const MediaWrapper = styled.div<{ text: string }>`
  width: ${(props) => (props?.text?.length > 0 ? '70px' : '200px')};
  height: ${(props) => (props?.text?.length > 0 ? '60px' : '200px')};
`;

export const Text = styled.p`
  color: ${colors.darkBlue};
  font: ${Fonts.fontTextLink};
  width: 265px;
`;

export const AboutUsImageCards = styled.span<{ backgroundImage: any }>`
  background-image: url(${(props) => props.backgroundImage});
  height: 120px;
  width: 180px;
  -o-background-size: ${(props) => (props.backgroundImage.includes('.png') ? 'none' : 'cover')};
  background-size:  ${(props) => (props.backgroundImage.includes('.png') ? 'none' : 'cover')};
  background-repeat: no-repeat;
  background-position: center center;
  box-shadow: none !important;
`;
