// Import Dependencies
import React from 'react';
// Import Components
import TextConverter from '../../utils/RichTextConverter';
import SVG from './SVGBig';
import {
  Content,
  ContentContainer,
  ImageContainer,
  Link,
  StyledSmallIconText,
  StyleLink,
  Subtitle,
  Title,
  Text,
  Wrapper,
} from '../../styles/partials/Step';
// Import Variables

interface StepProps {
  index: number;
  length: number;
  func?: any;
  step: {
    img: string;
    alt: string;
    title: string;
    subtitle: string;
    text?: any;
    url?: string;
    link?: string;
    icon?: string;
    iconText?: string;
    datatype?: 'outbound' | 'internal';
  };
  color: number;
}

export default function ({
  step, index, color, length, func,
}: StepProps) {
  step.url = step?.url ? step?.url : '';

  return (
    <Wrapper
      first={!index}
      second={index === 1}
      third={index === 2}
      fourth={index === 3}
      withIcon={step?.icon}
      length={length}
    >
      <ImageContainer
        container
        alignItems="center"
        justify="center"
        direction="row"
      >
        <SVG svg={step?.img} />
      </ImageContainer>
      <Title stepColor={color}>{step?.title}</Title>
      <ContentContainer>
        <Content isLink={step?.link} isIcon={step?.icon}>
          {step?.subtitle && <Subtitle>{step?.subtitle}</Subtitle>}
          <Text>{TextConverter(step?.text)}</Text>
        </Content>
        <Link>
          {step?.icon && (
            <StyledSmallIconText icon={step?.icon} iconText={step?.iconText} />
          )}
          {step.url.length > 0 && !func && (
            <StyleLink
              url={step?.url}
              datatype={step?.datatype}
              text={step?.link}
            />
          )}
          {func && (
            <StyleLink
              func={func}
              datatype={step?.datatype}
              text={step?.link}
            />
          )}
        </Link>
      </ContentContainer>
    </Wrapper>
  );
}
