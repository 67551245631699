import { ButtonMapper } from "./partials/ButtonMapper";
import { BackgroundMapper } from "./partials/BackgroundMapper";
import { IntroOutro } from "./partials/IntroOutro";

export const CareMapper = (obj: any) => {
  const mapped = {
    ...BackgroundMapper(obj),
    item: {
      ...IntroOutro(obj),
      button: ButtonMapper(obj),
      variant: "care",
      elements: [
        {
          icon: obj.img1,
          title: obj.title1,
          text: obj.text1,
          example: obj.example1,
          background: {
            src: obj.background1,
            alt: obj.background1 ? "background" : "",
            location: obj.location1,
          },
        },
        {
          icon: obj.img2,
          title: obj.title2,
          text: obj.text2,
          example: obj.example2,
          background: {
            src: obj.background2,
            alt: obj.background2 ? "background" : "",
            location: obj.location2,
          },
        },
        {
          icon: obj.img3,
          title: obj.title3,
          text: obj.text3,
          example: obj.example3,
          background: {
            src: obj.background3,
            alt: obj.background3 ? "background" : "",
            location: obj.location3,
          },
        },
        {
          icon: obj.img4,
          title: obj.title4,
          text: obj.text4,
          example: obj.example4,
          background: {
            src: obj.background4,
            alt: obj.background4 ? "background" : "",
            location: obj.location4,
          },
        },
        {
          icon: obj.img5,
          title: obj.title5,
          text: obj.text5,
          example: obj.example5,
          background: {
            src: obj.background5,
            alt: obj.background5 ? "background" : "",
            location: obj.location5,
          },
        },
      ],
    },
  };

  function filterArrayValues(arr: any) {
    arr = arr.filter(isEligible);
    return arr;
  }

  function isEligible(value: any) {
    if (value.icon !== null || value.title !== null) {
      return value;
    }
  }
  mapped.item.elements = filterArrayValues(mapped.item.elements);

  return mapped;
};
