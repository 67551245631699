// Import Dependencies
import React, { useEffect } from 'react';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { useHistory } from 'react-router-dom';
import { Fonts } from '../../Theme';
import { Link } from '../../styles/cta/BlueLink';
import { TextChecker } from '../../utils/RichTextConverter';
import { getButtonLinkType } from '../../utils/ButtonTargetParser';
// Import Components

// Import Variables

interface LinkProps {
  text?: string;
  url?: string;
  newLine?: boolean;
  func?: any;
  datatype?: 'outbound' | 'internal';
}

const funcA = (func: any) => {
  func();
};
export const BlueLink = ({
  text, url, newLine, datatype, func,
}: LinkProps) => {
  const history = useHistory();
  const splitted = url?.split('#') || [];
  if (url && url?.includes('bedarfscheck')) {
    window.open('https://pflegepartner.de/bedarfscheck', '_blank');
  }

  datatype = getButtonLinkType(url);
  const displayedText = (typeof text === 'string' && text) || (text && TextChecker([text]));

  return (
    <Link
      newLine={newLine}
      datatype={`${datatype}Link`}
      onClick={() => (func
        ? funcA(func)
        : splitted[1]?.length > 3 ? history.push(`${url}`)
          : window.open(url, datatype === 'outbound' ? '_blank' : '_self'))}
    >
      {text && (
        <ArrowForwardIosIcon
          style={{
            font: Fonts.fontArrowLink,
            position: 'relative',
            top: 3,
            marginRight: 5,
          }}
        />
      )}
      {displayedText}
    </Link>
  );
};
