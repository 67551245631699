import styled from 'styled-components';
import Grid from '@material-ui/core/Grid';
import { BackgroundConverter } from '../../utils/BackgroundConverter';
import {
  colors,
  Fonts,
  InnerWrapperWidth,
  MDBreakPoint,
  XSBreakPoint,
} from '../../Theme';
import { H2 } from '../Styles';

interface WrapperProps {
  background: { type: string; url: string };
}

export const Wrapper = styled(Grid)<WrapperProps>`
  padding: 50px 16px;
  flex-wrap: unset !important;
  ${({ background }) => BackgroundConverter(background.type, background.url)}}
  @media (max-width: ${XSBreakPoint}) {
    padding: 48px 16px;
  }
`;

export const Title = styled(H2)`
  color: ${colors.darkBlue};
  text-align: center;
  margin-bottom: 15px;
  @media (max-width: ${MDBreakPoint}) {
    margin: 0 16px;
    padding: 0 16px;
  }
`;

export const Text = styled.div`
  margin-bottom: 50px;
  margin-top: 20px;
  text-align: left;
  color: ${colors.black};
  font: ${Fonts.fontText};
  @media (max-width: ${MDBreakPoint}) {
    width: 100%;
    max-width: 1000px;
    padding: 0 16px;
  }
`;

export const StepsContainer = styled(Grid)`
  max-width: 1300px;
`;

export const Item = styled(Grid)``;

export const InnerWrapper = styled.div`
  max-width: ${InnerWrapperWidth};
`;

export const StepWrapper = styled.div<{ len: number }>`
  max-width: 1300px;
  margin: 0 25px;
  @media (max-width: ${MDBreakPoint}) {
    ${(props) => props.len === 4 && 'display: none'}
  }
  @media (max-width: 1080px) {
    display: none;
  }
`;
