export const BLOGS = `
            blogName
            stageImg
            blogTitle
            blogSubtitle
            publishDate
            stageText
            mediumImg
            smallImg
            metaImage
            categories
            description
            ogAlt
            ogTitle
            ogDescription
            ogName
            ogType
            ogLocal
            indexed
            `;
