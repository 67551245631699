import React from 'react';
import '../../index.css';
import { StyledSVG } from '../../styles/partials/SVG';

interface SVGProps {
  svg: string;
  width?: any;
  height?: any;
  style?: React.CSSProperties;
  onClick?: any;
}

const SVG = ({
  svg, width, height, style, onClick,
}: SVGProps) => (
  <StyledSVG
    src={svg}
    width={width}
    height={height}
    className="wrapper"
    style={style}
    onClick={onClick}
  />
);
export default SVG;
