import React, {Dispatch, SetStateAction, useState} from "react";
import {DialogContainer, Icon} from "../styles/style";
import NewsletterForm from "../dialogs/NewsletterForm";
import NewsletterCompleted from "../dialogs/NewsletterCompleted";
import NewsletterApproved from "../dialogs/NewsletterApproved";
import NewsletterDone from "../dialogs/NewsletterDone";
import NewsletterExpired from "../dialogs/NewsletterExpired";

interface DialogProps {
    openDialog: boolean;
    setOpenDialog: Dispatch<SetStateAction<boolean>>;
    param: string | undefined;
    handleCloseDialog: () => void;
}

interface NewsletterProps {
    query: string
}

export function Dialog({openDialog, setOpenDialog, param, handleCloseDialog}: DialogProps) {
    const [isSubmitted, setSubmitionCompleted] = useState(false);

    function NewsletterContainer ({query}: NewsletterProps) {
            if (query?.includes("newsletteranmeldung")) {
                return <NewsletterApproved param={query} />
            } else if (query?.includes("newsletteralreadydone")) {
                return <NewsletterDone />
            } else if (query?.includes("newsletterexpired")) {
                return <NewsletterExpired setOpenDialog={setOpenDialog} handleCloseDialog={handleCloseDialog} />
            } else {
                return !isSubmitted ? <NewsletterForm setSubmitionCompleted={setSubmitionCompleted} /> : <NewsletterCompleted />
            }
    }

    return (
        <DialogContainer
            open={openDialog}
            aria-labelledby="form-dialog-title"
            onClose={() => {
                setOpenDialog(false);
                handleCloseDialog();
            }}>
            <Icon onClick={() => {
                setOpenDialog(false);
                handleCloseDialog();
            }}/>
            <NewsletterContainer query={param || ''} />
        </DialogContainer>
    )
}
