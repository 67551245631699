import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { PagePlaceholder } from '../../components/loaders/PagePlaceholder';

export const Wrapper = styled(Grid)`
  padding: 0;
`;
export const Module = styled(Grid)`
  width: 100%;
`;
export const PagePlaceholderStyled = styled(PagePlaceholder)`
  margin: auto;
`;
