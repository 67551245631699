import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { colors, MDBreakPoint, XSBreakPoint } from '../../Theme';
import contentXs from '../../assets/content-xs.svg';
import contentMd from '../../assets/content-md.svg';
import contentLg from '../../assets/content-lg.svg';
import { H2 } from '../Styles';

export const Wrapper = styled.div`
  background-color: #fff;
  overflow: hidden;
`;

// Die Bilder benötigen eine Rotation sonst passt es nicht mit der UI zusammen.
export const CategoriesContainer = styled(Grid)`
  background: #69d2e6;
  background-repeat: no-repeat;
  background-position: center 100%;
  background-size: 100% auto;
  background-image: url(${contentLg});
  height: auto;

  @media (max-width: ${MDBreakPoint}) {
    background-image: url(${contentMd});
    background-position: center 65%;
  }

  @media (max-width: ${XSBreakPoint}) {
    background-image: url(${contentXs});
    background-position: center 100%;
  }
`;

export const Polygon = styled.svg`
  position: relative;
  background: #96dfed;
  width: 100%;
  height: 64px;
  top: 54px;
  clip-path: polygon(50% 40px, 40% 0%, 60% 0%);
  @media (max-width: ${MDBreakPoint}) {
    clip-path: polygon(50% 40px, 40% 0%, 60% 0%);
    height: 56px;
    top: 46px;
  }

  @media (max-width: ${XSBreakPoint}) {
    clip-path: polygon(50% 30px, 30% 0%, 70% 0%);
    height: 48px;
    top: 38px;
  }
`;

export const Categories = styled(Grid)`
  max-width: 1100px !important;
  @media (max-width: ${XSBreakPoint}) {
    min-width: 300px !important;
  }
`;

export const Title = styled(H2)`
  width: 100%;
  color: ${colors.darkBlue} !important;
  margin-bottom: 0;
  margin-top: 40px;
  @media (max-width: ${MDBreakPoint}) {
    padding: 0;
  }
`;

export const TitleBlog = styled(H2)`
  padding: 64px 8% 40px;
  width: 100%;
  margin: 0 !important;
  color: ${colors.darkBlue} !important;

  @media (max-width: ${MDBreakPoint}) {
    padding: 56px 5% 32px 5%;
    margin: 0 !important;
  }

  @media (max-width: ${XSBreakPoint}) {
    padding: 48px 8% 24px 8%;
    margin: 0 !important;
  }
`;
