import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import {
  colors, Fonts, InnerWrapperWidth, MDBreakPoint,
} from '../../Theme';

export const Wrapper = styled(Grid)`
  @media (max-width: 1080px) {
    display: none !important;
  }
  background: #e0f5f9;
  padding-bottom: 15px;
`;
export const InnerWrapper = styled.div`
  max-width: ${InnerWrapperWidth};
`;

export const ImageWrapper = styled.span`
  padding-top: 20px;
  margin-right: 65px;
  @media (max-width: 1100px) {
    margin-right: 30px;
  }
  float: left;
  :hover {
    cursor: pointer;
  }
`;

export const BorderBottom = styled.div`
  border-bottom: 1px solid #69d2e6;
  margin-top: 19px;
  width: 95%;
  @media (max-width: ${MDBreakPoint}) {
    margin-top: 13px;
  }
`;

export const GridItem = styled(Grid)`
  height: 44px !important;
  margin-right: 65px !important;
  @media (max-width: ${MDBreakPoint}) {
    margin-right: 48px !important;
  }
`;
export const Image = styled.img`
  width: 83px;
  height: 64px;
`;

export const Button = styled.button`
  border: 0px;
  padding-top: 5px;
  background: none;
  color: ${colors.darkBlue};
  :hover {
    cursor: pointer;
  }
  :focus {
    outline: 0;
  }
`;

export const Upper = styled.span`
  font: ${Fonts.fontMainNavInactive};
  @media (max-width: ${MDBreakPoint}) {
    font-size: ${Fonts.fontSizeTextSmall};
  }
`;
export const Sub = styled.span`
  font: ${Fonts.fontKalamRegular};
  font-weight: bold;
  @media (max-width: ${MDBreakPoint}) {
    font-size: ${Fonts.fontSizeTextExtraSmall};
  }
`;
