import styled, { css } from 'styled-components';
import { Grid } from '@material-ui/core';
import { BackgroundConverter } from '../../utils/BackgroundConverter';
import { colors, Fonts, MDBreakPoint } from '../../Theme';
import { H2, H4 } from '../Styles';

interface WrapperProps {
  background: { type: string; url: string };
}
export const OuterWrapper = styled(Grid)<WrapperProps>`
  min-height: 350px;
  padding: 50px 0;
  ${({ background }) => BackgroundConverter(background.type, background.url)}}

`;

export const InnerWrapper = styled.div`
  max-width: 1300px;
  display: block;
  @media (max-width: ${MDBreakPoint}) {
    width: 100%;
    max-width: 1000px;
    padding: 0 20px;
  }
`;

export const Content = styled(Grid)<{ isWithImage?: boolean }>`
  ${(props) => !props.isWithImage
    && css`
      max-width: 1300px !important;
      // value for Testing
      @media (max-width: ${MDBreakPoint}) {
        width: 360px;
      }
    `};
`;

export const Title = styled(H2)`
  color: ${colors.darkBlue};
  @media (max-width: 1300px) {
    padding-left: 16px;
  }
`;

export const Subtitle = styled(H4)`
  color: ${colors.darkBlue};
  @media (max-width: 1300px) {
    padding-left: 16px;
  }
`;
export const Text = styled(Grid)`
  color: ${colors.black};
  font: ${Fonts.fontText};
  max-width: 1000px;
  .paragraph {
    margin: 0 !important;
  }
  .description {
    margin: 20px 0;
  }
`;
