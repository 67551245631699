import { ButtonMapper } from "./partials/ButtonMapper";
import { BackgroundMapper } from "./partials/BackgroundMapper";
import { IntroOutro } from "./partials/IntroOutro";

export const FinancialMapper = (obj: any) => {
  const mapped = {
    ...BackgroundMapper(obj),
    item: {
      ...IntroOutro(obj),
      button: ButtonMapper(obj),
      variant: "finance",
      note: obj.note,
      elements: [
        {
          icon: obj.img1,
          title: obj.title1,
          text: obj.text1,
          monthlyCost: obj.monthlyCost1,
          differenceCost: obj.differenceCost1,
          differenceText: obj.differenceText1,
          background: {
            src: obj.background1 || "",
            alt: obj.background1 ? "text" : "",
            location: obj.background1 ? "left" : "",
          },
        },
        {
          icon: obj.img2,
          title: obj.title2,
          text: obj.text2,
          monthlyCost: obj.monthlyCost2,
          differenceCost: obj.differenceCost2,
          differenceText: obj.differenceText2,
          background: {
            src: obj.background2 || "",
            alt: obj.background2 ? "text" : "",
            location: obj.background2 ? "left" : "",
          },
        },
        {
          icon: obj.img3,
          title: obj.title3,
          text: obj.text3,
          monthlyCost: obj.monthlyCost3,
          differenceCost: obj.differenceCost3,
          differenceText: obj.differenceText3,
          background: {
            src: obj.background3 || "",
            alt: obj.background3 ? "text" : "",
            location: obj.background3 ? "left" : "",
          },
        },
        {
          icon: obj.img4,
          title: obj.title4,
          text: obj.text4,
          monthlyCost: obj.monthlyCost4,
          differenceCost: obj.differenceCost4,
          differenceText: obj.differenceText4,
          background: {
            src: obj.background4 || "",
            alt: obj.background4 ? "text" : "",
            location: obj.background4 ? "left" : "",
          },
        },
        {
          icon: obj.img5,
          title: obj.title5,
          text: obj.text5,
          monthlyCost: obj.monthlyCost5,
          differenceCost: obj.differenceCost5,
          differenceText: obj.differenceText5,
          background: {
            src: obj.background5 || "",
            alt: obj.background5 ? "text" : "",
            location: obj.background5 ? "left" : "",
          },
        },
      ],
    },
  };

  function filterArrayValues(arr: any) {
    arr = arr.filter(isEligible);
    return arr;
  }

  function isEligible(value: any) {
    if (value.icon !== null || value.title !== null) {
      return value;
    }
  }
  mapped.item.elements = filterArrayValues(mapped.item.elements);

  return mapped;
};
