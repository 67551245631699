import React from "react";
import {CheckerContactLabel, CheckerContactText, CheckerContentContainer, CheckerButtonWrapper} from "./style";
import {Cta, LinkWrapper} from "../../../styles/partials/StickySidebar";
import {useMediaQuery} from "@material-ui/core";

const content = {
    label: "Pflegebedarfs-Check",
    text: "In 6 Minuten zur persönlichen<br>Pflege-Organisation",
    smText: "In 6 Minuten zur persönlichen<br>Pflege-Organisation"
}

const CheckerContent = () => {

    const isSm: boolean = useMediaQuery("(max-width: 960px)")

    return (
        <CheckerContentContainer>
            <CheckerContactLabel>{content.label}</CheckerContactLabel>
            <CheckerContactText dangerouslySetInnerHTML={{__html: isSm ? content.smText : content.text}} />
            <CheckerButtonWrapper>
                <LinkWrapper to="/bedarfscheck" target="_blank">
                    <Cta>
                        Starten
                    </Cta>
                </LinkWrapper>
            </CheckerButtonWrapper>
        </CheckerContentContainer>
    )
}

export {
    CheckerContent
};
