// Import Dependencies
import React from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import sfinanzgruppe from '../../assets/logo-s-finanzgruppe.png';
import {
  ContentContainer,
  FirstRow,
  LinkContainer,
  StyledLink,
  Wrapper,
  URL,
  DisplayRow,
  LogoContainer,
  TextContainer,
  FirstImage,
  SecondImage,
  MobileRow,
  SecondRow,
  LogoXs,
  LogoLg,
  LinkContainerBedarf,
  WrapperBedarf,
  SecondRowBedarf,
  LinkBedarf,
  ContentWrapper,
  LinkWrapper,
  LogoSparkasse, Text,
} from '../../styles/components/Footer';
import { NavigationItems } from '../../mocks/mock';

interface FooterProps {
  ukv: { type: string; url: any };
  vkb: { type: string; url: any };
  handleOpen: () => void;
  firstColumn: {
    to: string;
    text: string;
    background: object;
  }[];
  secondColumn: {
    to: string;
    text: string;
    background: object;
  }[];
  text: string;
  cooperation: string;
  setValue: any;
}

export const Footer = ({
  ukv,
  vkb,
  firstColumn,
  secondColumn,
  text,
  cooperation,
  setValue,
  handleOpen,
}: FooterProps) => {
  const goUp = (pageIndex: number, pageName?: string, to?: string) => {
    setTimeout(() => {
      window.scrollTo(0, 102);
    }, 2);
    setValue(pageIndex);
    if (pageName === 'Cookie-Einstellungen') handleOpen();
    if (to) { history.push(to); } else { history.push(NavigationItems[pageIndex].to); }
  };
  const history = useHistory();

  return (
    <>
      {!useLocation().pathname.includes('/bedarfscheck')
        ? (
          <Wrapper container alignItems="center" direction="column">
            <ContentContainer item container xs={12} md={12} alignItems="center">
              <FirstRow item xs={12} md={4} lg={3}>
                {firstColumn.map((element, index) => (
                  <LinkContainer item key={index}>
                    <StyledLink onClick={() => goUp(index)} datatype="internal">
                      <a href={element.to} style={{ textDecoration: 'none', display: 'none' }} />
                      {element.text}
                    </StyledLink>
                  </LinkContainer>
                ))}
              </FirstRow>
              <FirstRow item xs={12} md={3}>
                {secondColumn.map((element, index) => (
                  <LinkContainer item key={index}>
                    {element.to.length > 15 ? (
                      <URL href={element.to} datatype="outbound" target="_blank">
                        {element.text}
                      </URL>
                    ) : (
                      <StyledLink
                        onClick={() => goUp(0, element.text, element.to)}
                        id={element.text === 'Cookie-Einstellungen' ? 'cookie' : ''}
                        datatype="internal"
                        title={element.text}
                      >
                        <a href={element.to} style={{ textDecoration: 'none', display: 'none' }} />
                        {element.text}
                      </StyledLink>
                    )}
                  </LinkContainer>
                ))}
                <LinkContainer item />
              </FirstRow>
              <DisplayRow
                item
                md={5}
                container
                direction="row"
                justify="flex-end"
                alignItems="center"
              >
                <LogoContainer item>
                  <TextContainer item>{cooperation}</TextContainer>
                  <Link to="//www.ukv.de" target="_blank">
                    <FirstImage src={ukv.url} alt="image" />
                  </Link>
                  <Link to="//www.vkb.de" target="_blank">
                    <SecondImage src={vkb.url} alt="image" />
                  </Link>
                </LogoContainer>
              </DisplayRow>
              <MobileRow
                item
                xs={12}
                container
                direction="row"
                justify="flex-start"
                alignItems="center"
              >
                <LogoContainer item>
                  <TextContainer item>{cooperation}</TextContainer>
                  <Link to="//www.ukv.de" target="_blank">
                    <FirstImage src={ukv.url} alt="image" />
                  </Link>
                  <Link to="//www.vkb.de" target="_blank">
                    <SecondImage src={vkb.url} alt="image" />
                  </Link>
                </LogoContainer>
              </MobileRow>
              <SecondRow
                item
                xs={12}
                md={12}
                container
                direction="row"
                justify="space-between"
                alignItems="flex-start"
              >
                <LogoXs item md={2} xs={12}>
                  <img src={sfinanzgruppe} alt="image" />
                </LogoXs>
                <LinkContainer container item>
                  <LinkContainer item md={10} xs={12} lg={9}>
                    {text}
                  </LinkContainer>
                  <LogoLg item md={1} xs={12} lg={2}>
                    <img src={sfinanzgruppe} alt="image" />
                  </LogoLg>
                </LinkContainer>
              </SecondRow>
            </ContentContainer>
          </Wrapper>
        ) : (
          <WrapperBedarf>
            <ContentWrapper container>
              <LinkContainerBedarf container xs={12}>
                {secondColumn.map((element, index) => (
                  <LinkWrapper item key={index}>
                    <Grid
                      onClick={() => goUp(0, element.text, element.to)}
                      id={element.text === 'Cookie-Einstellungen' ? 'cookie' : ''}
                      datatype="internal"
                      title={element.text}
                    >
                      {element.text !== 'Cookie-Einstellungen'
                        ? (
                          <a href={element.to} style={{ textDecoration: 'none' }}>
                            <LinkBedarf>{element.text}</LinkBedarf>
                          </a>
                        )
                        : (
                          <LinkBedarf>{element.text}</LinkBedarf>
                        )}
                    </Grid>
                  </LinkWrapper>
                ))}
              </LinkContainerBedarf>
              <SecondRowBedarf item xs={12}>
                <Text>© PflegePartner 2021</Text>
                <LogoSparkasse src={sfinanzgruppe} alt="image" />
              </SecondRowBedarf>
            </ContentWrapper>
          </WrapperBedarf>
        )}
    </>
  );
};
