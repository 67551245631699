import React from 'react';
import { Button, Img, ButtonText } from '../../styles/cta/ButtonIcon';

export interface ButtonProps {
  index: number;
  chosen: number;
  setCategory?: any;
  data: { name: string; icon: any };
}

const ButtonIcon = ({
  data, index, chosen, setCategory,
}: ButtonProps) => (
  <Button
    data={data}
    color="blue"
    index={index}
    chosen={chosen}
    onClick={setCategory}
  >
    <Img data={data} index={index} chosen={chosen} src={data.icon} alt="image" />
    <ButtonText>
      {data.name === 'Pflege Zu Hause' ? 'Pflege zu Hause' : data.name}
    </ButtonText>
  </Button>
);

export default ButtonIcon;
