// Import Dependencies
import React from 'react';
import 'swiper/swiper-bundle.css';
import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Components
import styled from 'styled-components';
import Step from '../partials/Step';
import Introduction from '../partials/Introduction';
import Closure from '../partials/Closure';
import {
  InnerWrapper,
  Item,
  StepsContainer,
  StepWrapper,
  Wrapper,
} from '../../styles/components/ProcessComponent';
import { colors, MDBreakPoint } from '../../Theme';
// Import Variables

const StyledSwiper = styled(Swiper)<{ len: number; icon: any }>`
  display: none;
  @media (max-width: ${MDBreakPoint}) {
    ${(props) => props.len === 4 && 'display: block'};
    ${(props) => props.len === 4 && 'height: 600px;'};
    padding: 40px 0;
  }
  @media (max-width: 1080px) {
    margin-left: auto;
    margin-right: auto;
    padding: 10px 0;
    display: block;
    width: 600px;
    height: ${(props) => (props.icon ? '750px' : '640px')};
  }
  @media (max-width: 700px) {
  }
  .swiper-container {
    width: 100%;
    height: 600px;
    box-sizing: border-box;
  }
  .swiper-button-next,
  .swiper-button-prev,
  .swiper-scrollbar { 
    display: none;
  }
  .swiper-slide {
    width: 360px !important;
    @media (max-width: 650px) { 
      width: 310px !important;
      left: -17px;
    }
  }
  .swiper-pagination {
    bottom: 5px;
  }
  .swiper-pagination-bullet {
    background-color: ${colors.turkis};
  }
  .swiper-pagination-bullet-active {
    background-color: ${colors.darkBlue};
  }
  .swiper-wrapper li:last-of-type {
    margin-right: 20px;
  }
`;

interface ProcessProps {
  background: { type: string; url: string };
  title?: string;
  subtitle?: string;
  introduction?: any;
  closing?: any;
  button?: any;
  func?: any;
  func2?: any;
  steps: {
    img: string;
    alt: string;
    title: string;
    subtitle: string;
    text: any;
    url?: string;
    link?: string;
    icon?: string;
    iconText?: string;
  }[];
}

SwiperCore.use([Pagination]);

export const ProcessComponent = ({
  background,
  introduction,
  title,
  subtitle,
  closing,
  steps,
  button,
  func,
  func2,
}: ProcessProps) => {
  func2 = func2 || false;

  if (button?.length > 0) {
    if (func) button[0].func = func;
  }

  return (
    <Wrapper
      container
      alignItems="center"
      direction="column"
      background={background}
    >
      <InnerWrapper>
        {(title || subtitle || introduction) && (
          <Introduction
            title={title}
            subtitle={subtitle}
            introduction={introduction}
            alignText="left"
          />
        )}
      </InnerWrapper>
      <StepWrapper len={steps.length}>
        <StepsContainer
          container
          alignItems="center"
          justify="center"
          direction="row"
        >
          {steps?.map((step, index) => (
            <Item key={index} item xs={steps.length === 3 ? 4 : 3}>
              <Step
                length={steps.length}
                step={step}
                index={index}
                color={steps.length === 3 ? index : steps.length}
                func={func2}
              />
            </Item>
          ))}
        </StepsContainer>
      </StepWrapper>

      <StyledSwiper
        len={steps.length}
        icon={steps[0]?.icon}
        slidesPerView={1}
        navigation
        pagination={{ clickable: true }}
        scrollbar={{ draggable: true }}
        autoplay
        onSwiper={() => {}}
        onSlideChange={() => {}}
        style={{}}
        translate="no"
        width={300}
      >
        {steps?.map((step, index) => (
          <SwiperSlide key={index}>
            <Step
              length={steps.length}
              step={step}
              index={index}
              color={steps.length === 3 ? index : steps.length}
            />
          </SwiperSlide>
        ))}
      </StyledSwiper>
    </Wrapper>
  );
};
