import styled, { css } from 'styled-components';
import { colors, Fonts, MDBreakPoint } from '../../Theme';

interface BorderProps {
  border: boolean;
  fullWidth?: boolean;
}

export const Button = styled.button<BorderProps>`
  background: transparent;
  ${(border) => border
    && css`
      border: 3px solid #0082ff;
    `};
  border-radius: 40px;
  color: ${colors.middleBlue};
  font: ${Fonts.fontButtonText};
  padding: 15px 40px;
  text-align: center;
  display: inline-block;
  @media (max-width: ${MDBreakPoint}) {
  }
  :focus {
    outline: none;
  }
  :hover {
    cursor: pointer;
  }
`;
