// Import Dependencies
import React from 'react';
import { Grid, GridSize } from '@material-ui/core';
// Import Components
import RoundIcons from '../partials/RoundIcons';
// Import Variables
import SwiperWrapper from '../partials/SwiperWrapper';
import Introduction from '../partials/Introduction';
import Closure from '../partials/Closure';
import {
  Container,
  DesktopView,
  InnerWrapper,
  Text,
  MobileView,
  OuterWrapper,
} from '../../styles/components/IconComponent';

interface IconProps {
  background: { type: string; url: string };
  title?: any;
  subtitle?: string;
  introduction?: any;
  isBlog?: boolean;
  images: { icon: string; text: string }[];
  closing?: any;
  button?: { title: string; color: string; url: string }[];
  setNavigationIndex?: (index: number) => void;
}

function calculateImageGrideSize(
  images: { icon: string; text: string }[],
): GridSize {
  switch (images.length) {
    case 1:
      return 12;
    case 2:
      return 6;
    default:
      return 4;
  }
}

const IconComponent = ({
  title,
  subtitle,
  introduction,
  images,
  background,
  closing,
  button,
  isBlog, setNavigationIndex,
}: IconProps) => (
  <OuterWrapper
    container
    justify="center"
    alignItems="center"
    background={background}
    isBlog={isBlog}
  >
    <InnerWrapper>
      {(title || subtitle || introduction) && (
        <Introduction
          title={title}
          subtitle={subtitle}
          introduction={introduction}
          alignText="left"
        />
      )}
      <Grid
        item
        container
        direction="row"
        alignItems="center"
        justify="center"
        xs={12}
      >
        <DesktopView>
          <Container item container direction="row">
            {images.map((image, index) => (
              <Grid
                container
                direction="column"
                alignItems="center"
                justify="center"
                item
                xs={12}
                md={calculateImageGrideSize(images)}
                lg={calculateImageGrideSize(images)}
                key={index}
              >
                {image.icon && (
                  <RoundIcons icon={image.icon} width={80} height={60} />
                )}
                {image.text && <Text>{image.text}</Text>}
              </Grid>
            ))}
          </Container>
        </DesktopView>
        <MobileView>
          <SwiperWrapper elements={images} isIcon />
        </MobileView>
        <Closure buttons={button} closing={closing} buttonsAlign="center" setNavigationIndex={setNavigationIndex} />
      </Grid>
    </InnerWrapper>
  </OuterWrapper>
);

export default IconComponent;
