import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { colors, MDBreakPoint } from '../../Theme';

export const Wrapper = styled(Grid)<{
  backgroundLg?: string;
  backgroundMd?: string;
  backgroundXs?: string;
}>`
  background: radial-gradient(
        100% 100% at 50% 0%,
        transparent 74%,
        rgb(255, 255, 255, 0.5) 0%
      )
      no-repeat 50%,
    ${(props) => (props.backgroundLg
    ? `url(${props.backgroundLg}) no-repeat center,`
    : `repeating-linear-gradient(${colors.lightTurkis} 100%, ${colors.lightTurkis} 100%) no-repeat 50% 50% / 100% 100%,`)}
      radial-gradient(100% 100% at 50% 0%, #e0f5f9 74%, #ffffff 0%) no-repeat
      50% 50% / 100% 100%;

  background-size: 100% 681px, 1556px 681px, 100% 681px;
  top: -2px;
  height: 681px;

  @media (max-width: ${MDBreakPoint}) {
    margin: 0 0 40px 0;
    height: 560px;
    background: radial-gradient(
          100% 100% at 50% 0%,
          transparent 74%,
          rgb(255, 255, 255, 0.5) 0%
        )
        no-repeat 50%,
      ${(props) => (props.backgroundMd
    ? `url(${props.backgroundMd}) no-repeat center,`
    : `repeating-linear-gradient(${colors.lightTurkis} 100%, ${colors.lightTurkis} 100%) no-repeat 50% 50% / 100% 100%,`)}
        radial-gradient(100% 100% at 50% 0%, #e0f5f9 74%, #ffffff 0%) no-repeat
        50% 50% / 100% 100%;
    background-size: auto, 1280px 560px, auto;
    background-position: bottom;
    //min-width: 750px;
  }

  @media (max-width: 960px) {
    height: 420px;
    background: radial-gradient(
          100% 100% at 50% 0%,
          transparent 74%,
          rgb(255, 255, 255, 0.5) 0%
        )
        no-repeat 50%,
      ${(props) => (props.backgroundXs
    ? `url(${props.backgroundXs}) no-repeat center,`
    : `repeating-linear-gradient(${colors.lightTurkis} 100%, ${colors.lightTurkis} 100%) no-repeat 50% 50% / 100% 100%,`)}
        radial-gradient(100% 100% at 50% 0%, #e0f5f9 74%, #ffffff 0%) no-repeat
        50% 50% / 100% 100%;
    background-size: auto, 960px 420px, 100%;
  }
`;

export const Separator = styled.div`
  height: 30px;
  margin: 15px 0 0;
`;
