import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { BackgroundConverter } from '../../utils/BackgroundConverter';
import {
  colors, Fonts, InnerWrapperWidth, MDBreakPoint,
} from '../../Theme';
import { H2, H3 } from '../Styles';

interface WrapperProps {
  background: { type: string; url: string };
  icon: boolean;
  isBlog?: boolean;
}

export const Wrapper = styled(Grid)<WrapperProps>`
  height: ${(props) => props.icon && '100%'};
  padding: ${({ isBlog }) => (isBlog ? '0 16px' : '50px 16px')};
  ${({ background }) => BackgroundConverter(background.type, background.url)};

  .MuiStepIcon-text {
    font-family: Raleway !important;
  }
  .MuiStepLabel-iconContainer {
    padding-right: 16px;
  }
`;

export const Title = styled(H2)`
  color: ${colors.darkBlue};
  text-align: center;
  max-width: 1000px;
  padding: 0 16px;
  width: 100%;
`;
export const Subtitle = styled(H3)`
  color: ${colors.darkBlue};
  text-align: center;
  max-width: 1000px;
  padding: 0 16px;
  width: 100%;
`;
export const Text = styled(Grid)`
  color: ${colors.black};
  font: ${Fonts.fontText};
  margin-bottom: 60px !important;
  @media (max-width: ${MDBreakPoint}) {
    max-width: 1000px;
    width: 100%;
  }
`;
export const Icon = styled.img`
  height: 350px;
  margin-top: 320px;
  position: relative;
  left: 163px;
  @media (min-width: 1600px) {
    left: 17%;
  }
  @media (max-width: 1400px) {
    display: none;
  }
`;

export const InnerWrapper = styled.div`
  width: ${InnerWrapperWidth};
  margin-right: auto;
  margin-left: auto;
`;
