// Import Dependencies
import React, {useEffect, useState} from 'react';
import {
  Route, Switch, Redirect, useLocation, useHistory
} from 'react-router-dom';
import styled from 'styled-components';
import { useQuery } from '@apollo/client';

// Import Components
import { WegeLeitenPage } from './pages/WegeLeitenPage';
import { AboutUs } from '../graphql/queryTemplate/AboutUs';
import { WegeLeiten } from '../graphql/queryTemplate/WegeLeiten';
import { Finanzieren } from '../graphql/queryTemplate/Finanzieren';
import FinanzierenPage from './pages/FinanzierenPage';
import Navigation from './components/Navigation';
import ZuhausePage from './pages/ZuhausePage';
import { BedarfscheckHeaderComponent } from './components/BedarfscheckHeaderComponent';
import { Footer } from './components/Footer';
import TopPageNavigation from './components/TopPageNavigation';
import InfoPage from './pages/InfoPage';
import BlogsDashboard from './pages/BlogsDashboard';
import { BedarfPage } from './pages/BedarfPage';
import StickyComponent from './partials/StickySidebar';
import { AboutPage } from './pages/AboutPage';
import Dialog from "./components/Dialog";

// Import Variables
import { footerObj } from '../mocks/mock';
import { ImpressumObj, ComplianceObj, PrivPolicyObj } from '../mocks/mockImpressum';
import ZipcodeComponent from './partials/ZipcodeComponent';
import {
  GET_ABOUT_US,
  GET_PFLEGE_FINANZIEREN,
  GET_WEGE_LEITEN,
  GET_ZUHAUSE,
  GET_META,
  GET_BLOGS,
} from '../graphql/queries';
import { Zuhause } from '../graphql/queryTemplate/Zuhause';
import { Metadata } from '../graphql/queryTemplate/Metadata';
import { Blogs } from '../graphql/queryTemplate/Blogs';
import Blog from './pages/Blog';

const Wrapper = styled.div`
  margin: 0 auto;
  font-size: calc(12px + 0.221vw);
`;

const optCache = (cacheIdentID: string) => ({
  context: {
    headers: {
      cacheIdent: cacheIdentID,
    },
  },
});

function App() {
  /*
   *  #TODO: Add nextFetchPolicy option to optimise requests after they have been fetched once
   * TODO: might want to delete setvalue in about page
   */
  const { loading: MetadataLoading } = useQuery(GET_META, optCache('md'));
  const { loading: WegeLeitenLoading } = useQuery(
    GET_WEGE_LEITEN,
    optCache('wl'),
  );
  const { loading: FinanzierenLoading } = useQuery(
    GET_PFLEGE_FINANZIEREN,
    optCache('fl'),
  );
  const { loading: BlogLoading } = useQuery(GET_BLOGS, optCache('bl'));
  const { loading: AboutUsLoading } = useQuery(GET_ABOUT_US, optCache('au'));
  const { loading: ZuhauseLoading } = useQuery(GET_ZUHAUSE, optCache('zl'));

  const BlogsData = Blogs();
  BlogsData.isLoading = BlogLoading;
  const MetadataData = Metadata();
  MetadataData.isLoading = MetadataLoading;
  const AboutUsPage = AboutUs();
  AboutUsPage.isLoading = AboutUsLoading;
  const WLeitenPage = WegeLeiten();
  WLeitenPage.isLoading = WegeLeitenLoading;
  const PFinanzieren = Finanzieren();
  PFinanzieren.isLoading = FinanzierenLoading;
  const PZuhause = Zuhause();
  PZuhause.isLoading = ZuhauseLoading;

  const [value, setValue] = useState(0);
  const [zipOpen, setZipOpen] = useState(false);
  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [buttonText, setButtonText] = useState('');
  const [ukv, setUkv] = useState('');
  const [vkb, setVkb] = useState('');
  const [err, setError] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (location.search.includes("newsletter")) {
      setOpenDialog(true);
    }
  }, [])

  const zipFunc = {
    setZipOpen,
    setTitle,
    setText,
    setButtonText,
    setUkv,
    setVkb,
  };

  const handleCloseZip = () => {
    setZipOpen(false);
    setError(false);
  };

  const handleOpenOpenZip = () => {
    setZipOpen(true);
  };

  function handleCloseDialog() {
    if (history.location.search.includes("newsletter")) {
      history.replace({
        search: '',
      });
    }
  }

  const blog = Blogs();
  //! MetadataData.isLoading && searchEngineIndex(MetadataData.searchengineIndex);
  const navigationElements = !MetadataData.isLoading && MetadataData.navigationElements;
  return (
    <Wrapper id="top">
      <ZipcodeComponent
        handleClose={handleCloseZip}
        open={zipOpen}
        text={text}
        buttonText={buttonText}
        title={title}
        ukv={ukv}
        vkb={vkb}
        err={err}
        setError={setError}
      />
      {!useLocation().pathname.includes('/bedarfscheck')
        ? (
          <Navigation
            loading={MetadataData.isLoading}
            navigationElements={navigationElements}
            value={value}
            setValue={setValue}
            handleOpenOpenZip={handleOpenOpenZip}
            zipFunc={zipFunc}
          />
        ) : (
          <BedarfscheckHeaderComponent />
        )}

      <TopPageNavigation
        setValue={setValue}
        handleOpenOpenZip={handleOpenOpenZip}
        zipFunc={zipFunc}
      />

      <Dialog openDialog={openDialog} setOpenDialog={setOpenDialog} param={location.search} handleCloseDialog={handleCloseDialog}/>

      {!blog.isLoading && (
        <Switch>
          <Route path="/az">
            <Redirect to="/?utm_source=az&utm_medium=advertorial&utm_campaign=2021_az&utm_content=pflegekompass" />
          </Route>
          <Route path="/ga" exact>
            <Redirect to="/?utm_source=ga&utm_medium=print&utm_campaign=2021_ga" />
          </Route>
          <Route path="/nl" exact>
            <Redirect to="/?utm_source=newsletter&utm_medium=email&utm_campaign=2021_kw19" />
          </Route>
          <Route path="/pv" exact>
            <Redirect to="/?utm_source=automail&utm_medium=email&utm_campaign=2021_pflegepartner" />
          </Route>
          <Route path="/mgs" exact>
            <Redirect to="/?utm_source=mgs&utm_medium=teaser&utm_campaign=2021_kw42_mgs" />
          </Route>
          {blog?.blogs?.map((blog: any) => (
            <Route
              path={`/blog/${blog.blogName}`}
              render={() => (
                <Blog
                  blogName={blog.blogName}
                  stage={{
                    img: blog.stageImg,
                    imgMd: blog.mediumImg,
                    imgXs: blog.smallImg,
                    title: blog.blogTitle,
                    subtitle: blog.blogSubtitle,
                    text: blog.stageText,
                    description: blog.description,
                  }}
                  meta={{
                    ogTitle: blog.ogTitle,
                    ogAlt: blog.ogAlt,
                    ogDescription: blog.ogDescription,
                    ogName: blog.ogName,
                    ogType: blog.ogType,
                    ogLocal: blog.ogLocal,
                    indexed: blog.indexed,
                  }}
                  zipFunc={zipFunc}
                  setValue={setValue}
                  blogMetadata={blog}
                  handleOpenOpenZip={handleOpenOpenZip}
                  setOpenDialog={setOpenDialog}
                />
              )}
            />
          ))}
          <Route
            path="/pflege-finanzieren"
            render={() => (
              <FinanzierenPage
                handleOpenOpenZip={handleOpenOpenZip}
                setValue={setValue}
                zipFunc={zipFunc}
                setOpenDialog={setOpenDialog}
              />
            )}
          />
          <Route
            path="/pflege-zu-hause"
            render={() => (
              <ZuhausePage
                zipFunc={zipFunc}
                handleOpenOpenZip={handleOpenOpenZip}
                setValue={setValue}
                setOpenDialog={setOpenDialog}
              />
            )}
          />
          <Route
              path="/ueber-uns"
              render={() => <AboutPage setOpenDialog={setOpenDialog}/>}
          />
          <Route
            path="/impressum"
            render={() => <InfoPage {...ImpressumObj} pageType={'impressum'}  />}
          />
          <Route
            path="/compliance"
            render={() => <InfoPage {...ComplianceObj} pageType={'compliance'} />}
          />
          <Route
            path="/privacy-policy"
            render={() => <InfoPage {...PrivPolicyObj} pageType={'privPolicy'} />}
          />
          <Route exact path="/blog" render={() => <BlogsDashboard setOpenDialog={setOpenDialog}/>} />
          <Route path="/bedarfscheck" component={BedarfPage} />
          <Route
            path="/"
            render={() => (
              <WegeLeitenPage
                handleOpenOpenZip={handleOpenOpenZip}
                zipFunc={zipFunc}
                setNavigationIndex={setValue}
                setOpenDialog={setOpenDialog}
              />
            )}
          />
        </Switch>
      )}
      <Footer
        {...footerObj}
        setValue={setValue}
        handleOpen={() => {
        }}
      />
      {!useLocation().pathname.includes('/bedarfscheck')
        && (
        <StickyComponent />
        )}
    </Wrapper>
  );
}

export default App;
