export const CARD_COMPONENT = ` 
            backside1
            backside2
            backside3
            backside4
            backside5
            backside6
            img1
            img2
            img3
            img4
            img5
            img6
            text1
            text2
            text3
            text4
            text5
            text6`;
