// Import Dependencies
import React, {Dispatch, SetStateAction, useEffect} from 'react';

import { useLocation } from 'react-router';
import TabsComponent from '../components/TabsComponent';
import { StageComponent } from '../components/StageComponent';
import { TextComponent } from '../components/TextComponent';
import SliderComponent from '../components/SliderComponent';
import { ProcessComponent } from '../components/ProcessComponent';
import { StepsComponent } from '../components/StepsComponent';
import { CardComponent } from '../components/CardComponent';
import { Zuhause } from '../../graphql/queryTemplate/Zuhause';
import { metaDataHandler } from '../../utils/MetaInfoHandler';
import { Metadata } from '../../graphql/queryTemplate/Metadata';
import {
  Module,
  PagePlaceholderStyled,
  Wrapper,
} from '../../styles/pages/ZuhausePage';
import { Scroller } from '../../utils/Scroller';
// import BedarfscheckComponent from "../components/BedarfscheckComponent";

interface Props {
  handleOpenOpenZip?: () => void;
  setValue: Dispatch<number>;
  zipFunc?: {
    setTitle: any;
    setText: any;
    setButtonText: any;
    setUkv: any;
    setVkb: any;
  };
  setOpenDialog: Dispatch<SetStateAction<boolean>>;
}

const ZuhausePage = ({ zipFunc, handleOpenOpenZip, setValue, setOpenDialog }: Props) => {
  const handleZipSlider1 = () => {
    if (zipFunc && handleOpenOpenZip) {
      zipFunc.setTitle('Kostenlos registrieren');
      zipFunc.setText(
        '„Mein PflegePartner” bieten wir gemeinsam mit unseren Partnern an. Um fortzufahren, geben Sie bitte Ihre Postleitzahl ein:',
      );
      zipFunc.setButtonText('Senden');
      zipFunc.setUkv(
        'https://pflegepartner.ukv.de/ppd/#/meinpflegepartner/public/registrierung',
      );
      zipFunc.setVkb(
        'https://pflegepartner.vkb.de/ppd/#/meinpflegepartner/public/registrierung',
      );
      handleOpenOpenZip();
    }
  };
  const handleZipSlider2 = () => {
    if (zipFunc) {
      zipFunc.setTitle('Zum Login');
      zipFunc.setText(
        '„Mein PflegePartner” bieten wir gemeinsam mit unseren Partnern an. Um fortzufahren, geben Sie bitte Ihre Postleitzahl ein:',
      );
      zipFunc.setButtonText('Senden');
      zipFunc.setUkv(
        'https://anmeldung.vkb.de/auth/realms/pflegepartner-test/protocol/openid-connect/auth?client_id=pflegeportal-frontend&redirect_uri=https%3A%2F%2Fpflegepartner.ukv.de%2Fppd%2F%23%2Fukv%2Fportal%2Fdashboard&state=b6b7dfb2-75d0-441c-844a-7bc1a40feaa0&nonce=0ebeb72a-6a33-4fc4-99d5-086b5a38b1cc&response_mode=fragment&response_type=code&scope=openid&login_hint=ukv',
      );
      zipFunc.setVkb(
        'https://anmeldung.vkb.de/auth/realms/pflegepartner-test/protocol/openid-connect/auth?client_id=pflegeportal-frontend&redirect_uri=https%3A%2F%2Fpflegepartner.vkb.de%2Fppd%2F%23%2Fvkb%2Fportal%2Fdashboard&state=be2d4501-836d-4972-8c69-b2f51f233638&nonce=89f75c9c-688c-48c5-8b39-d6dfb8d93435&response_mode=fragment&response_type=code&scope=openid&login_hint=vkb',
      );
    }
    if (handleOpenOpenZip) handleOpenOpenZip();
  };
  const metaDescription = 'Die meisten Pflegebedürftigen wollen solange wie möglich im gewohnten Umfeld bleiben. Erfahren Sie, wie Sie eine gute Pflege zu Hause garantieren.';
  const metaTitle = 'Pflege zu Hause | PflegePartner';
  const metaProps = {
    url: 'https://pflegepartner.de/pflege-zu-hause',
    title: metaTitle,
    description: metaDescription,
  };
  metaDataHandler(
    metaProps,
  );

  const MetaData = Metadata();
  !MetaData.isLoading && metaDataHandler(MetaData.zuhause);
  const PageData = Zuhause();

  const { pathname, hash } = useLocation();
  useEffect(() => setValue && setValue(2), []);

  return (
    <Wrapper container direction="column">
      {PageData.isLoading ? (
        <PagePlaceholderStyled />
      ) : (
        <>
          <Scroller hash={hash} />
          <StageComponent {...PageData.nav[0]} setOpenDialog={setOpenDialog}/>
          <Wrapper container direction="column" alignItems="center">
            <Module id="pflege" item>
              <TabsComponent {...PageData.tabs[0]} isRound />
            </Module>
            {/* <Module id="bedarfcheck" item> */}
            {/*  <BedarfscheckComponent /> */}
            {/* </Module> */}
            <Module id="organisation" item>
              <ProcessComponent {...PageData.process[0]} elementsInSlide={3} />
            </Module>
            <Module id="betreuungsangebote" item>
              <CardComponent {...PageData.cards[0]} />
            </Module>
            <Module id="unterstuetzung" item>
              {PageData.text.map((text: any) => (
                <>
                  <TextComponent {...text} />
                </>
              ))}
            </Module>
            <Module id="slider" item>
            </Module>
            <Module id="hilfsmittel" item>
              <TabsComponent {...PageData.tabsTwo[0]} isRound />
            </Module>
            <Module item xs={12}>
              <TextComponent {...PageData.textNew[0]} />
            </Module>
            <Module id="barrierefreiheit" item>
              <StepsComponent {...PageData.steps[0]} />
            </Module>
            <Module id="umzug" item>
              <TextComponent {...PageData.textTwo[0]} />
            </Module>
          </Wrapper>
        </>
      )}
    </Wrapper>
  );
};
export default ZuhausePage;
