import React from "react";
import {DialogContent} from "@material-ui/core";
import {
    ContentContainer,
    LogoContainer,
    Title,
    Subtitle,
    ContentText,
    MediaFaceContainer,
    MediaLogoContainer
} from "../../styles/style";
import FaceHappyIcon from "../../assets/face-happy.png";
import FaceSadIcon from "../../assets/face-sad.png";
import PflegeLogo from "../../assets/logo-pflegepartner-dunkelblau-tuerkis.svg";

interface NewsletterApprovedProps {
    param: string | null | undefined;
}

interface SubContentProps {
    isSuccess: boolean | undefined,
    data: {
        title: string;
        subtitle: string;
        content: string
    }
}

const content = {
    success: {
        title: "Es hat geklappt!",
        subtitle: "Ihre Newsletter-Anmeldung war erfolgreich",
        content: "Vielen Dank und herzlich willkommen! " +
            "Ab jetzt erhalten Sie von uns regelmäßig interessante Informationen und Tipps rund um das Thema Pflege. " +
            "Die aktuelle Ausgabe unseres Newsletters wartet bereits in Ihrem Posteingang. " +
            "Wir hoffen, dass Sie mit unserem Newsletter nützliche Antworten auf Ihre Fragen erhalten. ",
    },
    signOut: {
        title: "Abmeldung Newsletter!",
        subtitle: "Schade, dass Sie kein Interesse mehr an unserem Newsletter haben.",
        content: "Bei Bedarf können Sie sich jederzeit wieder für den Newsletter-Versand entscheiden. Wir würden uns freuen.",
    }
}

function SubContent ({isSuccess, data}: SubContentProps) {
    return isSuccess ? (
        <>
            <Subtitle id="form-dialog-title">
                {data.subtitle}
            </Subtitle>
            <ContentContainer container>
                <ContentText>
                    {data.content}
                </ContentText>
            </ContentContainer>
        </>
    ) : (
        <>
            <ContentContainer container>
                <ContentText>
                    {data.subtitle}
                </ContentText>
            </ContentContainer>
            <ContentContainer container>
                <ContentText>
                    {data.content}
                </ContentText>
            </ContentContainer>
        </>
    )
}

export function NewsletterApproved({param}: NewsletterApprovedProps) {
    const isSuccess = param?.includes("true");
    const data = isSuccess ? content.success : content.signOut;
    const FaceIcon =  isSuccess ? <MediaFaceContainer src={FaceHappyIcon} alt="happyFace"/> : <MediaFaceContainer src={FaceSadIcon} alt="sadFace"/>;

    return (
        <>
            <Title id="form-dialog-title">
                {data.title}
                {FaceIcon}
            </Title>
            <DialogContent>
                <SubContent isSuccess={isSuccess} data={data} />
                <LogoContainer container>
                    <Subtitle id="form-dialog-title">
                        Ihr
                    </Subtitle>
                    <MediaLogoContainer src={PflegeLogo} alt="logo"/>
                    <Subtitle id="form-dialog-title">
                        - Team
                    </Subtitle>
                </LogoContainer>
            </DialogContent>
        </>
    )
}
