import React from 'react';
import {
  ContentLoaderStyled,
  RectMD,
  RectXL,
  Wrapper,
} from '../../styles/loaders/PagePlaceholder';

export const PagePlaceholder = () => (
  <Wrapper>
    <ContentLoaderStyled>
      <RectXL x="0" y="40" rx="3" ry="3" width="1300" height="1800" />
      <RectMD x="0" y="40" rx="3" ry="3" width="600" height="1800" />
      <RectMD x="0" y="40" rx="3" ry="3" width="200" height="1800" />
    </ContentLoaderStyled>
  </Wrapper>
);
