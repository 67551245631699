import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { XSBreakPoint } from '../../Theme';

export const IconWrapper = styled.div`
  display: block;
  width: 150px;
  height: 150px;
  background: #ffffff 50% 50% no-repeat padding-box;
  -moz-border-radius: 75px;
  -webkit-border-radius: 75px;
  background-size: 100px 100px;
  box-shadow: 0px 3px 6px #00000029;
  margin-bottom: 30px !important;
  @media (max-width: ${XSBreakPoint}) {
    width: 112px;
    height: 112px;
    margin-bottom: 10px !important;
  }
`;

export const StyledGrid = styled(Grid)`
  margin-top: 35px !important;
`;
