import styled from 'styled-components';
import {
  colors, Fonts, MDBreakPoint, XSBreakPoint,
} from '../../Theme';

export const Link = styled.a<{ newLine?: boolean }>`
  cursor: pointer;
  color: ${colors.middleBlue};
  text-decoration: none;
  font: ${Fonts.fontTextLink};
  margin-top: ${(props) => (props.newLine ? '0' : '18px')};
  @media (max-width: 1080px) {
    padding: 0;
    font-size: 16px !important;
  }
  @media (max-width: ${XSBreakPoint}) {
    margin-bottom: 24px;
  }
  @media (max-width: ${MDBreakPoint}) {
    margin-top: 24px;
  }
`;
