// Import Dependencies
import React, {Dispatch, SetStateAction} from 'react';
// Import Components
import { Grid } from '@material-ui/core';
import { Stage } from '../partials/stage/Stage';
import { PageNav } from '../partials/PageNav';
import { TextChecker } from '../../utils/RichTextConverter';
import {
  Title,
  Wrapper,
  Container,
  Text,
  Subtitle,
} from '../../styles/components/StageComponent';


{/* TODO: change any types to regular types needed */}
interface StageProps {
  title: string;
  subtitle?: string;
  text: any;
  backgroundLg?: any;
  backgroundMd?: any;
  backgroundXs?: any;
  navi?: {
    defaultVal: string;
    elements: { item: { text: string }; index: any; id: string }[];
    inPage?: boolean;
  };
  classes?: any;
  setOpenDialog: Dispatch<SetStateAction<boolean>>;
}

export const StageComponent = ({
    navi,
    title,
    subtitle,
    text,
    backgroundLg,
    backgroundMd, backgroundXs, setOpenDialog,
}: StageProps) => (
  <Wrapper container justify="center" xs={12}>
    <Stage
      backgroundLg={backgroundLg}
      backgroundMd={backgroundMd}
      backgroundXs={backgroundXs}
      setOpenDialog={setOpenDialog}
    />
    <Grid container direction="column" alignItems="center" justify="center">
      <Title>{title}</Title>
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
    </Grid>
    <Container container justify="center">
      {navi && <PageNav {...navi} />}
    </Container>
    {text && text[0] && text[0].data?.text?.length > 0 && (
      <Container container justify="center">
        <Text item navi={navi}>
          {TextChecker(text)}
        </Text>
      </Container>
    )}
  </Wrapper>
);
