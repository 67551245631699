import { createGlobalStyle } from 'styled-components';

export const colors = {
  white: '#FFFFFF',
  lighterBlue: '#EBF1F7',
  lightBlue: '#E0F5F9',
  lightTurkis: '#96DFED',
  turkis: '#69D2E6',
  middleBlue: '#0082FF',
  middleDarkBlue: '#0D57A6',
  middleDarkerBlue: '#00488C',
  darkBlue: '#002864',

  offWhite: '#F8F8F8',
  lightGrey: '#0000001A',
  middleGrey: '#00000029',
  middleLightGrey: '#999999',
  middleDarkGrey: '#666666',
  grey: '#0000004D',
  darkGrey: '#707070',
  darkerGrey: '#333333',
  black: '#222222',
  darkBlack: '#000000',
  red: '#ff0055',
  lightPurple: '#8F59A566',
  // CardSize
  cardWidth: '300px',
  cardHeight: '250px',
  cardMarginRightBottom: '50px',
};

export const Fonts = {
  // Font-size
  fontSizeH1: '42px',
  fontSizeH2: '34px',
  fontSizeH3: '26px',
  fontSizeMainNav: '20px',
  fontSizeText: '18px',
  fontSizeTextSmall: '16px',
  fontSizeTextExtraSmall: '14px',

  // FontFamily
  raleway: 'Raleway',
  kalam: 'Kalam',
  // fontAwesome5Pro: 'Font Awesome 5 Pro',

  // Font-Weight
  fontWeightBold: 'bold',
  fontWeightNormal: 'normal',
  fontWeight600: '600',
  fontWeightMedium: '500',

  // Font-style
  fontStyleItalic: 'italic',
  fontStyleNormal: 'normal',

  // Font Shorthand
  fontH1: 'normal normal 600 45px/59px Raleway, SemiBold',
  fontH1MD: 'normal normal 600 32px/42px Raleway, SemiBold',
  fontH1XS: 'normal normal 600 29px/38px Raleway, SemiBold',

  fontH2: 'normal normal 600 37px/48px Raleway, SemiBold',
  fontH2MD: 'normal normal 600 29px/38px Raleway, SemiBold',
  fontH2XS: 'normal normal 600 26px/34px Raleway, SemiBold',

  fontH3: 'normal normal 600 31px/40px Raleway, SemiBold',
  fontH3MD: 'normal normal 600 26px/34px Raleway, SemiBold',
  fontH3XS: 'normal normal 600 23px/30px Raleway, SemiBold',

  fontH4: 'normal normal 600 26px/34px Raleway, SemiBold',
  fontH4MD: 'normal normal 600 23px/30px Raleway, SemiBold',
  fontH4XS: 'normal normal 600 20px/26px Raleway, SemiBold',

  fontH5: 'normal normal 600 22px/29px Raleway, SemiBold',
  fontH5MD: 'normal normal 600 20px/26px Raleway, SemiBold',
  fontH5XS: 'normal normal 600 18px/23px Raleway, SemiBold',

  fontH6: 'normal normal 600 18px/27px Raleway, SemiBold',
  fontH6MD: 'normal normal 600 18px/23px Raleway, SemiBold',
  fontH6XS: 'normal normal 600 16px/21px Raleway, SemiBold',

  fontIntroText: 'normal normal 600 18px/27px Raleway, SemiBold',
  fontText: 'normal normal 500 18px/27px Raleway, Medium',
  fontTextBold: 'normal normal bold 18px/27px Raleway, Bold',
  fontTextItalic: 'italic normal 500 18px/27px Raleway',
  fontTextSmall: 'normal normal 500 16px/22px Raleway',
  fontMainNavActive: 'normal normal bold 20px/34px Raleway, Bold',
  fontMainNavInactive: 'normal normal 600 20px/34px Raleway, SemiBold',
  fontTextLink: 'normal normal 600 18px/27px Raleway, SemiBold',
  fontButtonText: 'normal normal 600 18px/27px Raleway, SemiBold',
  fontTextLinkSmallNav: 'normal normal 600 16px/27px Raleway, SemiBold',
  fontKalamRegular: 'normal normal normal 14px/27px Kalam',
  fontKalamBold: 'normal normal bold 26px/34px Kalam',
  fontKalamBoldSmall: 'normal normal bold 18px/27px Kalam',
  fontArrowLink: 'normal normal normal 16px/27px Raleway, SemiBold',

  fontFooterLinks: 'normal normal 600 18px/36px Raleway, SemiBold',
  fontFooterLinksMD: 'normal normal 600 18px/36px Raleway, SemiBold',
  fontFooterLinksXS: 'normal normal 600 16px/32px Raleway, SemiBold',

  // Font Mobile
  fontH1Mobile: 'normal normal 600 32px/41px Raleway, SemiBold',
  fontIntroTextMobile: 'normal normal 600 16px/24px Raleway, SemiBold',
  fontTextLinkSmallNavMobile: 'normal normal 600 16px/32px Raleway, SemiBold',

  // Mobile Fonts
  fontFooterLinksMobile: 'normal normal 600 16px/32px Raleway, SemiBold',
  fontTextMobile: 'normal normal 500 16px/24px Raleway, Medium',

  // LineSpacing
  lineSpacingH1: '55px',
  lineSpacingH2: '44px',
  lineSpacingFooter: '36px',
  lineSpacingH3MainNav: '34px',
  lineSpacingText: '27px',
  lineSpacingTextRedSmall: '22px',

  // Width general
  ContentWidth: '1300px',
  innerContentWidth: '1000px',

  // Padding general
  paddingVertical50Content: '50px',
  paddingVertical80Content: '80px',
  padding30CardProcess: '30px',
  paddingButton: '20px 50px',
  paddingCard: '46px 20px',

  // Margin general
  marginContentToSliderArrow: '100px',
  marginRightArrowToLink: '5px',
  marginRightCheckToItem: '10.23px',
  marginBottomToText: '30px',
  marginBottomToCards: '50px',

  // CircleSize
  circleSizeNormal: '150px',
  circleSizeSmall: '60px',
};

export const XXSBreakPoint = '400px';
export const XLSBreakPoint = '500px';
export const XSBreakPoint = '960px';
export const MDBreakPoint = '1280px';
export const LGBreakPoint = '1550px';
export const XSWrapperWidth = '100%';
export const MDWrapperWidth = '1000px';
export const InnerWrapperWidth = '1000px';
export const OuterWrapperWidth = '1700px';

export const Theme = createGlobalStyle`
   html {font-size: 112.5% !important;
   @media(max-width: ${XSBreakPoint}){
      font-size: 100% !important;
   }}

    body {
      font-weight: 400 !important;
      line-height: 1.65 !important;
      @media(max-width: 1279px){
        line-height: 1.75 !important;
      }
      @media(max-width: 959px){
        line-height: 1.75 !important;
        font-size: 100% !important;
      }
    }
    
    p {
      margin-bottom: 1rem !important;
      @media(max-width: ${MDBreakPoint}){
        font-size: 18px;
      }
      @media(max-width: ${XSBreakPoint}){
        font-size: 100% !important;
        line-height: 24px ;
      }
    }
    
    h1, h2, h3, h4, h5 {
      margin: 0.8rem 0 !important;
      font-weight: 600 !important;
      line-height: 1.3 !important;
    }
    
    h1 {
      margin-top: 0 !important;;
      font-size: 2.488rem !important;;
        @media(max-width: 1279px){
        font-size: 1.802rem !important;;
      }
    }
    
    h2 {
    font-size: 2.074rem !important;;
      @media(max-width: 1279px){
      font-size: 1.602rem !important;;
      }
    }
    
    h3 {
    font-size: 1.728rem !important;;
      @media(max-width: 1279px){
        font-size: 1.424rem !important;;
      }
      @media(max-width: 959px){
        line-height: 1 !important;
      }
    }
    
    h4 {
    font-size: 1.44rem !important;;
      @media(max-width: 1279px){
       font-size: 1.266rem !important;;
      }
      @media(max-width: 959px){
        line-height: 1.75 !important;
      }
    }
    
    h5 {
    font-size: 1.2rem !important;;
      @media(max-width: 1279px){
        font-size: 1.125rem !important;;
      }
      @media(max-width: 959px){
        line-height: 1.75 !important;
      }
    }
    
    small, span, div, .text_small {
      @media(max-width: 1279px){
       font-size: 18px;
      }
      @media(max-width: ${XSBreakPoint}){
        font-size: 16px;
        line-height: 24px ;
        max-width:100%;
        width:100%;
      }
    }
`;
