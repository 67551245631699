import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { BackgroundConverter } from '../../utils/BackgroundConverter';
import {
  colors,
  Fonts,
  MDBreakPoint,
  XSBreakPoint,
  XSWrapperWidth,
} from '../../Theme';

interface WrapperProps {
  background: { type: string; url: string };
  isBlog?: boolean;
}

export const OuterWrapper = styled(Grid)<WrapperProps>`
  padding: ${({ isBlog }) => (isBlog ? '0 16px' : '50px 16px')};
  ${({ background }) => BackgroundConverter(background.type, background.url)}
`;

export const InnerWrapper = styled.div`
  max-width: 1280px;
  @media (max-width: ${XSBreakPoint}) {
    max-width: ${XSWrapperWidth};
  }
`;

export const Cite = styled(Grid)`
  color: ${colors.darkBlue};
  font: ${Fonts.fontH4} !important;
  max-width: 600px;
  text-align: center;
  @media (max-width: ${MDBreakPoint}) {
    font: ${Fonts.fontH4MD} !important;
  }
  @media (max-width: ${XSBreakPoint}) {
    font: ${Fonts.fontH4XS} !important;
  }
`;

export const Name = styled.div`
  font: italic 500 18px Raleway, Medium;
  color: #002864;
  text-align: center;
  margin: 20px 0 5px 0;
`;
export const Smiley = styled.div`
  text-align: center;
`;

export const Img = styled.img`
  height: 112px;
  width: 112px;
`;
