// Import Dependencies
import React from 'react';
import {
  Background,
  ImageWrapper,
  Image,
  HoverDiv,
  Text,
} from '../../styles/partials/ClickableText';
// Import Component

// Import Variable

interface ClickableTextTypes {
  index: any;
  value: any;
  datatype?: string;
  item: {
    icon?: any;
    to?: string;
    text: string;
  };
  background: {
    src?: string;
    alt?: string;
    location?: string;
  };
  clickHandler: Function;
  navItems?: number;
  inPage?: boolean;
  isMainPage?: boolean;
}

const RenderImage = ({ object, background, datatype }: any) => (
  <ImageWrapper>
    <Image src={object?.icon} alt="image" datatype={datatype} />
    {background.location !== 'none' && (
      <Background
        src={background.src}
        alt={background.alt}
        location={background.location}
      />
    )}
  </ImageWrapper>
);

const ClickableText = ({
  item,
  value,
  clickHandler,
  index,
  background,
  navItems,
  inPage,
  isMainPage,
  datatype,
}: ClickableTextTypes) => (
  <HoverDiv onClick={() => clickHandler(index)}>
    {item?.icon && (
      <RenderImage object={item} background={background} datatype={datatype} />
    )}
    <Text
      clicked={index === value}
      isLink={item?.to}
      navItems={navItems}
      inPage={inPage}
      isMainPage={isMainPage}
      datatype={datatype}
    >
      {item && item?.text && item?.text.split('  ').length > 1 ? (
        <>
          {item?.text.split('  ')[0]}
          <br />
          {item?.text.split('  ')[1]}
        </>
      ) : (
        <>{item?.text}</>
      )}
    </Text>
  </HoverDiv>
);

export default ClickableText;
