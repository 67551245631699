import React, { useEffect } from 'react';

const isProd = process.env.REACT_APP_BUILD_ENV?.toLowerCase() === 'prod';
const scriptSource = isProd ? 'https://www.vkb.de/service2/servicepool/mkt/live/vkb_bc/pflegebedarfs_check/js/bundle.js' : 'https://mkt.nakami.de/vkb_bc/pflegebedarfs_check/js/bundle.js';
const dataApp = isProd ? '{"app":"pflegebedarfs_check","theme":"vkb_bc","api":"https://www.vkb.de/service2/servicepool/api/mkt/api/","directApi":"https://www.vkb.de/service2/servicepool/api/mkt/api/"}' : '{"app":"pflegebedarfs_check","theme":"vkb_bc","api":"https://mkt.nakami.de/api/","directApi":"https://mkt.nakami.de/api/"}';
const dataConfig = isProd ? '{"base_asset_url":"https://www.vkb.de/service2/servicepool/mkt/live/vkb_bc/pflegebedarfs_check/"}' : '{"base_asset_url":"https://mkt.nakami.de/vkb_bc/pflegebedarfs_check/", "oev_dev": true }';

// TODO: Metadata?
export function BedarfPage() {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = scriptSource;
    script.async = true;
    document.body.appendChild(script);
  }, []);

  return (
    <>
      <div
        data-app={dataApp}
        data-config={dataConfig}
      />
    </>
  );
}
