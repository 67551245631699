import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { BackgroundConverter } from '../../utils/BackgroundConverter';
import {
  colors,
  Fonts,
  InnerWrapperWidth,
  MDBreakPoint,
  MDWrapperWidth,
  XSBreakPoint,
} from '../../Theme';
import { H2, H3 } from '../Styles';

interface WrapperProps {
  background: { type: string; url: string };
}

export const useStyles = makeStyles({
  root: {
    '& p': {
      height: 'auto',
      width: 'auto',
    },
  },
});
export const Wrapper = styled(Grid)<WrapperProps>`
  padding: 50px 0;
  ${({ background }) => BackgroundConverter(background.type, background.url)}}
  @media (max-width: ${XSBreakPoint}) {
    padding: 48px 16px;
  }
`;

export const InnerWrapper = styled(Grid)`
  max-width: ${InnerWrapperWidth};
  margin: 0 auto;
  font: ${Fonts.fontText};
  @media (max-width: ${MDBreakPoint}) {
    max-width: 1000px;
    width: 100%;
  }
`;
export const Subtitle = styled(H3)`
  text-align: center;
  color: ${colors.darkBlue};
  margin-top: 60px !important;
`;

export const Title = styled(H2)`
  text-align: center;
  color: ${colors.darkBlue};
  max-width: 700px;
  margin: 0 auto !important;
`;

export const Text = styled.div`
  margin: 10px 0 50px 0;
  padding: 0 0 70px;
  max-width: ${InnerWrapperWidth};
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  color: ${colors.black};
  font: ${Fonts.fontText};
  @media (max-width: ${MDBreakPoint}) {
    margin: 10px 0 0;
    padding: 0 16px;
  }
`;

export const CareItem = styled(Grid)``;
export const DesktopView = styled.div`
  @media (max-width: ${MDBreakPoint}) {
    display: none;
  }
`;

export const MobileContainer = styled.span`
  max-width: ${InnerWrapperWidth};
  margin: 0 auto;
  display: none;
  font: ${Fonts.fontText};
  @media (max-width: ${XSBreakPoint}) {
    width: 360px;
    display: block;
  }
`;

export const MDContainer = styled.span`
  display: none;
  font: ${Fonts.fontText};
  @media (max-width: ${MDBreakPoint}) {
    width: ${MDWrapperWidth};
    display: block;
  }
  @media (max-width: ${XSBreakPoint}) {
    display: none;
  }
`;

export const StyledInnerText = styled.p`
  width: 1000px;
  height: 60px;
  margin-top: 0;
  font: ${Fonts.fontText};
`;

export const StyledGrid = styled(Grid)`
  width: 1700px !important;

  @media (max-width: 1600px) {
    width: 1500px !important;
  }
  @media (max-width: 1500px) {
    width: 1400px !important;
  }
  @media (max-width: 1400px) {
    width: 1300px !important;
  }
`;

export const CenteredDiv = styled.div`
  padding-bottom: 50px;
`;
