import { ButtonMapper } from "./partials/ButtonMapper";
import { BackgroundMapper } from "./partials/BackgroundMapper";
import { IntroOutro } from "./partials/IntroOutro";
import { Timestamps } from "./partials/TimestampMapper";

export const StepsMapper = (obj: any) => ({
  type: "steps",
  ...Timestamps(obj),
  ...BackgroundMapper(obj),
  ...IntroOutro(obj),
  buttons: ButtonMapper(obj),
  splitted: true,
  text: obj.text,
  subsubtitle: obj.subsubtitle,
  elements: obj.points,
  icon: { src: obj.icon, alt: obj.icon ? "gard" : "", display: !!obj.icon },
});
