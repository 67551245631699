import React, { useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Controller } from 'swiper';
import styled from 'styled-components';
import SwipeSlider from './SwipeSlide';
import { Separator } from '../../styles/partials/SwiperWrapper';
import { colors } from '../../Theme';

function TextHeight(elements: object) {
  const RemScaling = 1.125;
  const HeightScaling = Math.max(
    ...Object.values(elements).map(
      (entry: any) => entry.text && entry.text.length,
    ),
  ) * RemScaling;
  return HeightScaling >= 30 ? HeightScaling : 30;
}

SwiperCore.use([Controller]);

interface SwiperProps {
  elements: object[];
  elementsInSlide?: number;
  isIcon?: boolean;
  isPflege?: boolean;
  isTabs?: boolean;
  isImage?: boolean;
  textHeight?: number;
}

export const SwiperPagination = styled(Swiper)`
  .swiper-pagination-bullet {
    background-color: ${colors.turkis};
  }
  .swiper-pagination-bullet-active {
    background-color: ${colors.darkBlue};
  }
`;
export const StyledSwiper = styled(Swiper)<{ height: number }>`
  .swiper-button-prev {
    top: 90px;
    left: 0;
  }
  .swiper-button-next {
    top: 90px;
    right: 0;
  }
  .swiper-pagination {
    bottom: 0;
  }
  font-family: Raleway, Sarif !important;
`;

const SwiperWrapper = ({
  elements,
  elementsInSlide,
  isIcon,
  isPflege,
  isTabs,
  isImage,
  textHeight,
}: SwiperProps) => {
  const [mainSwiper, setMainSwiper] = useState();
  const [paginationSwiper, setPaginationSwiper] = useState();
  const defs = {
    isIcon,
    isPflege,
    isTabs,
    isImage,
    textHeight,
  };
  const height = elements ? TextHeight(elements) : 0;
  let j = 0;
  const mappedElements: any = elementsInSlide
    ? elements.map((element: object, index: number) => {
      if (index % elementsInSlide === 0) {
        j += 1;
      }
      return mappedElements[j].push(element);
    })
    : elements;
  return (
    <>
      <StyledSwiper
        height={height}
        breakpoints={{ 960: { slidesPerView: 3 } }}
        slidesPerView={1}
        controller={{ control: paginationSwiper }}
        onSwiper={(swiper: any) => setMainSwiper(swiper)}
      >
        {elements.map((element: object, index: number) => (
          <SwiperSlide key={index}>
            <SwipeSlider
              element={element}
              first={index === 0}
              last={index === elements.length - 1}
              {...defs}
              textHeight={height}
            />
          </SwiperSlide>
        ))}
      </StyledSwiper>
      <SwiperPagination
        pagination={{ clickable: true }}
        onSwiper={(swiper: any) => setPaginationSwiper(swiper)}
        controller={{ control: mainSwiper }}
      >
        {elements.map((x: any, index: number) => (
          <SwiperSlide key={index}>
            <Separator />
          </SwiperSlide>
        ))}
      </SwiperPagination>
    </>
  );
};

export default SwiperWrapper;
