import styled from 'styled-components';
import ContentLoader from 'react-content-loader';
import { colors, MDBreakPoint } from '../../Theme';

export const ContentLoaderStyled = styled(ContentLoader)`
  width: 1100px;
`;

export const Wrapper = styled.div`
  text-align: center;
  background: ${colors.lightBlue};
  @media (max-width: ${MDBreakPoint}) {
    display: none;
  }
`;
