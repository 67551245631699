import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { BackgroundConverter } from '../../utils/BackgroundConverter';
import {
  colors, Fonts, InnerWrapperWidth, XSBreakPoint,
} from '../../Theme';
import { H2, H3 } from '../Styles';

interface WrapperProps {
  background: { type: string; url: string };
  isBlog?: boolean;
}
export const OuterWrapper = styled(Grid)<WrapperProps>`
  padding: ${({ isBlog }) => (isBlog ? '0 16px' : '50px 16px')};  
  ${({ background }) => BackgroundConverter(background.type, background.url)}}
`;

export const InnerWrapper = styled.div`
  max-width: 1007px;
  @media (max-width: ${XSBreakPoint}) {
    width: 100%;
  }
`;

export const Subtitle = styled(H3)`
  text-align: center;
  color: ${colors.darkBlue};
  margin-top: 60px !important;
`;

export const Title = styled(H2)`
  text-align: center;
  color: ${colors.darkBlue};
  padding-bottom: 20px;
`;

export const Container = styled(Grid)`
  padding: 20px 0;
`;

export const DesktopView = styled.span`
  width: ${InnerWrapperWidth};
  @media (max-width: ${XSBreakPoint}) {
    display: none;
  }
`;
export const MobileView = styled.span`
  display: none;
  @media (max-width: ${XSBreakPoint}) {
    display: block;
    width: 100%;
    padding: 0 16px;
  }
`;
export const Text = styled.div<{ alignLeft?: boolean }>`
  text-align: ${(props) => (props.alignLeft ? 'left' : 'center')};
  color: ${colors.black};
  font: ${Fonts.fontText};
  @media (max-width: ${XSBreakPoint}) {
    padding-left: 10px;
    padding-right: 10px;
  }
`;
