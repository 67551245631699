import styled from 'styled-components';
import { ReactSVG } from 'react-svg';
import Box from '@material-ui/core/Box';
import { colors, Fonts, MDBreakPoint } from '../../Theme';
import { BlueLink } from '../../components/cta/BlueLink';
import { H1, H3 } from '../Styles';

// @ts-ignore
export const StyledSVG = styled(ReactSVG)`
  fill: ${colors.darkBlue} !important;
  height: ${(props) => `${props.height}px`} !important;
  width: ${(props) => `${props.width}px`} !important;
  @media (max-width: 1280px) {
    height: ${(props: any) => `${props.height * 0.7}px`} !important;
    scale: 0.8;
  }
  @media (max-width: 980px) {
    height: ${(props: any) => `${props.height * 0.5}px`} !important;
    scale: 0.7;
  }
`;

export const Wrapper = styled.div`
  height: 100%;
  width: 300px;
  margin: 0 auto;
  text-align: center;
`;
export const Bold = styled.div`
  font-weight: 800;
  color: ${colors.darkBlue};
  margin: 10px 0 0 0;
`;
export const Separator = styled.div`
  border-bottom: 2px solid ${colors.darkBlue};
  padding: 10px 0;
  max-width: 150px;
  margin-right: auto;
  margin-left: auto;
`;
export const Slider = styled.div<{
  first: boolean;
  last: boolean;
  icon?: boolean;
  isPflege?: boolean;
}>`
  border-top: 1px solid ${colors.turkis};
  padding: 0 0 10px 0;
  width: ${(props) => {
    if (props.icon || props.isPflege) {
      if (props.first) return '600px';
      if (props.last) return '200px';
      return '900px';
    }
    if (props.first) return '370px';
    if (props.last) return '200px';
    return '600px';
  }};
  margin: ${(props) => {
    if (props.first) return '0 0 0 200px';
    if (props.last) return '0 200px 0 0 ';
    return '0 auto';
  }};
`;

export const MonthlyCosts = styled.div`
  color: ${colors.darkBlue};
`;
export const TitleTabs = styled.div<{ height: any }>`
  color: ${colors.darkBlue};
  font-weight: bold;
  height: ${(props) => props.height * 0.8}px;
  margin-bottom: 10px;
  @media (max-width: ${MDBreakPoint}) {
    max-width: 250px;
    margin: 16px 0;
  }
`;
export const DifferenceCosts = styled(H1)`
  color: ${colors.darkBlue};
`;
export const DifferenceText = styled.div`
  color: ${colors.darkBlue};
`;
export const Img = styled.img``;

// @ts-ignore
export const StyleText = styled(Box)<{ align: string }>`
  color: ${colors.darkBlue};
  text-align: ${(props) => props.align};
  width: 70%;
`;

// @ts-ignore
export const PflegeText = styled(Box)<{ align: string }>`
  color: ${colors.darkBlue};
  text-align: ${(props) => props.align};
  width: 100%;
`;
export const PflegeTitle = styled(H3)`
  text-align: center;
  color: ${colors.darkBlue};
  padding-bottom: 20px !important;
  max-width: 100%;
  max-height: 10px;
`;

export const Text = styled.div<{ align: string }>`
  width: 350px;
  height: 100%;
  text-align: left;
  text-align: ${(props) => props.align};
  display: inline-block;
  color: ${colors.black};
  font: ${Fonts.fontTextSmall};
  overflow: visible;
  @media (max-width: ${MDBreakPoint}) {
    max-width: 280px;
  }
`;

export const TextTabs = styled.div<{ height: any }>`
  width: 250px;
  height: ${(props) => props.height * 1.7}px;
  text-align: center;
  display: inline-block;
  color: ${colors.black};
  font: ${Fonts.fontText};
`;

export const ContentWrapper = styled.div`
  height: 100%;
  text-align: center;
  width: 262px;
  margin: 0 auto;
`;

export const TabWrapper = styled.div`
  text-align: center;
  height: 100%;
  width: 360px;
`;

export const Grad = styled.div``;
export const Line = styled.div<{ width: number }>`
  margin: 0 auto;
  border-bottom: 5px solid ${colors.turkis};
  width: ${(props) => props.width}%;
  position: relative;
  bottom: -3px;
`;
export const StyleLink = styled(BlueLink)`
  padding: 20px 0 0 20px;
  @media (max-width: ${MDBreakPoint}) {
    padding: 0;
  }
`;
