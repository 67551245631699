import { BackgroundMapper } from "./partials/BackgroundMapper";
import { IntroOutro } from "./partials/IntroOutro";
import { ButtonMapper } from "./partials/ButtonMapper";
import { Timestamps } from "./partials/TimestampMapper";

export const ZitatMapper = (obj: any) => ({
  type: "zitat",
  ...Timestamps(obj),
  ...BackgroundMapper(obj),
  ...IntroOutro(obj),
  buttons: ButtonMapper(obj),
  img: obj.img,
  name: obj.name,
  text: obj.text,
});
