import React from 'react';
import { Grid } from '@material-ui/core';
import styled from 'styled-components';
import { colors, Fonts } from '../../Theme';

interface Props {
  text?: string;
  icon?: any;
  color?: 'white' | 'blue';
}

const Icon = styled.img`
  filter: invert(7%) sepia(63%) saturate(5887%) hue-rotate(199deg)
    brightness(120%) contrast(113%);
  fill: ${colors.darkBlue} !important;
`;
const Text = styled(Grid)`
  max-width: 1000px !important;
  padding: 50px 0 0 0;
  font: ${Fonts.fontH3};
  color: ${colors.darkBlue};
  text-align: center;
`;

const Wrapper = styled(Grid)<{ color: 'blue' | 'white' }>`
  padding: 50px 0;
  background: ${({ color }) => (color === 'white' ? 'white' : '#e0f5f9')};
`;

const NothingToShow = ({ text, icon, color = 'white' }: Props) => (
  <Wrapper
    container
    xs={12}
    direction="column"
    justify="center"
    alignItems="center"
    color={color}
  >
    <Icon src={icon} color="#ffffff" />
    <Text item>{text}</Text>
  </Wrapper>
);

export default NothingToShow;
