import styled, { css } from 'styled-components';
import { Grid } from '@material-ui/core';
import {
  colors, Fonts, MDBreakPoint, XSBreakPoint,
} from '../../Theme';
import { BlueLink } from '../../components/cta/BlueLink';
import SmallIconText from '../../components/partials/SmallIconText';
import { H3 } from '../Styles';

interface WProps {
  first: boolean;
  second: boolean;
  third: boolean;
  fourth: boolean;
  withIcon?: string;
  length: number;
}

export const Wrapper = styled.div<WProps>`
  -webkit-box-shadow: 13px 1px 15px 0 #0000003e,
    inset 0 0 50px #f8f8f8, inset 0 0 50px #f8f8f8,
    inset 0 0 50px #f8f8f8;
  -moz-box-shadow: 13px 1px 15px 0 #0000003e,
    inset 0 0 50px #f8f8f8, inset 0 0 50px #f8f8f8,
    inset 0 0 50px #f8f8f8;
  border-radius: 10px;
  box-shadow: 13px 1px 15px 0 #0000003e,
    inset 0 0 50px #f8f8f8, inset 0 0 50px #f8f8f8,
    inset 0 0 50px #f8f8f8;
  background: #f8f8f8;
  height: ${({ withIcon }) => (withIcon ? '660px' : '590px')};
  width: ${({ length }) => (length === 3 ? '470px' : '390px')};
    
  ${({ first, length }) => first
    && css`
      position: relative;
      left: ${length === 3 ? '-50px' : '-110px'};
      z-index: 3;
    `};
  
    ${({ second, length }) => second
      && css`
        position: relative;
        left: ${length === 3 ? '-30px' : '-55px'};
        z-index: 2;
      `}
  
  ${({ third, length }) => third
    && css`
      position: relative;
      left: ${length === 3 ? '0px' : '0px'};
      z-index: 1;
    `}
    
  ${({ fourth }) => fourth
    && css`
      position: relative;
      left: 50px;
      z-index: 0;
    `};
      
  @media(max-width: ${MDBreakPoint}){ 
    width: 345px;
    height:${({ withIcon, length }) => {
    if (length === 4) {
      return withIcon ? '700px' : '500px';
    }
    return withIcon ? '800px' : '660px';
  }};
    padding: 0;
    position: relative;
    ${({ first, length }) => first
      && css`
        left: ${length === 3 ? '0' : '220px'};
          z-index: 3;
      `};    
    ${({ second, length }) => second
      && css`
        left: ${length === 3 ? '0' : '300px'};
          z-index: 2;
      `}
    ${({ third, length }) => third
      && css`
        left: ${length === 3 ? '0' : '385px'};
          z-index: 1;
      `}
    ${({ fourth }) => fourth
      && css`
        left: 480px;
          z-index: 0;
      `}
      }
}
  @media(max-width: 1080px){
    -webkit-box-shadow: 13px 1px 35px 0 #0000006e,
    inset 0 0 50px #f8f8f8, inset 0 0 50px #f8f8f8,
    inset 0 0 50px #f8f8f8;
    -moz-box-shadow: 13px 1px 35px 0 #0000006e,
    inset 0 0 50px #f8f8f8, inset 0 0 50px #f8f8f8,
    inset 0 0 50px #f8f8f8;
    border-radius: 10px;
    box-shadow: 13px 1px 35px 0 #0000006e,
    inset 0 0 50px #f8f8f8, inset 0 0 50px #f8f8f8,
    inset 0 0 50px #f8f8f8;
    background: #f8f8f8;
    width: 360px;
    height: ${({ withIcon }) => (withIcon ? '690px' : '580px')};
    padding: 0;
    ${({ first }) => first
      && css`
        left: 0px;
          z-index: 3;
      `};    
  ${({ second }) => second
    && css`
      left: 0px;
        z-index: 2;
    `}
    
  ${({ third }) => third
    && css`
      left: 0px;
        z-index: 1;
    `}
    
    ${({ fourth }) => fourth
      && css`
        left: 0px;
      `}
    }

  @media (max-width: ${XSBreakPoint}) {
    height: 620px;
  }
`;

interface TitleProps {
  stepColor: number;
}

export const Title = styled.div<TitleProps>`
  ${({ stepColor }) => {
    switch (stepColor) {
      case 0:
        return css`
          background: ${colors.darkBlue};
        `;
      case 1:
        return css`
          background: ${colors.middleBlue};
        `;
      case 2:
        return css`
          background: ${colors.turkis};
        `;
      default:
        return css`
          background: ${colors.turkis};
        `;
    }
  }};
  border-top-right-radius: 40px;
  border-bottom-right-radius: 40px;
  color: ${colors.white};
  font: ${Fonts.fontH3} !important;
  margin: 0 0 10px;
  padding: 10px 0;
  text-align: center;
  width: 90%;
  @media (max-width: ${MDBreakPoint}) {
    font: ${Fonts.fontH3};
    padding: 2px 0;
    margin: 0 0;
  }
  @media (max-width: ${XSBreakPoint}) {
  }
`;
export const Subtitle = styled(H3)`
  color: ${colors.darkBlue};
  padding: 0 0 0 18px;
  text-align: left !important;
`;

export const Text = styled.div<{ isLink?: string; centered?: boolean }>`
  color: ${colors.black};
  text-align: ${(props) => (props.centered ? 'center' : 'left')};
  margin-bottom: 18px;
  margin-top: 30px;
  max-width: 90%;
  font: ${Fonts.fontText};
  padding: 0 0 0 20px;

  @media (max-width: 1080px) {
    font: ${Fonts.fontTextSmall};
  }

  @media (max-width: ${MDBreakPoint}) {
    margin-top: 0px;
  }
  @media (max-width: ${XSBreakPoint}) {
    margin: 0;
  }
  
`;
export const ImageContainer = styled(Grid)`
  padding: 25px 0 0;
  @media (max-width: ${MDBreakPoint}) {
    padding: 10px 0;
    text-align: center;
  }
`;

export const Link = styled.div`
  padding: 25px 0 0 15px;
  @media (max-width: ${MDBreakPoint}) {
    padding: 0;
    margin: 0;
  }
  @media (max-width: ${XSBreakPoint}) {
    width: 270px;
    padding: 0 0 0 15px;
  }
`;

export const ContentContainer = styled.div`
  padding: 0 15px;
  height: 70%;
  display: flex;
  flex-direction: column;
  @media (max-width: 1080px) {
    padding: 0 10px;
    max-width: 310px;
  }
  @media (max-width: ${MDBreakPoint}) {
    height:74%;
  }
  @media (max-width: ${XSBreakPoint}) {
    padding: 0;
    height:61%;
  }
`;

export const StyleLink = styled(BlueLink)`
  padding: 20px 0 0 20px;
  @media (max-width: ${MDBreakPoint}) {
    padding: 0;
  }
  @media (max-width: 1080px) {
    font-size: 16px;
  }
`;

export const Content = styled.div<{ isLink?: string; isIcon?: string }>`
  height: 75%;

  @media (min-width: ${XSBreakPoint}) and (max-width: ${MDBreakPoint}) {
    padding: 0;
    height: 80%;
  }
  @media (max-width: 1080px) {
    height: 74%;
    .paragraph {
      padding-bottom: 0;
    }
  }
  @media (max-width: ${XSBreakPoint}) {
    height: 83%;
  }
`;

export const StyledSmallIconText = styled(SmallIconText)`
  max-height: 85px !important;
  margin: 0 0 20px;
  @media (max-width: ${XSBreakPoint}) {
    line-height: 20px;
  }  
`;
