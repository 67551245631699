import styled, { css } from 'styled-components';
import { colors, Fonts } from '../../Theme';

export const HoverDiv = styled.div`
  :hover {
    cursor: pointer;
  }
`;

export const ImageWrapper = styled.div`
  margin: auto;
  height: 180px;
  max-width: 190px;
`;

export const Image = styled.img`
  z-index: 1;
  position: relative;
  max-width: 100%;
  max-height: 100%;
`;

interface BackgroundProps {
  src?: string;
  alt?: string;
  location?: string;
}
export const Background = styled.img<BackgroundProps>`
  z-index: 0;
  bottom: 155px;
  height: 140px;
  max-width: 160px;
  position: relative;
  ${({ location }) => (location !== 'left'
    ? css`
          left: 70px;
        `
    : css`
          right: 120px;
        `)}
`;

export const Text = styled.p<{
  datatype?: string;
  clicked: boolean;
  isLink: any;
  navItems?: number;
  inPage?: boolean;
  isMainPage?: boolean;
}>`
  max-width: 365px;
  width: fit-content;
  max-height: fit-content;
  text-align: center;

  ${(props) => props.isMainPage && 'font-size:18px !important'};

  ${(props) => props.isMainPage
    && !props.clicked
    && css`
      color: ${colors.darkBlue};
      font: ${Fonts.fontMainNavInactive};
    `};
  ${(props) => props.isMainPage
    && props.clicked
    && css`
      color: ${colors.darkBlue};
      font: ${Fonts.fontMainNavInactive};
        text-shadow: 0 0 1px ${colors.darkBlue};
    `};
  ${(props) => !props.isMainPage
    && css`
      font: ${Fonts.fontTextLink};
      color: ${colors.middleBlue};
    `};
  ${(props) => !props.isMainPage
    && props.clicked
    && css`
      font: ${Fonts.fontTextLink};
      color: ${colors.darkBlue};
    `};

  ${(props) => props.inPage
    && css`
      margin-bottom: -3px !important;
      padding-bottom: 12px !important;
    `};
  margin-bottom: 0;
  border-bottom: 5px solid
    ${(props) => (props.clicked ? '#69d2e6' : 'transparent')};
  padding-bottom: 8px;
  @media (max-width: 1280px) {
    font-size: ${(props) => props.isMainPage && '16px !important'};
    padding-bottom: 5px;
  }
`;
