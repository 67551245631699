import { makeVar, ReactiveVar } from '@apollo/client';
import { InPageNavMapper } from '../../utils/mapper/InPageNavigation';
import { TabsMapper } from '../../utils/mapper/TabMapper';
import { ProcessMapper } from '../../utils/mapper/ProcessMapper';
import { TextMapper } from '../../utils/mapper/TextMapper';
import { CareMapper } from '../../utils/mapper/CareMapper';
import { StepsMapper } from '../../utils/mapper/StepsMapper';
import { SliderMapper } from '../../utils/mapper/SliderMapper';
import { FinancialMapper } from '../../utils/mapper/FinancialMapper';
import { CardMapper } from '../../utils/mapper/CardMapper';

export const FINANZIEREN = {
  listPfInPageNavigations: {
    read(value: any) {
      if (value !== undefined) {
        return Finanzieren({
          ...Finanzieren(),
          nav: value.data.map((data: any) => InPageNavMapper(data)),
        });
      }
    },
  },
  listPfTabKomponentes: {
    read(value: any) {
      if (value !== undefined) {
        return Finanzieren({
          ...Finanzieren(),
          tabs: value.data.map((data: any) => TabsMapper(data)),
        });
      }
    },
  },
  listPfTextKomponente1s: {
    read(value: any) {
      if (value !== undefined) {
        return Finanzieren({
          ...Finanzieren(),
          textNew: value.data.map((data: any) => TextMapper(data)),
        });
      }
    },
  },
  listPfProzessKomponentes: {
    read(value: any) {
      if (value !== undefined) {
        return Finanzieren({
          ...Finanzieren(),
          process: value.data.map((data: any) => ProcessMapper(data)),
        });
      }
    },
  },
  listPfTextKomponentes: {
    read(value: any) {
      if (value !== undefined) {
        return Finanzieren({
          ...Finanzieren(),
          text: value.data.map((data: any) => TextMapper(data)),
        });
      }
    },
  },
  listPfPflegeKomponentes: {
    read(value: any) {
      if (value !== undefined) {
        return Finanzieren({
          ...Finanzieren(),
          care: value.data.map((data: any) => CareMapper(data)),
        });
      }
    },
  },
  listGutachterbesuchKomponents: {
    read(value: any) {
      if (value !== undefined) {
        return Finanzieren({
          ...Finanzieren(),
          steps: value.data.map((data: any) => StepsMapper(data)),
        });
      }
    },
  },
  listSliderKomponents: {
    read(value: any) {
      if (value !== undefined) {
        return Finanzieren({
          ...Finanzieren(),
          slider: value.data.map((data: any) => SliderMapper(data)),
        });
      }
    },
  },
  listTextKomponentes: {
    read(value: any) {
      if (value !== undefined) {
        return Finanzieren({
          ...Finanzieren(),
          textTwo: value.data.map((data: any) => TextMapper(data)),
        });
      }
    },
  },
  listFinanzielBelastungs: {
    read(value: any) {
      if (value !== undefined) {
        return Finanzieren({
          ...Finanzieren(),
          finance: value.data.map((data: any) => FinancialMapper(data)),
        });
      }
    },
  },
  listKachelKomponentes: {
    read(value: any) {
      if (value !== undefined) {
        return Finanzieren({
          ...Finanzieren(),
          cards: value.data.map((data: any) => CardMapper(data)),
        });
      }
    },
  },
};
export const Finanzieren: ReactiveVar<any> = makeVar<any>({
  nav: {},
  tabs: {},
  process: {},
  text: {},
  care: {},
  steps: {},
  slider: {},
  textTwo: {},
  finance: {},
  cards: {},
  textNew: {},
  isLoading: true,
});
