// Import Dependencies
import React from 'react';
import Box from '@material-ui/core/Box';
/*
 * Import Components
 * Import Variables
 */
import { TextChecker } from '../../utils/RichTextConverter';
import {
  Bold,
  DifferenceCosts,
  DifferenceText,
  MonthlyCosts,
  Separator,
  StyleLink,
  StyleText,
  Wrapper,
  Text,
} from '../../styles/partials/TabContent';

interface TabContentProps {
  value: number;
  index: number;
  content?: any;
  closing?: any;
  example?: any;
  costs?: any;
  datatype?: any;
  url?: any;
  linkText?: any;
}

const TabContent = ({
  value,
  index,
  content,
  closing,
  example,
  costs,
  url,
  linkText,
  datatype,
}: TabContentProps) => {



  // @ts-ignore
  return (<Wrapper role="tabpanel" hidden={value !== index} id={`full-width-tabpanel-${index}`} aria-labelledby={`full-width-tab-${index}`} > {value === index && (<Box p={3}>
              {example && (
                  <>
                    <div>{example}</div>
                    <Separator />
                    <Bold>Beispiel</Bold>
                  </>
              )}
              {!costs && (
                  <>
                    <StyleText>{TextChecker(content)}</StyleText>
                    <br />
                    <Text>{TextChecker(closing)}</Text>
                    {url && <StyleLink url={url} text={linkText} datatype={datatype} />}
                  </>
              )}
              {costs && (
                  <>
                    <div>{content}</div>
                    <Separator />
                    <MonthlyCosts>{costs.monthlyCost}</MonthlyCosts>
                    <DifferenceCosts>{costs.differenceCost}</DifferenceCosts>
                    <DifferenceText>{costs.differenceText}</DifferenceText>
                  </>
              )}
            </Box>
        )}
      </Wrapper>
  );
}

export default TabContent;
