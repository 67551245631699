export const PAGE_METADATA = `
    url
    title
    description
    ogImage
    ogAlt
    ogTitle
    ogDescription
    ogSiteName 
    ogType
    ogLocal
    indexed
`;

export const ABOUT_PAGE_METADATA = `
    url
    title
    description
    ogImage
    ogAlt
    ogTitle
    ogDescription
    ogName 
    ogType
    ogLocal
    indexed
`;
