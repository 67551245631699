import styled from 'styled-components';
import ContentLoader from 'react-content-loader';
import { MDBreakPoint, XSBreakPoint } from '../../Theme';

export const ContentLoaderStyled = styled(ContentLoader)`
  height: 400px;
  padding: 80px 0;
  width: 1200px;
  @media (max-width: ${MDBreakPoint}) {
    width: 900px;
  }
  @media (max-width: ${MDBreakPoint}) {
    width: 600px;
  }
`;
export const RectXL = styled('rect')`
  display: block;
  @media (max-width: ${MDBreakPoint}) {
    display: none;
  }
`;
export const RectMD = styled('rect')`
  display: block;
  margin: 0 auto;
  @media (max-width: ${XSBreakPoint}) {
    display: none;
  }
`;
export const Wrapper = styled.div`
  width: 100%;
  text-align: center;
`;
