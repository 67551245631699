export default function RichTextExist(richText: [any]) {
  if (!(richText && richText[0] && (richText[0].data.text || richText[0].data.items))) {
    return false;
  }
  // eslint-disable-next-line no-restricted-syntax
  if (richText[0].data.items) {
    for (const text of richText[0].data.items) {
      if ((text && text.length > 0)) {
        return true;
      }
    }
  }

  if (richText[0].data.text) {
    return true;
  }
}
