// Import Dependencies
import React from 'react';
import { Grid } from '@material-ui/core';
import ReactCardFlip from 'react-card-flip';
import SVG from './SVG';
import TextChecker from '../../utils/RichTextConverter';
import {
  AboutUsImageCards,
  InnerWrapper,
  MediaWrapper,
  StyledCard,
  StyledCardContent,
  StyledCardText,
  useStyles,
  Text,
} from '../../styles/partials/Card';
// Import Components

// Import Variables

interface DataObject {
  data: {
    src: string;
    text: string;
    background?: object;
  };
  setFlip: any;
  flip: boolean;
  index: number;
  isAboutUs?: boolean;
  openHandler?: () => void;
}

const Cards = ({
  data, setFlip, flip, index, isAboutUs, openHandler,
}: DataObject) => {
  const classes = useStyles();
  const Svg = data.src;
  return (
    <ReactCardFlip
      isFlipped={flip}
      flipDirection={index % 2 === 1 ? 'horizontal' : 'vertical'}
    >
      <StyledCard
        onMouseEnter={() => setFlip(index)}
        classes={{ root: classes.root }}
      >
        <InnerWrapper>
          <Grid
            container
            direction="column"
            alignItems="center"
            justify="center"
          >
            {isAboutUs ? (
              <AboutUsImageCards backgroundImage={Svg} />
            ) : (
              <MediaWrapper text={data.text}>
                <SVG svg={data.src} />
              </MediaWrapper>
            )}

            {data.text && <Text>{data.text}</Text>}
          </Grid>
        </InnerWrapper>
      </StyledCard>
      <StyledCard onMouseLeave={() => setFlip(null)}>
        <InnerWrapper>
          <Grid
            container
            direction="column"
            alignItems="center"
            justify="center"
          >
            <StyledCardContent>
              <StyledCardText>{TextChecker(data.background, openHandler)}</StyledCardText>
            </StyledCardContent>
          </Grid>
        </InnerWrapper>
      </StyledCard>
    </ReactCardFlip>
  );
};

export default Cards;
