import styled from 'styled-components';
import { ReactSVG } from 'react-svg';
import { colors } from '../../Theme';


// @ts-ignore
export const StyledSVG = styled(ReactSVG)<{ width: number; height: number }>`
  fill: ${colors.darkBlue} !important;
  height: ${(props) => `${props.height}px`};
  width: ${(props) => `${props.width}px`};
  margin: 0 auto;
`;
