// Import Dependencies
import React from 'react';
import SVG from './SVG';
import { IconWrapper, StyledGrid } from '../../styles/partials/RoundIcons';
/*
 * Import Components
 * Import Variables
 */

interface IconProbs {
  width?: any;
  height?: any;
  icon: any;
}

const RoundIcons = ({ icon, width, height }: IconProbs) => (
  <IconWrapper>
    <StyledGrid
      container
      item
      direction="row"
      justify="center"
      alignItems="center"
    >
      <SVG svg={icon} width={width} height={height} />
    </StyledGrid>
  </IconWrapper>
);

export default RoundIcons;
