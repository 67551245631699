import { makeVar, ReactiveVar } from "@apollo/client";

export const BLOGS = {
  listBlogs: {
    read(value: any) {
      if (value !== undefined) {
        return Blogs({
          ...Blogs(),
          blogs: value.data,
        });
      }
    },
  },
};

export const Blogs: ReactiveVar<any> = makeVar<any>({
  blogs: {},
  isLoading: true,
});
