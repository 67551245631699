import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import {
  colors,
  Fonts,
  InnerWrapperWidth,
  MDBreakPoint,
  XSBreakPoint,
} from '../../Theme';
import { H1 } from '../Styles';

export const Wrapper = styled(Grid)`
  position: relative;
  padding: 0 0 40px;

  max-width: ${InnerWrapperWidth};
  background: white;
  @media (max-width: ${MDBreakPoint}) {
    width: 360px;
    padding: 0 0 32px;
  }
  @media (max-width: ${XSBreakPoint}) {
    padding: 0 0 24px;
  }
`;

export const Text = styled(Grid)<{ navi: any }>`
  margin: 40px 0;
  max-width: 1000px;
  color: ${colors.black};
  font: ${Fonts.fontIntroText};
  padding: ${(props) => (props.navi?.elements ? '0 0 50px 0' : '30px 0 50px')};
  @media (max-width: ${MDBreakPoint}) {
    font: ${Fonts.fontIntroTextMobile};
    line-height: 27px;
    margin: 24px 0;
    width: 100%;
    max-width: 1000px;
    padding: 0 16px;
  }
`;

export const Container = styled(Grid)`
  margin: 0;
`;

export const Title = styled(H1)`
  display: block;
  padding: 50px 0 0;
  color: ${colors.darkBlue} !important;
  margin-bottom: 0;
  max-width: 1000px;
  margin-top: 40px;
  @media (max-width: ${XSBreakPoint}) {
    padding: 0 16px;
  }
`;
export const Subtitle = styled.p`
  color: ${colors.darkBlue} !important;
  text-align: center;
  font: ${Fonts.fontH2} !important;
  @media (max-width: ${MDBreakPoint}) {
    font: ${Fonts.fontH2MD} !important;
  }
  @media (max-width: ${XSBreakPoint}) {
    font: ${Fonts.fontH2XS} !important;
    padding: 0 16px;
  }
  max-width: 1000px;
  margin: 0 auto !important;
  padding: 0;
`;
