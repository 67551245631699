export const InPageNavMapper = (obj: any) => {
  const TmpObj = {
    title: obj.title,
    text: obj.introduction,
    backgroundLg: obj.backgroundLg,
    backgroundMd: obj.backgroundMd,
    backgroundXs: obj.backgroundXs,
    navi: {
      defaultVal: "",
      elements: [
        {
          item: { text: obj.textNav1 },
          index: obj.indexNav1,
          id: obj.idNav1,
        },
        {
          item: { text: obj.textNav2 },
          index: obj.indexNav2,
          id: obj.idNav2,
        },
        {
          item: { text: obj.textNav3 },
          index: obj.indexNav3,
          id: obj.idNav3,
        },
        {
          item: { text: obj.textNav4 },
          index: obj.indexNav4,
          id: obj.idNav4,
        },
        {
          item: { text: obj.textNav5 },
          index: obj.indexNav5,
          id: obj.idNav5,
        },
        {
          item: { text: obj.textNav6 },
          index: obj.indexNav6,
          id: obj.idNav6,
        },
      ],
    },
  };
  function filterArrayValues(arr: any) {
    arr = arr.filter(isEligible);
    return arr;
  }

  function isEligible(value: any) {
    if (value.index !== null || value.id !== null) {
      return value;
    }
  }

  TmpObj.navi.elements = filterArrayValues(TmpObj.navi.elements);
  return TmpObj;
};
