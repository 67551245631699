// Import Dependencies
import React from 'react';
import { useHistory } from 'react-router-dom';
// Import Components
import { Grid } from '@material-ui/core';
import BlogCard from '../partials/BlogCard';
import { PagePlaceholder } from '../loaders/PagePlaceholder';
import NothingToShow from './NothingToShow';
import { BlueButton } from '../cta/BlueButton';
// Import Variables
import { Blogs } from '../../graphql/queryTemplate/Blogs';
import {
  Container,
  Title,
  BlogsContainer,
  ButtonContainer,
} from '../../styles/components/BlogComponent';
import { Empty } from '../../styles/partials/ButtonsContainer';
import icon from '../../assets/bearbeitung.png';
import { emptyCat } from '../../mocks/mockBlog';

interface BlogsProps {
  category: { name: string; icon: any };
  recommendation?: boolean;
  current?: string;
}

const BlogsComponent = ({ category, recommendation, current }: BlogsProps) => {
  let title = recommendation
    ? 'Das könnte Sie auch interessieren'
    : `${category.name}`;
  title = category.name === 'Pflege Zu Hause' ? 'Pflege zu Hause' : title;
  const blogs = Blogs();
  let objects;

  const history = useHistory();

  const goTo = (name: string, height: number) => {
    setTimeout(() => {
      window.scrollTo(0, height);
    }, 2);
    history.push(`/blog/${name}`);
  };

  if (!blogs.isLoading) {
    const length = recommendation ? 3 : blogs.length;
    if (category.name === 'Neueste Artikel') {
      if (current) {
        objects = blogs.blogs.filter((blog: any) => blog.blogName !== current);
      } else {
        objects = blogs.blogs;
      }
      objects = objects.slice(0, length);
    } else {
      objects = blogs.blogs.filter((blog: any) => {
        if (blog.categories) return blog.categories.includes(category.name);
      });
    }
    objects = objects.sort((a: any, b: any) => {
      let numberA = a.publishDate.split('.');
      let numberB = b.publishDate.split('.');
      numberA = numberA.reverse().join('');
      numberB = numberB.reverse().join('');
      if (numberA > numberB) return 1;
      return -1;
    });
    objects.reverse();
  }
  return (
    <BlogsContainer>
      <Title recommendation={recommendation}>{title}</Title>
      <Container
        container
        xs={12}
        direction="row"
        justify={
          !(objects.length === 1 || objects.length >= 2)
            ? 'flex-start'
            : 'center'
        }
        alignItems="center"
      >
        {!blogs.isLoading ? (
          objects && objects.length > 0 ? (
            objects?.map((card: any, index: number) => (
              <Grid
                item
                xs={12}
                md={6}
                lg={index === objects.length ? 6 : 4}
              >
                <a href={`/blog/${card.blogName}`} style={{ all: 'unset', display: 'none' }} />
                <BlogCard
                  blogCard={card}
                  index={index}
                  last={index === objects.length}
                  getBlog={() => goTo(card.blogName, 0)}
                />
              </Grid>
            ))
          ) : (
            <Empty
              alignItems="center"
              direction="row"
              justify="center"
              container
              xs={12}
            >
              <NothingToShow text={emptyCat} icon={icon} />
            </Empty>
          )
        ) : (
          <PagePlaceholder />
        )}
      </Container>

      {recommendation && (
        <ButtonContainer xs={12} justify="center">
          <BlueButton
            border={false}
            datatype="internal"
            url=""
            text="Zurück zur Blog-Übersicht"
            func={() => goTo('', 0)} // 1150)}
          />
        </ButtonContainer>
      )}
    </BlogsContainer>
  );
};

export default BlogsComponent;
