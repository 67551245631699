import React, { useEffect } from 'react';
// Import Dependencies

// Import Components
import { TextChecker } from '../../utils/RichTextConverter';
import { metaDataHandler } from '../../utils/MetaInfoHandler';
import { Metadata } from '../../graphql/queryTemplate/Metadata';
import {
  Content,
  OuterWrapper,
  Title,
  InnerWrapper,
  Subtitle,
  Text,
} from '../../styles/pages/InfoPage';
import { type } from 'os';
// import Variabel

type PageType = 'impressum' | 'compliance' | 'privPolicy'

interface TextInterface {
  background: { type: string; url: string };
  title?: any;
  subtitle?: string;
  introduction?: any;
  pageType: PageType
}

function defaultMetaProps (pageType: PageType) {
  let metaDescription = '';
  let metaTitle = '';
  let ogSiteName = '';

  switch(pageType){
    case('impressum'): {
      metaDescription = 'Hier finden Sie das Impressum von pflegepartner.de.';
      metaTitle = 'Impressum | PflegePartner';
      ogSiteName = 'Impressum | PflegePartner';
      break;
    }
    case('compliance'): {
      metaDescription = 'Hier finden Sie die Compliance-Hinweise von pflegepartner.de.';
      metaTitle = 'Compliance | PflegePartner';
      ogSiteName = 'Compliance | PflegePartner';
      break;
    }
    case('privPolicy'): {
      metaDescription = 'Hier finden Sie die Datenschutzerklärung von pflegepartner.de.';
      metaTitle = 'Datenschutzerklärung | PflegePartner';
      ogSiteName = 'Datenschutzerklärung | PflegePartner';
      break;
    }
    default: {
      metaDescription = 'Fallback Meta Description';
      ogSiteName = 'Fallback | PflegePartner';
      metaTitle = 'Fallback | PflegePartner';
    }
  }
  const metaProps = {
    url: 'https://pflegepartner.de/',
    title: metaTitle,
    ogTitle: metaTitle,
    ogSiteName: 'Impressum | PflegePartner',
    description: metaDescription,
  };

  return metaProps;
}

function MetaProps(pageType:PageType, preMetaData: any, MetaData: any) {

  switch(pageType){
    case('impressum'): {
      return metaDataHandler({
        ...MetaData.impressum,
        ...preMetaData
      });
    }
    case('compliance'): {
      return metaDataHandler({...MetaData.compliance, ...preMetaData});
    }
    default: {
      metaDataHandler({...MetaData.privPolicy, ...preMetaData});
    }
  }

}

const InfoPage = ({
                    background,
                    title,
                    subtitle,
                    introduction,
                    pageType
                  }: TextInterface) => {
  const metaProps = defaultMetaProps(pageType);
  metaDataHandler(metaProps);

  const preMetaData = {
    metaDescription: metaProps.description,
    metaTitle: metaProps.title,
    title: metaProps.title,
    ogSiteName: metaProps.ogSiteName,
  }

  const MetaData = Metadata();

  useEffect(()=>{
    if(MetaData.isLoading) return
    MetaProps(pageType, preMetaData, MetaData)
  },[MetaData])

  return (
    <OuterWrapper
      container
      background={background}
      alignItems="center"
      justify="center"
      xs={12}
    >
      <InnerWrapper>
        <Title>{title}</Title>
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
        <Content item container alignItems="center" justify="center" xs={12}>
          {introduction && <Text>{TextChecker(introduction)}</Text>}
        </Content>
      </InnerWrapper>
    </OuterWrapper>
  );
};
export default InfoPage;
