import { InMemoryCache } from '@apollo/client';
import { WEGE_LEITEN } from './queryTemplate/WegeLeiten';
import { FINANZIEREN } from './queryTemplate/Finanzieren';
import { ABOUT_US } from './queryTemplate/AboutUs';
import { ZU_HAUSE } from './queryTemplate/Zuhause';
import { METADATA } from './queryTemplate/Metadata';
import { BLOGS } from './queryTemplate/Blogs';
import { BLOGLIST } from './queryTemplate/Blog';

export const cache: InMemoryCache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        ...METADATA,
        ...WEGE_LEITEN,
        ...FINANZIEREN,
        ...ABOUT_US,
        ...ZU_HAUSE,
        ...BLOGS,
        ...BLOGLIST,
      },
    },
  },
});
