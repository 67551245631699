import React from 'react';
import TextChecker from '../../utils/RichTextConverter';
import {
  StyledStepNumber,
  StyledStepText,
  useStyles,
  Wrapper,
} from '../../styles/partials/Stepper';
import RichTextExist from '../../utils/RichTextExist';

interface StepperProps {
  elements: any;
}

function createExistValueList(oldlist: any[]) {
  const list: any[] = [];
  oldlist.forEach((item) => {
    if (RichTextExist(item)) {
      list.push(item);
    }
  });
  return list;
}

export function VerticalStepper({ elements }: StepperProps) {
  const classes = useStyles();
  return (
    <Wrapper className={classes.root}>
      <div className="inner-wrapper">
        {createExistValueList(elements).map((element: any, index: number) => (
          <div className="step">
            <div className="connector" />
            <StyledStepNumber>
              <div className="step-number">{index + 1}</div>
            </StyledStepNumber>
            <StyledStepText>
              <div className="step-text">{TextChecker(element)}</div>
            </StyledStepText>
          </div>
        ))}
      </div>
    </Wrapper>
  );
}
