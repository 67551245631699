// Import Dependencies
import React, {Dispatch, SetStateAction, useState} from 'react';
import { Grid } from '@material-ui/core';
// Import Components
import BlogsComponent from '../components/BlogsComponent';
import { StageComponent } from '../components/StageComponent';
import { CategoriesComponent } from '../components/CategoriesComponent';
// Import Variables
import {
  CategoriesContainer,
  Wrapper,
  TitleBlog,
} from '../../styles/pages/BlogsDashboard';
import { categories, dashboardStage, BlogTitle } from '../../mocks/mockBlog';
import backgroundLg from '../../assets/stageBackground/blog-stage-lg.jpg';
import backgroundMd from '../../assets/stageBackground/blog-stage-md.jpg';
import backgroundXs from '../../assets/stageBackground/blog-stage-xs.jpg';
import { metaDataHandler } from '../../utils/MetaInfoHandler';

interface BlogsDashboardProps {
  setOpenDialog: Dispatch<SetStateAction<boolean>>;
}

const BlogsDashboard = ({setOpenDialog}: BlogsDashboardProps) => {
  const [chosen, setCategory] = useState(0);
  const metaTitle = 'Blog | PflegePartner';
  // const metaDescription = 'Jeder Pflegefall ist anders. Ein besonders belastendes Thema: die Kosten für die Pflege. Hier erfahren Sie, welche Finanzierungsmöglichkeiten Sie haben.';
  const metaProps = {
    url: 'https://pflegepartner.de/blog',
    title: metaTitle,
    indexed: true,
    // description: metaDescription,
  };
  metaDataHandler(
    metaProps,
  );

  return (
    <Wrapper>
      <StageComponent
        {...dashboardStage}
        backgroundLg={backgroundLg}
        backgroundMd={backgroundMd}
        backgroundXs={backgroundXs}
        setOpenDialog={setOpenDialog}
      />
      <CategoriesContainer>
        <Grid container justify="center" alignItems="center" xs={12}>
          <Grid item xs={12}>
            <TitleBlog>{BlogTitle}</TitleBlog>
          </Grid>
          <CategoriesComponent setCategory={setCategory} chosen={chosen} />
        </Grid>
      </CategoriesContainer>
      <BlogsComponent category={categories[chosen]} />
    </Wrapper>
  );
};

export default BlogsDashboard;
