import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import styled from 'styled-components';
import {
  colors, Fonts, InnerWrapperWidth, XSBreakPoint,
} from '../../Theme';

export const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: 'grid',
    width: '100%',
    margin: '0 auto',
    alignItems: 'center',
    marginTop: '16px',
    marginBottom: '48px',

    '& .inner-wrapper': {
      display: 'inline-block',
      width: '80%',
      margin: '0 auto',
      position: 'relative',
      alignItems: 'center',
    },
    '& .connector': {
      position: 'absolute',
      height: '140%',
      width: '2px',
      transform: 'rotate(0deg)',
      backgroundColor: '#69D2E6',
      top: '50%',
      left: '27px',
      zIndex: '1',
    },
    '& .step:last-child .connector': {
      display: 'none',
    },
    '& .step': {
      display: 'inline-flex',
      alignItems: 'center',
      float: 'left',
      position: 'relative',
      top: '0px',
      right: '39px',
      marginLeft: '10px',
      marginBottom: '10px',
      width: '100%',
    },
    '& .step-text': {
      width: '106%',
      marginLeft: '25px',
    },
    '& .step-number': {
      display: 'flex',
      position: 'relative',
      alignItems: 'center',
      justifyContent: 'center',
      fontSize: '29px !important',
      width: '55px',
      height: '55px',
      boxShadow: '1px 6px 16px -1px rgba(0, 0, 0, 0.2)',
      borderRadius: '50%',
      backgroundColor: 'white',
      zIndex: '2',
    },
    '& .paragraph': {
      marginTop: '0 !important',
      marginBottom: '0 !important',
    },
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
}));

export const Wrapper = styled.div`
  max-width: ${InnerWrapperWidth};
  font-family: Raleway sans-serif;
  .MuiTypography-body2 {
    font: normal normal 500 18px/27px Raleway, Medium;
  }
  @media (max-width: ${XSBreakPoint}) {
    .MuiStepConnector-line {
      border-color: transparent !important;
    }
  }
`;

export const StyledStepText = styled.div`
  color: ${colors.darkBlue} !important;
  font: ${Fonts.fontText} !important;
`;
export const StyledStepNumber = styled.div`
  color: ${colors.darkBlue} !important;
  font: ${Fonts.fontIntroText} !important;
  @media (max-width: ${XSBreakPoint}) {
    width: 70px !important;
  }
`;
