import React, { useState } from 'react';
import { Tabs } from '@material-ui/core';
import SwiperCore, { Controller } from 'swiper';
import RoundIcons from './RoundIcons';
import { colors } from '../../Theme';
import TabContent from './TabContent';
import {
  MobileContainer,
  StyledTab,
  Text,
  useStyles,
} from '../../styles/partials/SlidableTabs';

interface SliderTabs {
  items: any;
  isRound?: boolean;
}

SwiperCore.use([Controller]);

const SlidableTabs = ({ items, isRound }: SliderTabs) => {
  const [value, setValue] = useState(0);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const classes = useStyles();
  return (
    <MobileContainer container direction="row" justify="flex-start">
      <Tabs
        value={value}
        onChange={handleChange}
        variant="scrollable"
        scrollButtons="on"
        selectionFollowsFocus
        classes={{
          indicator: classes.indicator,
          root: classes.root,
        }}
        style={{
          fontFamily: 'Raleway',
          width: 'auto',
          color: colors.middleBlue,
        }}
      >
        {items.map((item: any, index: number) => (
          <StyledTab
            key={index}
            className="TabTab"
            label={<Text>{item.text}</Text>}
            icon={
              isRound ? (
                <RoundIcons icon={item.icon} width={80} height={60} />
              ) : (
                <img src={item.icon} alt="image" />
              )
            }
          />
        ))}
      </Tabs>
      {items[0]?.content
        && items.map((item: any, index: number) => (
          <div key={index}>
            <TabContent
              value={value}
              index={index}
              content={item.content}
              example={item.example}
              costs={item.costs}
              key={index}
            />
          </div>
        ))}
    </MobileContainer>
  );
};

export default SlidableTabs;
