export const ImpressumObj = {
  background: { type: 'color', url: 'white' },
  title: 'Impressum',
  introduction: [
    {
      type: 'paragraph',
      data: {
        text:
            'Diese Seiten sind ein Service des Konzerns Versicherungskammer und seiner Konzernunternehmen.',
      },
    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },
    },
    {
      type: 'paragraph',
      data:
        {
          text: '<b>1. Angaben zum Anbieter</b>',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<b>Versicherungskammer Bayern</b>',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<b>Versicherungsanstalt des öffentlichen Rechts</b>',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<b>Anschrift:</b> Maximilianstraße 53, 80530 München',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<b>Tel.:</b> (089) 21 60-0',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<b>Fax:</b> (089) 21 60-27 14',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<b>E-Mail Sammelpostfach:</b> service@vkb.de',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<b>Handelsregister:</b> AG München HRA 70 400',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<b>USt. Ident. Nr.:</b> DE172489027',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<b>Sitz der Gesellschaft: München',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<b>Vorstand:</b>',
        },
    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Prof. Dr. Frank Walthes (Vorsitzender)',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Barbara Schick (Stellvertretende Vorstandsvorsitzende)',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Andreas Kolb',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Dr. Robert Heene',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Klaus G. Leyh',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Isabella Martorell Naßl',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Dr. Stephan Spieleder',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<b>Vorsitzender des Verwaltungsrates:</b> Prof. Dr. Ulrich Reuter',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<b>Zuständige Aufsichtsbehörde:</b>',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Bundesanstalt für Finanzdienstleistungsaufsicht (BaFin)',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Versicherungs- und Pensionsfondsaufsicht',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Graurheindorfer Str. 108',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '53117 Bonn',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Telefon: (0228) 41 08-0',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Telefax: (0228) 41 08-15 50',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'E-Mail: poststelle@bafin.de',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<b>Bayerische Landesbrandversicherung AG</b>',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<b>Anschrift:</b> Maximilianstraße 53, 80538 München',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<b>Tel.:</b> (089) 21 60-0',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<b>Fax:</b> (089) 21 60-27 14',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<b>E-Mail Sammelpostfach:</b> service@vkb.de',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<b>Handelsregister:</b> AG München HRB 110 001',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<b>USt. Ident. Nr.:</b> DE259197847',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<b>Sitz der Gesellschaft:</b> München',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<b>Vorstand:</b>',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Prof. Dr. Frank Walthes (Vorsitzender)',
        },
    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Barbara Schick (Stellvertretende Vorstandsvorsitzende)',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Dr. Robert Heene',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Klaus G. Leyh',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Isabella Martorell Naßl',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Dr. Stephan Spieleder',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<b>Vorsitzender des Aufsichtsrats:</b> Dr. Ewald Maier',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<b>Zuständige Aufsichtsbehörde:</b>',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Bundesanstalt für Finanzdienstleistungsaufsicht (BaFin)',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Versicherungs- und Pensionsfondsaufsicht',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Graurheindorfer Str. 108',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '53117 Bonn',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Telefon: (0228) 41 08-0',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Telefax: (0228) 41 08-15 50',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'E-Mail: poststelle@bafin.de',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text:
            '<b>Bayerischer Versicherungsverband Versicherungsaktiengesellschaft</b>',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<b>Anschrift:</b> Maximilianstraße 53, 80530 München',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<b>Tel.:</b> (089) 21 60-0',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<b>Fax:</b> (089) 21 60-27 14',
        },
    },
    {
      type: 'paragraph',
      data:
        {
          text: '<b>E-Mail Sammelpostfach:</b> service@vkb.de',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<b>Handelsregister:</b> AG München HRB 110 000',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<b>USt. Ident. Nr.:</b> DE259197855',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<b>Sitz der Gesellschaft:</b> München',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<b>Vorstand:</b>',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Prof. Dr. Frank Walthes (Vorsitzender)',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Barbara Schick (Stellvertretende Vorstandsvorsitzende)',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Dr. Robert Heene',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Andreas Kolb',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Klaus G. Leyh',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Isabella Martorell Naßl',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Dr. Stephan Spieleder',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<b>Vorsitzender des Aufsichtsrats:</b> Dr. Ewald Maier',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<b>Zuständige Aufsichtsbehörde:</b>',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Bundesanstalt für Finanzdienstleistungsaufsicht (BaFin)',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Versicherungs- und Pensionsfondsaufsicht',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Graurheindorfer Str. 108',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '53117 Bonn',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Telefon: (0228) 41 08-0',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Telefax: (0228) 41 08-15 50',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'E-Mail: poststelle@bafin.de',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<b>Bayern-Versicherung Lebensversicherung AG</b>',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<b>Anschrift:</b> Maximilianstraße 53, 80538 München',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<b>Tel.:</b> (089) 21 60-0',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<b>Fax:</b> (089) 21 60-27 14',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<b>E-Mail Sammelpostfach:</b> service@vkb.de',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<b>Handelsregister:</b> AG München HRB 123660',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<b>USt. Ident. Nr.:</b> DE129275125',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<b>Sitz der Gesellschaft:</b> München',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<b>Vorstand:</b>',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Prof. Dr. Frank Walthes (Vorsitzender)',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Barbara Schick (Stellvertretende Vorstandsvorsitzende)',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Dr. Robert Heene',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Klaus G. Leyh',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Isabella Martorell Naßl',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Dr. Stephan Spieleder',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<b>Vorsitzender des Aufsichtsrats:</b> Dr. Thomas Jung',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<b>Zuständige Aufsichtsbehörde:</b>',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Bundesanstalt für Finanzdienstleistungsaufsicht (BaFin)',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Versicherungs- und Pensionsfondsaufsicht',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Graurheindorfer Str. 108',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '53117 Bonn',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Telefon: (0228) 41 08-0',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Telefax: (0228) 41 08-15 50',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'E-Mail: poststelle@bafin.de',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<b>Bayerische Beamtenkrankenkasse Aktiengesellschaft</b>',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<b>Anschrift:</b> Maximilianstraße 53, 80530 München',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<b>Tel.:</b> (0 89) 21 60-88 88',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<b>Fax:</b> (0 89) 21 60-80 01',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<b>E-Mail Sammelpostfach:</b> service@vkb.de',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<b>Handelsregister:</b> AG München HRB 111 650',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<b>USt. Ident. Nr.:</b> DE245885569',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<b>Sitz der Gesellschaft:</b> München',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<b>Vorstand:</b>',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Isabella Martorell Naßl (Vorsitzende)',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Martin Fleischer',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Katharina Jessel',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<b>Vorsitzender des Aufsichtsrats:</b> Dr. Wolfgang Breuer',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<b>Zuständige Aufsichtsbehörde:</b>',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Bundesanstalt für Finanzdienstleistungsaufsicht (BaFin)',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Versicherungs- und Pensionsfondsaufsicht',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Graurheindorfer Str. 108',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '53117 Bonn',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Telefon: (0228) 41 08-0',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Telefax: (0228) 41 08-15 50',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'E-Mail: poststelle@bafin.de',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<b>2. Verantwortliche i.S.v. § 55 Abs. 2 RStV</b>',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Digital Marketing Solutions - 2MS02',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Redaktionelle Verantwortung: Sandy Lehmann',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'E-Mail: Sandy.Lehmann@vkb.de',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text:
            'Dieses Impressum gilt auch für unser Angebot auf Facebook, Instagram, Twitter, YouTube und XING. ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text:
            'Weitere Informationen zu den einzelnen Unternehmen des Konzerns finden Sie unter Einzelunternehmen.',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text:
            'Die Versicherungskammer Bayern ist ein öffentlicher Versicherer.',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text:
            'Weitere Informationen zum Datenschutz und der Sicherheit Ihrer Daten finden Sie unter Datenschutz.',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<b>3. Nutzungshinweise</b>',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Hinweis zur Aktualität',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text:
            'Alle Informationen und Inhalte auf den Internetseiten der Versicherungskammer Bayern (VKB) werden von der Versicherungskammer Bayern ständig überprüft und aktualisiert. Trotz aller Sorgfalt können sich aber Angaben zwischenzeitlich verändert haben. Eine Haftung oder Garantie für die Aktualität, Richtigkeit und Vollständigkeit der zur Verfügung gestellten Informationen kann daher nicht übernommen werden.',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Hinweis für Hyperlinks auf Webseiten von Dritten ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '&nbsp;',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text:
            'Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung für die Inhalte externer Links. Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich. Zum Zeitpunkt der Verlinkung lagen keine für uns erkennbaren Rechtsverstöße in den Inhalten des Links vor. Werden uns Rechtsverstöße später bekannt, wird der hiervon betroffene Link sofort gelöscht.',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Urheberrechte',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text:
            'Alle Inhalte, die Struktur und sonstige Komponenten der Internetseiten der Versicherungskammer Bayern sind urheberrechtlich geschützt. Jede Art der Vervielfältigung, Bearbeitung, Verbreitung und/oder Verwertung von Informationen und/oder Daten, die vom Urheberrecht nicht ausdrücklich erlaubt ist, insbesondere die Verwendung von Texten, Textteilen oder Bildmaterial bedarf unserer vorherigen schriftlichen Zustimmung bzw. derjenigen des jeweiligen Urhebers. Soweit die Inhalte auf dieser Seite nicht von uns erstellt wurden, beachten wir die Urheberrechte Dritter, insbesondere kennzeichnen wir die Inhalte Dritter als solche. Downloads und Kopien der Seite bzw. ihrer Inhalte sind nur für den privaten Gebrauch gestattet.',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Bildnachweise',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '[folgen]',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text:
            '<b>4. Technische Voraussetzungen zur Nutzung der Internetseiten der Versicherungskammer Bayern</b>',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text:
            'Um unsere Internetseiten uneingeschränkt nutzen zu können, sollten Sie folgende Browser-Versionen verwenden:',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Desktop:',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Internet Explorer ab Version 11',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Firefox ab Version 32',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Google Chrome ab Version 32',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Mobile Geräte:',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Safari',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Google Chrome',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Opera Mini',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Firefox Mobil',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Internet Explorer Mobil',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Android Standard',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text:
            'Die Internetseiten der Versicherungskammer Bayern nutzen JavaScript zur responsiven Darstellung und um einige Vorgänge für Anwender einfacher zu gestalten sowie für die Navigation innerhalb der Website.',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text:
            'Wir speichern auf Ihrer Festplatte Cookies, die lediglich für die Dauer Ihres Besuches auf unserer Website Gültigkeit haben. Diese Cookies enthalten ausschließlich eine eindeutige Zeichenabfolge, der außerhalb unserer Website keine Bedeutung zukommt. Wir verwenden diese Zeichenabfolge dazu, Ihnen bei Ihrem Besuch unserer Website bestimmte Inhalte anbieten zu können, zu deren technischen Voraussetzungen das Setzen solcher Cookies unbedingt gehört.',
        },

    },
  ],
};

export const ComplianceObj = {
  background: { type: 'color', url: 'white' },
  title: 'Compliance',
  introduction: [
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text:
            'Die Unternehmen des Konzerns Versicherungskammer sind verlässliche, integre und faire Partner. Dies ist eine der Grundlagen für unseren Erfolg. Daher ist die Sicherstellung von Compliance und die Vermeidung sowie Minimierung hiermit verbundener Risiken von besonderer Bedeutung und trägt zur Werthaltigkeit des Konzerns bei.',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text:
            'Compliance im Konzern Versicherungskammer bedeutet die Einhaltung aller Gesetze und Verordnungen sowie aller aufsichtsbehördlichen Anforderungen und Selbstverpflichtungen mit Außenwirkung.',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text:
            'Proaktiv Risiken zu vermeiden und eine stete Aufmerksamkeit hinsichtlich rechtlicher Risiken zu schaffen ist nur ein Ziel von Compliance. Daneben gehören die Risikokontroll-, Frühwarn-, Überwachungs- und Beratungsfunktion zu den Kernaufgaben von Compliance. Dieses Rechtsbewusstsein bewahrt das Unternehmen vor wirtschaftlichen Schäden.',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text:
            'Der Versicherungskammer Konzern hat sich hierfür ein Compliance-Management-System (CMS), Verhaltensgrundsätze und interne Regelungen gegeben, die für alle Mitarbeiter und Führungskräfte gleichermaßen gelten. Diese unterstützen den langfristigen Aufbau einer natürlichen Compliance-Kultur.',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<b>Wer ist Ansprechpartner für Compliance?</b>',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text:
            'Zentraler Ansprechpartner für das Thema Compliance ist der Leiter der Abteilung Compliance (1UR04) des Konzerns Versicherungskammer, Herr Weimann.',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'E-Mail: compliance@vkb.de',
        },

    },
  ],
};

export const PrivPolicyObj = {
  background: { type: 'color', url: 'white' },
  title: 'Datenschutzerklärung',
  introduction: [
    {
      type: 'paragraph',
      data:
        {
          text: '<b>1. Datenschutz auf einen Blick</b> ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<b>Allgemeine Hinweise</b> ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren personenbezogenen Daten passiert, wenn Sie diese Website besuchen. Personenbezogene Daten sind alle Daten, mit denen Sie persönlich identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen Sie unserer unter diesem Text aufgeführten Datenschutzerklärung.',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<b>Datenerfassung auf dieser Website</b> ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<i>Wer ist verantwortlich für die Datenerfassung auf dieser Website?</i> ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Die Datenverarbeitung auf dieser Website erfolgt durch den Websitebetreiber. Dessen Kontaktdaten können Sie dem Abschnitt „Hinweis zur Verantwortlichen Stelle“ in dieser Datenschutzerklärung entnehmen.',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<i>Wie erfassen wir Ihre Daten?</i> ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es sich z. B. um Daten handeln, die Sie in ein Kontaktformular eingeben. Andere Daten werden automatisch oder nach Ihrer Einwilligung beim Besuch der Website durch unsere ITSysteme erfasst. Das sind vor allem technische Daten (z. B. Internetbrowser, Betriebssystem oder Uhrzeit des Seitenaufrufs). Die Erfassung dieser Daten erfolgt automatisch, sobald Sie diese Website betreten.',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<i>Wofür nutzen wir Ihre Daten?</i> ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der Website zu gewährleisten. Andere Daten können zur Analyse Ihres Nutzerverhaltens verwendet werden.',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<i>Welche Rechte haben Sie bezüglich Ihrer Daten?</i> ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft, Empfänger und Zweck Ihrer gespeicherten personenbezogenen Daten zu erhalten. Sie haben außerdem ein Recht, die Berichtigung oder Löschung dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur Datenverarbeitung erteilt haben, können Sie diese Einwilligung jederzeit für die Zukunft widerrufen. Außerdem haben Sie das Recht, unter bestimmten Umständen die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Des Weiteren steht Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu. Hierzu sowie zu weiteren Fragen zum Thema Datenschutz können Sie sich jederzeit an uns wenden.',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<b>Analyse-Tools und Tools von Drittanbietern</b> ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Beim Besuch dieser Website kann Ihr Surf-Verhalten statistisch ausgewertet werden. Das geschieht vor allem mit sogenannten Analyseprogrammen. Detaillierte Informationen zu diesen Analyseprogrammen finden Sie in der folgenden Datenschutzerklärung.',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<b>2. Hosting und Content Delivery Networks (CDN)</b> ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Wir hosten die Inhalte unserer Website bei folgendem Anbieter:',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<b>Externes Hosting</b> ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Diese Website wird extern gehostet. Die personenbezogenen Daten, die auf dieser Website erfasst werden, werden auf den Servern des Hosters gespeichert. Hierbei kann es sich v. a. um IP-Adressen, Kontaktanfragen, Meta- und Kommunikationsdaten, Vertragsdaten, Kontaktdaten, Namen, Websitezugriffe und sonstige Daten, die über eine Website generiert werden, handeln. Das externe Hosting erfolgt zum Zwecke der Vertragserfüllung gegenüber unseren potenziellen und bestehenden Kunden (Art. 6 Abs. 1 lit. b DSGVO) und im Interesse einer sicheren, schnellen und effizienten Bereitstellung unseres Online-Angebots durch einen professionellen Anbieter (Art. 6 Abs. 1 lit. f DSGVO). Sofern eine entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG, soweit die Einwilligung die Speicherung von Cookies oder den Zugriff auf Informationen im Endgerät des Nutzers (z. B. Device-Fingerprinting) im Sinne des TTDSG umfasst. Die Einwilligung ist jederzeit widerrufbar. Unser(e) Hoster wird bzw. werden Ihre Daten nur insoweit verarbeiten, wie dies zur Erfüllung seiner Leistungspflichten erforderlich ist und unsere Weisungen in Bezug auf diese Daten befolgen.',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Wir setzen folgende(n) Hoster ein:',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'OEV Online Dienste GmbH',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Hansaallee 183',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '40549 Düsseldorf',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'OEV Online Dienste GmbH nutzt zum Zwecke des Hostings als Subunternehmer die Amazon Web Services EMEA SARL, 38 Avenue John F. Kennedy, 1855 Luxemburg (nachfolgend: AWS). Wenn Sie unsere Website besuchen, werden Ihre personenbezogenen Daten auf den Servern von AWS verarbeitet. Hierbei können auch personenbezogene Daten an das Mutterunternehmen von AWS in die USA übermittelt werden. Die Datenübertragung in die USA wird auf die EU-Standardvertragsklauseln gestützt. Details finden Sie hier: https://aws.amazon.com/de/blogs/security/aws-gdpr-data-processing-addendum/ . Weitere Informationen entnehmen Sie der Datenschutzerklärung von AWS: https://aws.amazon.com/de/privacy/?nc1=f_pr .',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<i>Auftragsverarbeitung</i> ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Wir haben einen Vertrag über Auftragsverarbeitung (AVV) zur Nutzung des oben genannten Dienstes geschlossen. Hierbei handelt es sich um einen datenschutzrechtlich vorgeschriebenen Vertrag, der gewährleistet, dass dieser die personenbezogenen Daten unserer Websitebesucher nur nach unseren Weisungen und unter Einhaltung der DSGVO verarbeitet.',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<b>Amazon CloudFront CDN</b> ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Der Hoster nutzt das Content Delivery Network Amazon CloudFront CDN. Anbieter ist die Amazon Web Services EMEA SARL, 38 avenue John F. Kennedy, L-1855, Luxemburg (nachfolgend „Amazon“). Bei Amazon CloudFront CDN handelt es sich um ein weltweit verteiltes Content Delivery Network. Dabei wird technisch der Informationstransfer zwischen Ihrem Browser und unserer Website über das Content Delivery Network geleitet. Hierdurch können wir die weltweite Erreichbarkeit und die Leistungsfähigkeit unserer Website erhöhen. Der Einsatz von Amazon CloudFront CDN beruht auf unserem berechtigten Interesse an einer möglichst fehlerfreien und sicheren Bereitstellung unseres Webangebotes (Art. 6 Abs. 1 lit. f DSGVO). Die Datenübertragung in die USA wird auf die Standardvertragsklauseln der EU-Kommission gestützt. Details finden Sie hier: https://aws.amazon.com/de/blogs/security/aws-gdpr-data-processing-addendum/.',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Weitere Informationen zu Amazon CloudFront CDN finden Sie hier: https://d1.awsstatic.com/legal/privacypolicy/AWS_Privacy_Notice__German_Translation.pdf',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<i>Auftragsverarbeitung</i>',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Wir haben einen Vertrag über Auftragsverarbeitung (AVV) zur Nutzung des oben genannten Dienstes geschlossen. Hierbei handelt es sich um einen datenschutzrechtlich vorgeschriebenen Vertrag, der gewährleistet, dass dieser die personenbezogenen Daten unserer Websitebesucher nur nach unseren Weisungen und unter Einhaltung der DSGVO verarbeitet.',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<b>3. Allgemeine Hinweise und Pflichtinformationen</b> ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<b>Datenschutz</b> ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Die Betreiber dieser Seiten nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir behandeln Ihre personenbezogenen Daten vertraulich und entsprechend den gesetzlichen Datenschutzvorschriften sowie dieser Datenschutzerklärung. Wenn Sie diese Website benutzen, werden verschiedene personenbezogene Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie persönlich identifiziert werden können. Die vorliegende Datenschutzerklärung erläutert, welche Daten wir erheben und wofür wir sie nutzen. Sie erläutert auch, wie und zu welchem Zweck das geschieht. Wir weisen darauf hin, dass die Datenübertragung im Internet (z. B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor dem Zugriff durch Dritte ist nicht möglich.',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<b>Hinweis zur verantwortlichen Stelle</b> ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Die verantwortliche Stelle für die Datenverarbeitung auf dieser Website ist:',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Versicherungskammer Bayern',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Versicherungsanstalt des öffentlichen Rechts',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Maximilianstraße 53',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '80530 München',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Telefon: (089) 21 60-0',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'E-Mail: service@vkb.de',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Handelsregister: AG München, HRA 70 400',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'USt. Ident. Nr.: DE172489027',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Sitz der Gesellschaft: München',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Verantwortliche Stelle ist die natürliche oder juristische Person, die allein oder gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z.B. Namen, E-Mail-Adressen o. Ä.) entscheidet.',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<b>Speicherdauer</b> ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Soweit innerhalb dieser Datenschutzerklärung keine speziellere Speicherdauer genannt wurde, verbleiben Ihre personenbezogenen Daten bei uns, bis der Zweck für die Datenverarbeitung entfällt. Wenn Sie ein berechtigtes Löschersuchen geltend machen oder eine Einwilligung zur Datenverarbeitung widerrufen, werden Ihre Daten gelöscht, sofern wir keine anderen rechtlich zulässigen Gründe für die Speicherung Ihrer personenbezogenen Daten haben (z. B. steuer- oder handelsrechtliche Aufbewahrungsfristen); im letztgenannten Fall erfolgt die Löschung nach Fortfall dieser Gründe.',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<b>Allgemeine Hinweise zu den Rechtsgrundlagen der Datenverarbeitung auf dieser Website</b> ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Sofern Sie in die Datenverarbeitung eingewilligt haben, verarbeiten wir Ihre personenbezogenen Daten auf Grundlage von Art. 6 Abs. 1 lit. a DSGVO bzw. Art. 9 Abs. 2 lit. a DSGVO, sofern besondere Datenkategorien nach Art. 9 Abs. 1 DSGVO verarbeitet werden. Im Falle einer ausdrücklichen Einwilligung in die Übertragung personenbezogener Daten in Drittstaaten erfolgt die Datenverarbeitung außerdem auf Grundlage von Art. 49 Abs. 1 lit. a DSGVO. Sofern Sie in die Speicherung von Cookies oder in den Zugriff auf Informationen in Ihr Endgerät (z. B. via Device-Fingerprinting) eingewilligt haben, erfolgt die Datenverarbeitung zusätzlich auf Grundlage von § 25 Abs. 1 TTDSG. Die Einwilligung ist jederzeit widerrufbar. Sind Ihre Daten zur Vertragserfüllung oder zur Durchführung vorvertraglicher Maßnahmen erforderlich, verarbeiten wir Ihre Daten auf Grundlage des Art. 6 Abs. 1 lit. b DSGVO. Des Weiteren verarbeiten wir Ihre Daten, sofern diese zur Erfüllung einer rechtlichen Verpflichtung erforderlich sind auf Grundlage von Art. 6 Abs. 1 lit. c DSGVO. Die Datenverarbeitung kann ferner auf Grundlage unseres berechtigten Interesses nach Art. 6 Abs. 1 lit. f DSGVO erfolgen. Über die jeweils im Einzelfall einschlägigen Rechtsgrundlagen wird in den folgenden Absätzen dieser Datenschutzerklärung informiert.',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<b>Datenschutzbeauftragter</b> ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Wir haben einen Datenschutzbeauftragten benannt. Unseren Datenschutzbeauftragten erreichen Sie per Post unter der o.g. Adresse mit dem Zusatz - Datenschutzbeauftragter - oder per E-Mail unter datenschutz@vkb.de.',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<b>Hinweis zur Datenweitergabe in die USA und sonstige Drittstaaten</b> ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Wir verwenden unter anderem Tools von Unternehmen mit Sitz in den USA oder sonstigen datenschutzrechtlich nicht sicheren Drittstaaten. Wenn diese Tools aktiv sind, können Ihre personenbezogene Daten in diese Drittstaaten übertragen und dort verarbeitet werden. Wir weisen darauf hin, dass in diesen Ländern kein mit der EU vergleichbares Datenschutzniveau garantiert werden kann. Beispielsweise sind US-Unternehmen dazu verpflichtet, personenbezogene Daten an Sicherheitsbehörden herauszugeben, ohne dass Sie als Betroffener hiergegen gerichtlich vorgehen könnten. Es kann daher nicht ausgeschlossen werden, dass US-Behörden (z. B. Geheimdienste) Ihre auf US-Servern befindlichen Daten zu Überwachungszwecken verarbeiten, auswerten und dauerhaft speichern. Wir haben auf diese Verarbeitungstätigkeiten keinen Einfluss.',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<b>Widerruf Ihrer Einwilligung zur Datenverarbeitung</b> ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich. Sie können eine bereits erteilte Einwilligung jederzeit widerrufen. Die Rechtmäßigkeit der bis zum Widerruf erfolgten Datenverarbeitung bleibt vom Widerruf unberührt.',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<b>Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie gegen Direktwerbung (Art. 21 DSGVO)</b> ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER F DSGVO 6 / 12 ERFOLGT, HABEN SIE JEDERZEIT DAS RECHT, AUS GRÜNDEN, DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE VERARBEITUNG IHRER PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN; DIES GILT AUCH FÜR EIN AUF DIESE BESTIMMUNGEN GESTÜTZTES PROFILING. DIE JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT, ENTNEHMEN SIE DIESER DATENSCHUTZERKLÄRUNG. WENN SIE WIDERSPRUCH EINLEGEN, WERDEN WIR IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT MEHR VERARBEITEN, ES SEI DENN, WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE VERARBEITUNG NACHWEISEN, DIE IHRE INTERESSEN, RECHTE UND FREIHEITEN ÜBERWIEGEN ODER DIE VERARBEITUNG DIENT DER GELTENDMACHUNG, AUSÜBUNG ODER VERTEIDIGUNG VON RECHTSANSPRÜCHEN (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO).',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'WERDEN IHRE PERSONENBEZOGENEN DATEN VERARBEITET, UM sDIREKTWERBUNG ZU BETREIBEN, SO HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM ZWECKE DERARTIGER WERBUNG EINZULEGEN; DIES GILT AUCH FÜR DAS PROFILING, SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN VERBINDUNG STEHT. WENN SIE WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN ANSCHLIESSEND NICHT MEHR ZUM ZWECKE DER DIREKTWERBUNG VERWENDET (WIDERSPRUCH NACH ART. 21 ABS. 2 DSGVO).',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<b>Beschwerderecht bei der zuständigen Aufsichtsbehörde</b> ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein Beschwerderecht bei einer Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes oder des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht besteht unbeschadet anderweitiger verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe.',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<b>Recht auf Datenübertragbarkeit</b> ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung eines Vertrags automatisiert verarbeiten, an sich oder an einen Dritten in einem gängigen, maschinenlesbaren Format aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen verlangen, erfolgt dies nur, soweit es technisch machbar ist.',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<b>Auskunft, Löschung und Berichtigung</b> ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung oder Löschung dieser Daten. Hierzu sowie zu weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit an uns wenden.',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<b>Recht auf Einschränkung der Verarbeitung</b> ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Hierzu können Sie sich jederzeit an uns wenden. Das Recht auf Einschränkung der Verarbeitung besteht in folgenden Fällen:',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '● Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten bestreiten, benötigen wir in der Regel Zeit, um dies zu überprüfen. Für die Dauer der Prüfung haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '● Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig geschah/geschieht, können Sie statt der Löschung die Einschränkung der Datenverarbeitung verlangen.',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '● Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie jedoch zur Ausübung, Verteidigung oder Geltendmachung von Rechtsansprüchen benötigen, haben Sie das Recht, statt der Löschung die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '● Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine Abwägung zwischen Ihren und unseren Interessen vorgenommen werden. Solange noch nicht feststeht, wessen Interessen überwiegen, haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen.',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt haben, dürfen diese Daten – von ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder juristischen Person oder aus Gründen eines wichtigen öffentlichen Interesses der Europäischen Union oder eines Mitgliedstaats verarbeitet werden.',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<b>SSL- bzw. TLS-Verschlüsselung</b>',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Diese Seite nutzt aus Sicherheitsgründen und zum Schutz der Übertragung vertraulicher Inhalte, wie zum Beispiel Bestellungen oder Anfragen, die Sie an uns als Seitenbetreiber senden, eine SSL- bzw. TLSVerschlüsselung. Eine verschlüsselte Verbindung erkennen Sie daran, dass die Adresszeile des Browsers von „http://“ auf „https://“ wechselt und an dem Schloss-Symbol in Ihrer Browserzeile. Wenn die SSL- bzw. TLS-Verschlüsselung aktiviert ist, können die Daten, die Sie an uns übermitteln, nicht von Dritten mitgelesen werden.',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<b>4. Datenerfassung auf dieser Website</b>',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<b>Cookies</b>',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Unsere Internetseiten verwenden so genannte „Cookies“. Cookies sind kleine Datenpakete und richten auf Ihrem Endgerät keinen Schaden an. Sie werden entweder vorübergehend für die Dauer einer Sitzung (Session-Cookies) oder dauerhaft (permanente Cookies) auf Ihrem Endgerät gespeichert. Session-Cookies werden nach Ende Ihres Besuchs automatisch gelöscht. Permanente Cookies bleiben auf Ihrem Endgerät gespeichert, bis Sie diese selbst löschen oder eine automatische Löschung durch Ihren Webbrowser erfolgt.',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Teilweise können auch Cookies von Drittunternehmen auf Ihrem Endgerät gespeichert werden, wenn Sie unsere Seite betreten (Third-Party-Cookies). Diese ermöglichen uns oder Ihnen die Nutzung bestimmter Dienstleistungen des Drittunternehmens (z. B. Cookies zur Abwicklung von Zahlungsdienstleistungen).',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Cookies haben verschiedene Funktionen. Zahlreiche Cookies sind technisch notwendig, da bestimmte Websitefunktionen ohne diese nicht funktionieren würden (z. B. die Warenkorbfunktion oder die Anzeige von Videos). Andere Cookies dienen dazu, das Nutzerverhalten auszuwerten oder Werbung anzuzeigen.',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Cookies, die zur Durchführung des elektronischen Kommunikationsvorgangs, zur Bereitstellung bestimmter, von Ihnen erwünschter Funktionen (z. B. für die Warenkorbfunktion) oder zur Optimierung der Website (z. B. Cookies zur Messung des Webpublikums) erforderlich sind (notwendige Cookies), werden auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO gespeichert, sofern keine andere Rechtsgrundlage angegeben wird. Der Websitebetreiber hat ein berechtigtes Interesse an der Speicherung von notwendigen Cookies zur technisch fehlerfreien und optimierten Bereitstellung seiner Dienste. Sofern eine Einwilligung zur Speicherung von Cookies und vergleichbaren Wiedererkennungstechnologien abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage dieser Einwilligung (Art. 6 Abs. 1 lit. a DSGVO und § 25 Abs. 1 TTDSG); die Einwilligung ist jederzeit widerrufbar.',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Sie können Ihren Browser so einstellen, dass Sie über das Setzen von Cookies informiert werden und Cookies nur im Einzelfall erlauben, die Annahme von Cookies für bestimmte Fälle oder generell ausschließen sowie das automatische Löschen der Cookies beim Schließen des Browsers aktivieren. Bei der Deaktivierung von Cookies kann die Funktionalität dieser Website eingeschränkt sein.',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Soweit Cookies von Drittunternehmen oder zu Analysezwecken eingesetzt werden, werden wir Sie hierüber im Rahmen dieser Datenschutzerklärung gesondert informieren und ggf. eine Einwilligung abfragen.',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<b>Einwilligung mit Usercentrics</b>',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Diese Website nutzt die Consent-Technologie von Usercentrics, um Ihre Einwilligung zur Speicherung bestimmter Cookies auf Ihrem Endgerät oder zum Einsatz bestimmter Technologien einzuholen und diese datenschutzkonform zu dokumentieren. Anbieter dieser Technologie ist die Usercentrics GmbH, Sendlinger Straße 7, 80331 München, Website: https://usercentrics.com/de/ (im Folgenden „Usercentrics“).',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Wenn Sie unsere Website betreten, werden folgende personenbezogene Daten an Usercentrics übertragen:',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '● Ihre Einwilligung(en) bzw. der Widerruf Ihrer Einwilligung(en)',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '● Ihre IP-Adresse',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '● Informationen über Ihren Browser',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '● Informationen über Ihr Endgerät',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '● Zeitpunkt Ihres Besuchs auf der Website',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Des Weiteren speichert Usercentrics ein Cookie in Ihrem Browser, um Ihnen die erteilten Einwilligungen bzw. deren Widerruf zuordnen zu können. Die so erfassten Daten werden gespeichert, bis Sie uns zur Löschung auffordern, das Usercentrics-Cookie selbst löschen oder der Zweck für die Datenspeicherung entfällt. Zwingende gesetzliche Aufbewahrungspflichten bleiben unberührt.',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Der Einsatz von Usercentrics erfolgt, um die gesetzlich vorgeschriebenen Einwilligungen für den Einsatz bestimmter Technologien einzuholen. Rechtsgrundlage hierfür ist Art. 6 Abs. 1 lit. c DSGVO.',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<b>Auftragsverarbeitung</b>',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Wir haben einen Vertrag über Auftragsverarbeitung (AVV) zur Nutzung des oben genannten Dienstes geschlossen. Hierbei handelt es sich um einen datenschutzrechtlich vorgeschriebenen Vertrag, der gewährleistet, dass dieser die personenbezogenen Daten unserer Websitebesucher nur nach unseren Weisungen und unter Einhaltung der DSGVO verarbeitet.',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<b>Server-Log-Dateien</b>',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Der Provider der Seiten erhebt und speichert automatisch Informationen in so genannten Server-LogDateien, die Ihr Browser automatisch an uns übermittelt. Dies sind:',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '● Browsertyp und Browserversion',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '● verwendetes Betriebssystem',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '● Referrer URL',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '● Hostname des zugreifenden Rechners',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '● Uhrzeit der Serveranfrage',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '● IP-Adresse',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen.',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Die Erfassung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. f DSGVO. Der Websitebetreiber hat ein berechtigtes Interesse an der technisch fehlerfreien Darstellung und der Optimierung seiner Website – hierzu müssen die Server-Log-Files erfasst werden.',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<b>Anfrage per E-Mail, Telefon oder Telefax</b>',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Wenn Sie uns per E-Mail, Telefon oder Telefax kontaktieren, wird Ihre Anfrage inklusive aller daraus hervorgehenden personenbezogenen Daten (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei uns gespeichert und verarbeitet. Diese Daten geben wir nicht ohne Ihre Einwilligung weiter.',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Die Verarbeitung dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen erforderlich ist. In allen übrigen Fällen beruht die Verarbeitung auf unserem berechtigten Interesse an der effektiven Bearbeitung der an uns gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese abgefragt wurde; die Einwilligung ist jederzeit widerrufbar.',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Die von Ihnen an uns per Kontaktanfragen übersandten Daten verbleiben bei uns, bis Sieuns zur Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder der Zweck für die Datenspeicherung entfällt (z. B. nach abgeschlossener Bearbeitung Ihres Anliegens). Zwingende gesetzliche Bestimmungen – insbesondere gesetzliche Aufbewahrungsfristen – bleiben unberührt.',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<b>5. Newsletter</b>',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<b>Newsletterdaten</b>',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Wenn Sie den auf der Website angebotenen Newsletter beziehen möchten, benötigen wir von Ihnen eine EMail-Adresse sowie Informationen, welche uns die Überprüfung gestatten, dass Sie der Inhaber der angegebenen E-Mail-Adresse und mit dem Empfang des Newsletters einverstanden sind. Weitere Daten werden nicht bzw. nur auf freiwilliger Basis erhoben. Für die Abwicklung der Newsletter nutzen wir Newsletterdiensteanbieter, die nachfolgend beschrieben werden.',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<b>Inxmail</b>',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Diese Website nutzt Inxmail für den Versand von Newslettern. Anbieter ist die Inxmail GmbH, Wetzinger Straße 17, 79106 Freiburg (nachfolgend Inxmail).',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Inxmail ist ein Dienst, mit dem u.a. der Versand von Newslettern organisiert und analysiert werden kann. Die von Ihnen zum Zwecke des Newsletterbezugs eingegeben Daten werden auf den Servern von Inxmail verarbeitet.',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<i>Datenanalyse durch Inxmail</i>',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Mit Hilfe von Inxmail ist es uns möglich, unsere Newsletter-Kampagnen zu analysieren. So können wir z. B. sehen, ob eine Newsletter-Nachricht geöffnet wurde und welche Links ggf. angeklickt wurden. Auf diese Weise können wir u.a. feststellen, welche Links besonders oft angeklickt wurden. Außerdem können wir erkennen, ob nach dem Öffnen / Anklicken bestimmte vorher definierte Aktionen durchgeführt wurden (Conversion-Rate). Wir können so z. B. erkennen, ob Sie nach dem Anklicken des Newsletters einen Kauf getätigt haben. Inxmail ermöglicht es uns auch, die Newsletter-Empfänger anhand verschiedener Kategorien zu unterteilen („clustern”). Dabei lassen sich die Newsletterempfänger z. B. nach Alter, Geschlecht oder Wohnort unterteilen. Auf diese Weise lassen sich die Newsletter besser an die jeweiligen Zielgruppen anpassen. Wenn Sie keine Analyse durch Inxmail wollen, müssen Sie den Newsletter abbestellen. Hierfür stellen wir in jeder Newsletternachricht einen entsprechenden Link zur Verfügung. Die Datenschutzerklärung von Inxmail finden Sie unter: https://www.inxmail.de/datenschutz .',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<i>Anonymisiertes Tracking</i>',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Wir verwenden das anonymisierte Tracking von Inxmail, bei dem ein Rückschluss auf Ihre Person nur dann möglich ist, wenn Sie vorab ausdrücklich hierin eingewilligt haben.',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<i>Rechtsgrundlage</i>',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Die Datenverarbeitung erfolgt auf Grundlage Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO). Sie können diese Einwilligung jederzeit für die Zukunft widerrufen.',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<i>Speicherdauer</i>',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Die von Ihnen zum Zwecke des Newsletter-Bezugs bei uns hinterlegten Daten werden von uns bis zu Ihrer Austragung aus dem Newsletter bei uns bzw. dem Newsletterdiensteanbieter gespeichert und nach der Abbestellung des Newsletters aus der Newsletterverteilerliste oder nach Zweckfortfall gelöscht. Wir behalten uns vor, E-Mail-Adressen aus unserem Newsletterverteiler nach eigenem Ermessen im Rahmen unseres berechtigten Interesses nach Art. 6 Abs. 1 lit. f DSGVO zu löschen oder zu sperren. Daten, die zu anderen Zwecken bei uns gespeichert wurden bleiben hiervon unberührt.',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Nach Ihrer Austragung aus der Newsletterverteilerliste wird Ihre E-Mail-Adresse bei uns bzw. dem Newsletterdiensteanbieter ggf. in einer Blacklist gespeichert, sofern dies zur Verhinderung künftiger Mailings erforderlich ist. Die Daten aus der Blacklist werden nur für diesen Zweck verwendet und nicht mit anderen Daten zusammengeführt. Dies dient sowohl Ihrem Interesse als auch unserem Interesse an der Einhaltung der gesetzlichen Vorgaben beim Versand von Newslettern (berechtigtes Interesse im Sinne des Art. 6 Abs. 1 lit. f DSGVO). Die Speicherung in der Blacklist ist zeitlich nicht befristet. Sie können der Speicherung widersprechen, sofern Ihre Interessen unser berechtigtes Interesse',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: ' &nbsp; ',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: '<i>Auftragsverarbeitung</i>',
        },

    },
    {
      type: 'paragraph',
      data:
        {
          text: 'Wir haben einen Vertrag über Auftragsverarbeitung (AVV) zur Nutzung des oben genannten Dienstes geschlossen. Hierbei handelt es sich um einen datenschutzrechtlich vorgeschriebenen Vertrag, der gewährleistet, dass dieser die personenbezogenen Daten unserer Websitebesucher nur nach unseren Weisungen und unter Einhaltung der DSGVO verarbeitet. 11 / 12 HTML-Quellcode der Datenschutzerklärung für Ihre Website Den nachfolgenden Quellcode können Sie kopieren, um die Datenschutzerklärung auf Ihrer Website einzufügen. Hinweis: Einige Browser und PDF-Reader haben Probleme bei der vollständigen Darstellung des nachfolgenden HTML-Quellcodes. Wir empfehlen Ihnen für die Anzeige dieses PDF-Dokuments die Nutzung des kostenlosen Adobe Acrobat Reader DC (Download). Powered by TCPDF (www.tcpdf.org) 12 / 12',
        },

    },
  ]
}
