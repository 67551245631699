// Import Dependencies
import React, {Dispatch, SetStateAction} from 'react';
import {useLocation} from "react-router";
import {ContentContainer, NewsletterContainer, CheckerCtaSvg, ContentWrapper, NewsletterButtonSvg} from './style';
import {Wrapper} from '../../../styles/partials/SwiperWrapper';
import CheckerContent from "../../components/CheckerContent";
import NewsletterButton from "../../components/NewsletterButton";
import CheckerCtaBackgroundSvg from '../../../assets/quarterElypsis.svg';
import NewsletterBackgroundSvg from '../../../components/components/NewsletterButton/assets/quarterElypsisTop.svg';

interface StageProps {
    backgroundLg?: string;
    backgroundMd?: string;
    backgroundXs?: string;
    setOpenDialog: Dispatch<SetStateAction<boolean>>;
}

export const Stage = ({backgroundLg, backgroundMd, backgroundXs, setOpenDialog}: StageProps) => {
    const location = useLocation();

    function handleClick() {
        setOpenDialog(true);
    }

    return (
        <>
            {location.pathname !== "/" ?
                <Wrapper
                    container
                    justify="center"
                    backgroundLg={backgroundLg}
                    backgroundMd={backgroundMd}
                    backgroundXs={backgroundXs}
                >
                </Wrapper> :
                <ContentWrapper
                    container
                    justify="center"
                    backgroundLg={backgroundLg}
                    backgroundMd={backgroundMd}
                    backgroundXs={backgroundXs}
                >
                    <ContentContainer>
                        <CheckerCtaSvg src={CheckerCtaBackgroundSvg}/>
                        <CheckerContent/>
                    </ContentContainer>
                </ContentWrapper>
            }
        </>
    )
}
