import React from 'react';
import { ButtonMapper } from './partials/ButtonMapper';
import { BackgroundMapper } from './partials/BackgroundMapper';
import { IntroOutro } from './partials/IntroOutro';
import { Timestamps } from './partials/TimestampMapper';

export const SliderMapper = (obj: any) => {
  const mapped = {
    type: 'slider',
    ...Timestamps(obj),
    ...BackgroundMapper(obj),
    ...IntroOutro(obj),
    button: ButtonMapper(obj),
    slides: [
      {
        label: obj.tabLabel1,
        card: {
          title: obj.title1,
          subtitle: obj.subtitle1,
          text: obj.text1,
          img: obj.img1,
          imgXs: obj.img1Xs,
          button: [
            {
              title: obj.buttonSlideText11,
              link: obj.buttonSlideLink11,
              color: obj.buttonSlideColor11,
            },
            {
              title: obj.buttonSlideText12,
              link: obj.buttonSlideLink12,
              color: obj.buttonSlideColor12,
            },
          ],
        },
      },
      {
        label: obj.tabLabel2,
        card: {
          title: obj.title2,
          subtitle: obj.subtitle2,
          text: obj.text2,
          img: obj.img2,
          imgXs: obj.img2Xs,
          button: [
            {
              title: obj.buttonSlideText21,
              link: obj.buttonSlideLink21,
              color: obj.buttonSlideColor21,
            },
            {
              title: obj.buttonSlideText22,
              link: obj.buttonSlideLink22,
              color: obj.buttonSlideColor22,
            },
          ],
        },
      },
    ],
  };

  function filterArrayValues(arr: any) {
    arr = arr.filter(isEligible);
    return arr;
  }

  function isEligible(value: any) {
    if (value.title !== null) {
      return value;
    }
  }
  mapped.slides[0].card.button = filterArrayValues(
    mapped.slides[0].card.button,
  );
  mapped.slides[1].card.button = filterArrayValues(
    mapped.slides[1].card.button,
  );

  return mapped;
};
