import React from 'react';
import { Grid } from '@material-ui/core';
import Introduction from '../partials/Introduction';
import Closure from '../partials/Closure';
import smile from '../../assets/smile-pflegepartner-tuerkis-40-prozent.svg';
import {
  OuterWrapper,
  Cite,
  Img,
  InnerWrapper,
  Name,
  Smiley,
} from '../../styles/components/ZitatComponent';

interface PropsInterface {
  background: { type: string; url: string };
  title?: string;
  subtitle?: string;
  introduction?: any;
  closing?: any;
  buttons?: { title: string; color: string; url: any; func?: () => void }[];
  name?: string;
  text?: string;
  img?: any;
  isBlog?: boolean;
}
const ZitatComponent = ({
  background,
  title,
  subtitle,
  introduction,
  name,
  img,
  text,
  closing,
  buttons,
  isBlog,
}: PropsInterface) => (
  <OuterWrapper
    container
    alignItems="center"
    direction="column"
    background={background}
    isBlog={isBlog}
  >
    <InnerWrapper>
      {(title || subtitle || introduction) && (
        <Introduction
          title={title}
          subtitle={subtitle}
          introduction={introduction}
        />
      )}
      <Grid container xs={12} direction="column" alignItems="center">
        {img && <Img src={img} alt="image" />}
        {text && (
        <Cite item>
          "
          {text}
          "
        </Cite>
        )}
        {name && (
          <Smiley>
            <Name>{name}</Name>
            <img src={smile} alt="image" width={100} height={20} />
          </Smiley>
        )}
      </Grid>
      {closing && buttons && (
        <Closure buttons={buttons} closing={closing} buttonsAlign="left" />
      )}
    </InnerWrapper>
  </OuterWrapper>
);

export default ZitatComponent;
