import { makeVar, ReactiveVar } from '@apollo/client';
import { InPageNavMapper } from '../../utils/mapper/InPageNavigation';
import { ProcessMapper } from '../../utils/mapper/ProcessMapper';
import { GraphicMapper } from '../../utils/mapper/GraphicMapper';
import { CardMapper } from '../../utils/mapper/CardMapper';
import { SliderMapper } from '../../utils/mapper/SliderMapper';
import { IconsMapper } from '../../utils/mapper/IconsMapper';
import { TextMapper } from '../../utils/mapper/TextMapper';

export const WEGE_LEITEN = {
  listInPageNavigation1s: {
    read(value: any) {
      if (value !== undefined) {
        return WegeLeiten({
          ...WegeLeiten(),
          nav: value.data.map((data: any) => InPageNavMapper(data)),
        });
      }
    },
  },
  listProzessKomponentes: {
    read(value: any) {
      if (value !== undefined) {
        return WegeLeiten({
          ...WegeLeiten(),
          process: value.data.map((data: any) => ProcessMapper(data)),
        });
      }
    },
  },
  listWlTextKomponentes: {
    read(value: any) {
      if (value !== undefined) {
        return WegeLeiten({
          ...WegeLeiten(),
          textNew: value.data.map((data:any) => TextMapper(data)),
        });
      }
    },
  },
  listPflegeOrganisierens: {
    read(value: any) {
      if (value !== undefined) {
        return WegeLeiten({
          ...WegeLeiten(),
          graphic: value.data.map((data: any) => GraphicMapper(data)),
        });
      }
    },
  },
  listKachelKomponente1s: {
    read(value: any) {
      if (value !== undefined) {
        return WegeLeiten({
          ...WegeLeiten(),
          cards: value.data.map((data: any) => CardMapper(data)),
        });
      }
    },
  },
  listSliderKomponentes: {
    read(value: any) {
      if (value !== undefined) {
        return WegeLeiten({
          ...WegeLeiten(),
          slider: value.data.map((data: any) => SliderMapper(data)),
        });
      }
    },
  },
  listIconKomponentes: {
    read(value: any) {
      if (value !== undefined) {
        return WegeLeiten({
          ...WegeLeiten(),
          icon: value.data.map((data: any) => IconsMapper(data)),
        });
      }
    },
  },
};
export const WegeLeiten: ReactiveVar<any> = makeVar<any>({
  nav: {},
  process: {},
  graphic: {},
  cards: {},
  slider: {},
  icon: {},
  textNew: {},
  isLoading: true,
});
