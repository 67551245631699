export const ButtonMapper = (obj: any) => {
  let TmpObj = [
    {
      title: obj.buttonText1,
      color: obj.buttonColor1,
      url: obj.buttonLink1,
      datatype: "inbound",
    },
    {
      title: obj.buttonText2,
      color: obj.buttonColor2,
      url: obj.buttonLink2,
      datatype: "inbound",
    },
  ];
  function filterArrayValues(arr: any) {
    arr = arr.filter(isEligible);
    return arr;
  }

  function isEligible(value: any) {
    if (value.title !== null || value.color !== null) {
      return value;
    }
  }

  TmpObj = filterArrayValues(TmpObj);
  return TmpObj;
};
