import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import footerLg from '../../assets/footer-lg.svg';
import {
  colors, Fonts, MDBreakPoint, XSBreakPoint,
} from '../../Theme';
import footerXs from '../../assets/footer-xs.svg';

export const Wrapper = styled(Grid)`
  background: #69d2e6;
  padding: 50px 0;
  height: 100%;
  background-image: url(${footerLg});
  background-repeat: no-repeat;
  background-size: cover;
  @media (max-width: ${MDBreakPoint}) {
    background-image: url(${footerXs});
    background-size: cover;
  }
  @media (max-width: ${XSBreakPoint}) {
    background-image: url(${footerXs});
    background-size: contain, cover;
    background-position: bottom;
  }
`;

export const ContentContainer = styled(Grid)`
  max-width: 1100px !important;
  @media (max-width: ${MDBreakPoint}) {
    padding-left: 15px;
  }
  @media (max-width: ${XSBreakPoint}) {
  }
`;

export const FirstRow = styled(Grid)`
  padding: 20px;
`;
export const SecondRow = styled(Grid)`
  padding: 20px;
  color: ${colors.darkBlack};
  font: ${Fonts.fontText};

  @media (max-width: ${XSBreakPoint}) {
    font: ${Fonts.fontTextMobile};
    align-items: flex-start !important;
    margin-top: 16px !important;
    padding-bottom: 10px;
  }
`;
export const SecondRowBedarf = styled(Grid)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  @media (max-width: ${XSBreakPoint}) {
    flex-direction: column;
    padding-left: 16px;
    align-items: flex-start;
  }
`;

export const LinkContainer = styled(Grid)``;

export const MobileRow = styled(Grid)`
  display: none !important;
  @media (max-width: ${XSBreakPoint}) {
    display: block !important;
    padding-bottom: 10px;
  }
`;

export const DisplayRow = styled(Grid)`
  display: block;
  @media (max-width: ${XSBreakPoint}) {
    display: none !important;
  }
`;

export const LogoContainer = styled(Grid)`
  padding: 20px;
`;
export const FirstImage = styled.img`
  width: 150px;
  height: 90px;
  margin-top: 5px;
`;

export const SecondImage = styled.img`
  width: 150px;
  height: 90px;
  margin-top: 5px;
`;

export const TextContainer = styled(Grid)`
  font: ${Fonts.fontText};
  color: ${colors.darkBlack};
`;
export const StyledLink = styled.p`
  text-decoration: none;
  color: ${colors.darkBlue};
  font: ${Fonts.fontFooterLinks};
  margin: 0 !important;
  :hover {
    cursor: pointer;
  }
  @media (max-width: ${XSBreakPoint}) {
    font: ${Fonts.fontFooterLinksMobile};
    margin: 0 !important;
  }
`;

export const LogoLg = styled(Grid)`
  display: inline-block;
  padding-left: 25px;
  @media (max-width: ${MDBreakPoint}) {
    padding-left: 27px;
  }
  @media (max-width: ${XSBreakPoint}) {
    display: none;
  }
`;

export const LogoXs = styled(Grid)`
  display: none;
  @media (max-width: ${XSBreakPoint}) {
    display: inline-block;
  }
`;

export const URL = styled.a`
  text-decoration: none;
  color: ${colors.darkBlue};
  font: ${Fonts.fontFooterLinks};
  :hover {
    cursor: pointer;
  }

  @media (max-width: ${XSBreakPoint}) {
    font: ${Fonts.fontFooterLinksMobile};
  }
`;

// Footer for Bedarfscheck Page
export const WrapperBedarf = styled(Grid)`
  width: 100%;
  height: auto;
  background: white;
`;
export const ContentWrapper = styled(Grid)`
  max-width: 588px;
  height: auto;
  margin: 0 auto;
  padding: 20px 0;
  align-items: center;
`;

export const LinkContainerBedarf = styled(Grid)`
  display: flex;
  justify-content: space-between;
`;

export const LinkWrapper = styled(Grid)`
  flex-direction: row;

  @media (max-width: ${XSBreakPoint}) {
    flex-direction: column;
    padding-left: 16px;
  }
`;

export const LinkBedarf = styled.p`
  text-decoration: none;
  margin: 0 !important;
  color: ${colors.darkBlue};
  font-size: 12px;
  font-weight: 500;
  line-height: 32px;
  :hover {
    cursor: pointer;
  }
  @media (max-width: ${XSBreakPoint}) {
    margin: 0 !important;
      font-size: 12px !important;
  }
`;

export const LogoSparkasse = styled.img`
  width: 90px;
  height: fit-content;
`;

export const Text = styled.p`
  color: #222222;
  font-weight: 500;
  font-size: 12px !important;
  margin: 12px 0 !important;
`;
