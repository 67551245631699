import star from "../assets/Categories/star.png";
import euro from "../assets/Categories/euro.png";
import zuhause from "../assets/Categories/zuhause.png";
import heim from "../assets/Categories/heim.png";
import diagnose from "../assets/Categories/diagnose.png";

export const categories = [
  { name: "Neueste Artikel", icon: star },
  { name: "Pflege finanzieren", icon: euro },
  { name: "Pflege Zu Hause", icon: zuhause },
  { name: "Pflege im Heim", icon: heim },
  { name: "Diagnose", icon: diagnose },
];

export const dashboardStage = {
  title: "Pflege-Blog",
  backgroundLg: "",
  backgroundMd: "",
  backgroundXs: "",
  text: [
    {
      type: "paragraph",
      children: [
        {
          text:
            "In unserem Pflege-Blog veröffentlichen wir regelmäßig Artikel zu wesentlichen Themen der Pflege. Wir stellen Ihnen Menschen und ihre Geschichten vor, lassen Experten zu Wort kommen und bereiten wichtige Informationen sowie aktuelle Entwicklungen in der Pflege auf. Mit Hintergrundberichten, Lebensgeschichten oder auch Fachbeiträgen wollen wir Sie ermutigen, aufklären und inspirieren.",
        },
      ],
    },
  ],
};

export const emptyCat =
  "Genau in diesem Augenblick arbeiten wir an dem nächsten Artikel für diese Kategorie. In den anderen ThemenFeldern finden Sie ansonsten ebenfalls spannende Geschichten und hilfreiche Tipps.";
export const emptyArt =
  "Diese Seite befindet sich aktuell in Bearbeitung und wird bald für Sie komplett zur Verfügung stehen. Schauen Sie in der Zwischenzeit auf einem unserer anderen Blogartikeln vorbei.";

export const BlogTitle = "Welche Kategorie soll angezeigt werden?";
