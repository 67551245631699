export const TABS = `
  img1,
  img2,
  img3,
  text1,
  text2,
  text3,
  title1,
  title2,
  title3,
`;
