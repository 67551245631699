// Import Dependencies
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Button } from '../../styles/cta/BlueButton';
import { NavigationItems } from '../../mocks/mock';
import { getButtonLinkType } from '../../utils/ButtonTargetParser';
// Import Components

// Import Variables

interface ButtonProps {
  text: string;
  border: boolean;
  url: string;
  datatype: 'outbound' | 'internal';
  fullWidth?: boolean;
  func?: () => void;
  setNavigationIndex?: (index: number) => void;
}

export const BlueButton = ({
  text,
  border,
  fullWidth,
  url,
  func,
  datatype,
  setNavigationIndex,
}: ButtonProps) => {
  const history = useHistory();

  datatype = getButtonLinkType(url);

  const goTo = (name: string, height: number) => {
    if (name.includes('bedarfscheck')) {
      window.open('https://pflegepartner.de/bedarfscheck', '_blank');
    } else {
      const splitted = name.split(':');
      if (splitted[0] === 'mailto') {
        const mail = document.createElement('a');
        mail.href = name;
        mail.click();
      } else if (datatype === 'outbound') {
        window.open(url, '_blank');
      } else {
        setTimeout(() => {
          window.scrollTo(0, height);
        }, 2);
        const index = NavigationItems.findIndex((value) => value.to === url.split('#')[0]);
        if (index && setNavigationIndex) {
          setNavigationIndex(index);
        }
        if (name.split('#').length === 2) {
          history.push(`${name}`);
        } else {
          history.push(name);
        }
      }
    }
  };

  return (
    <>
      <Button
        type="submit"
        border={border}
        fullWidth={fullWidth}
        datatype={`${datatype}Button`}
        onClick={() => (func ? func() : goTo(url, 0))}
      >
        <a href={url} target={datatype === 'outbound' ? '_blank' : '_self'} style={{ display: 'none' }} />
        {text}
      </Button>
    </>
  );
};
