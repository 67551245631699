import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { MDBreakPoint, XSBreakPoint } from '../../Theme';

export const Wrapper = styled(Grid)`
  width: 100%;
  height: auto;
  background: #E0F5F9;
`;

export const ContentWrapper = styled(Grid)`
  max-width: 588px;
  height: 112px;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  position: relative;
  
  @media (max-width: ${MDBreakPoint}) {
    height: 82px;
  }
  
  @media (max-width: ${XSBreakPoint}) {
    height: 62px;
  }
`;

export const Image = styled.img`
  width: 81.05px;
  height: auto;
  float: left;
  position: absolute;
  left: 0px;
  
  @media (max-width: ${MDBreakPoint}) {
    width: 62.5px;
  }
  
  @media (max-width: ${XSBreakPoint}) {
    width: 37.74px;
    margin-left: 16px;
  }
`;

export const Title = styled.h2`
  color: #002864;
  font-size: 26px !important;
  
  @media (max-width: ${MDBreakPoint}) {
    font-size: 23px !important;
  }
  
  @media (max-width: ${XSBreakPoint}) {
    font-size: 20px !important;
  }
`;
