import { ButtonMapper } from "./partials/ButtonMapper";
import { BackgroundMapper } from "./partials/BackgroundMapper";
import { IntroOutro } from "./partials/IntroOutro";
import { Timestamps } from "./partials/TimestampMapper";

export const TabsMapper = (obj: any) => {
  const mapped = {
    type: "tabs",
    ...Timestamps(obj),
    ...BackgroundMapper(obj),
    ...IntroOutro(obj),
    buttons: ButtonMapper(obj),
    tabs: [
      {
        icon: obj.img1,
        title: obj.title1,
        text: obj.text1,
      },
      {
        icon: obj.img2,
        title: obj.title2,
        text: obj.text2,
      },
      {
        icon: obj.img3,
        title: obj.title3,
        text: obj.text3,
      },
    ],
  };

  function filterArrayValues(arr: any) {
    arr = arr.filter(isEligible);
    return arr;
  }

  function isEligible(value: any) {
    if (value.icon !== null || value.title !== null) {
      return value;
    }
  }
  mapped.tabs = filterArrayValues(mapped.tabs);

  return mapped;
};
