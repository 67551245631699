import React from 'react';
import CheckIcon from '@material-ui/icons/Check';
import { decorateText } from '../../utils/RichTextConverter';
import {
  ListContainer, ListItem, Ol, Ul,
} from '../../styles/partials/List';
import { WebinyList } from '../../utils/interfaces';

interface ListInterface {
  sort: 'ul' | 'ol';
  elements: WebinyList;
}

const List = ({ sort, elements }: ListInterface) => (sort === 'ul' ? (
  <Ul>
    {elements?.data.items.map((list: string, index: number) => (
      <ListContainer key={index}>
        <CheckIcon
          style={{
            fontSize: 30,
            position: 'relative',
            marginRight: 10,
            marginLeft: 10,
            fontWeight: 200,
            color: '#002864',
          }}
        />
        <ListItem>{decorateText(list)}</ListItem>
      </ListContainer>
    ))}
  </Ul>
) : (
  <Ol>
    {elements.data.items.map((item: string, index: number) => (
      <li key={index}>
        {decorateText(item, true)}
      </li>
    ))}
  </Ol>
));

export default List;
