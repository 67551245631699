import ukv from "../assets/footer-ukv.svg";
import vkb from "../assets/footer-vkb.svg";

export const NavigationItems = [
  {
    to: "/",
    text: "Pflege in die Wege leiten",
    background: {},
  },
  {
    to: "/pflege-finanzieren",
    text: "Pflege finanzieren",
    background: {},
  },
  {
    to: "/pflege-zu-hause",
    text: "Pflege zu Hause",
    background: {},
  },
  {
    to: "/ueber-uns",
    text: "Über uns",
    background: {},
  },
  {
    to: "/blog",
    text: "Blog",
    background: {},
  },
];

export const NavigationLogin = {
  text: "Mein PflegePartner",
  url:
    "https://anmeldung.vkb.de/auth/realms/pflegepartner-test/protocol/openid-connect/auth?client_id=pflegeportal-frontend&redirect_uri=https%3A%2F%2Fpflegepartner.vkb.de%2Fppd%2F%23%2Fvkb%2Fportal%2Fdashboard&state=35616bd7-814e-4fdb-89fb-1ddbd1290693&nonce=03b9fc4e-9db1-4733-9b89-154d90881b0a&response_mode=fragment&response_type=code&scope=openid&login_hint=vkb",
};

export const footerObj = {
  ukv: { type: "image", url: ukv },
  vkb: { type: "image", url: vkb },
  firstColumn: NavigationItems,
  secondColumn: [
    {
      to:"/privacy-policy",
      text: "Datenschutz-Hinweise",
      background: {},
    },
    {
      to: "/compliance",
      text: "Compliance-Hinweise",
      background: {},
    },
    {
      to: "/impressum",
      text: "Impressum",
      background: {},
    },
    {
      to: "/",
      text: "Cookie-Einstellungen",
      background: {},
    },
  ],
  text: "© PflegePartner 2021",
  cooperation: "Eine Kooperation mit:",
};
