import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';
import {
  XSBreakPoint,
} from '../../Theme';

export const Wrapper = styled(Grid)`
  height: 498px;
  width: 182px;
  background: #0082FF;
  position: fixed;
  right: 0;
  top: calc(50vh - 250px);
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  display: flex;
  flex-direction: column;
  z-index: 2;
  box-shadow: -5px 5px 15px #00000026;
  
  @media (max-width: 1919px) {
    display: none;
  }
  
  // @media (max-width: ${XSBreakPoint}){
  //   display: none;
  // } 
`;

export const LogoWrapper = styled(Grid)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 112px;
`;

export const Logo = styled.img`
  height: auto;
  width: 80px;
  border: 3px solid white;
  border-radius: 50%;
  background: #F8F8F8;
`;

export const TitleWrapper = styled(Grid)`
  width: 166px;
  background: white;
  height: 66px;
  align-self: flex-start;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  color: #0082FF;
  display: flex;
  align-items: center;
`;

export const Title = styled.h5`
  color: #0082FF;
  font-size: 18px !important;
  margin: 0 0 0 16px !important;
  width: auto;
  line-height: 27px !important;
  
`;

export const Description = styled.p`
  width: 150px;
  margin: 16px 0 !important;
  height: 211px;
  align-self: center;
  color: white;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
`;

export const CtaWrapper = styled(Grid)`
  border-bottom-left-radius: 10px;
  margin: 8px auto 16px auto !important;
  display: flex;
  width: 150px;
  justify-content: center;
  margin: 0 auto;
  display: flex;
  text-align: center;
`;

export const LinkWrapper = styled(Link)`
  width: 100%;
  border-radius: 100px;
  text-decoration: none;
  z-index: 1;
`;

export const Cta = styled.h5`
  font-size: 18px !important;
  font-weight: 600 !important;
  text-decoration: none;
  color: white;

  height: 53px;
  cursor: pointer;
  background: transparent;
  padding: 10px 30px;
  border: 3px solid white;
  border-radius: 100px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px !important;
`;
