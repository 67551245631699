// Import Dependencies
import React, { Dispatch, useState } from 'react';
import { Grid } from '@material-ui/core';
// Import Components
import ClickableText from '../partials/ClickableText';
import { TextChecker } from '../../utils/RichTextConverter';
// Import Variabels
import SlidableTabs from '../partials/SlidableTabs';
import SwiperWrapper from '../partials/SwiperWrapper';
import Closure from '../partials/Closure';
import Introduction from '../partials/Introduction';
import {
  CareItem,
  CenteredDiv,
  DesktopView,
  InnerWrapper,
  MDContainer,
  MobileContainer,
  StyledGrid,
  StyledInnerText,
  useStyles,
  Wrapper,
} from '../../styles/components/GraphicComponent';

interface GraphicProps {
  title: string;
  subtitle: string;
  background: { type: string; url: string };
  introduction: any;
  upper: {
    icon: string;
    text: string;
    key: string;
    background: { src: string; alt: string; location: string };
  }[];
  lower: {
    icon: string;
    text: string;
    key: string;
    background: { src: string; alt: string; location: string };
  }[];
  content: any;
  closing?: any;
  button?: { title: string; color: string; url: string }[];
  setNavigationIndex?: Dispatch<number>
}

function GraphicComponent({
  background,
  title,
  subtitle,
  introduction,
  upper,
  lower,
  content,
  closing,
  button,
  setNavigationIndex,
}: GraphicProps) {
  const [value, setValue] = useState(0);
  const tabs = [
    ...upper.map((element) => ({
      text: element.text,
      icon: element.icon,
      content: content[element.key],
    })),
    ...lower.map((element) => ({
      text: element.text,
      icon: element.icon,
      content: content[element.key],
    })),
  ];

  const useClickHandler = (text: any) => {
    setValue(text);
  };
  const classes = useStyles();
  return (
    <Wrapper
      background={background}
      justify="center"
      direction="column"
      alignItems="center"
      container
      xs={12}
      className="abc1"
    >
      <InnerWrapper>
        <Introduction
          title={title}
          subtitle={subtitle}
          introduction={introduction}
          alignText="left"
        />
      </InnerWrapper>
      <DesktopView>
        <StyledGrid container item direction="row" justify="center">
          {upper.map((item, index) => (
            <CareItem
              datatype="iconNavigation"
              key={index}
              container
              item
              direction="row"
              alignItems="center"
              justify="center"
              xs={12}
              lg={4}
              classes={{ root: classes.root }}
              style={{
                marginBottom: '1px',
                fontWeight: 600,
              }}
            >
              <ClickableText
                item={item}
                value={value}
                clickHandler={useClickHandler}
                index={item.key}
                background={item.background}
              />
            </CareItem>
          ))}
        </StyledGrid>
        <CenteredDiv>
          <Grid container direction="row" justify="center" alignItems="center">
            <StyledInnerText>{TextChecker(content[value])}</StyledInnerText>
          </Grid>
        </CenteredDiv>
        <Grid
          container
          item
          direction="row"
          alignItems="center"
          justify="center"
          style={{ margin: '0 auto' }}
        >
          {lower.map((item, index) => (
            <CareItem
              datatype="iconNavigation"
              key={index}
              container
              item
              classes={{ root: classes.root }}
              direction="row"
              alignItems="center"
              justify="center"
              xs={12}
              lg={4}
              spacing={4}
              style={{
                marginTop: '1px',
                marginBottom: '20px',
                fontWeight: 600,
              }}
            >
              <ClickableText
                item={item}
                value={value}
                clickHandler={useClickHandler}
                index={item.key}
                background={item.background}
              />
            </CareItem>
          ))}
        </Grid>
      </DesktopView>
      <MDContainer>
        <SlidableTabs items={tabs} />
      </MDContainer>
      <MobileContainer>
        <SwiperWrapper elements={tabs} isTabs isImage />
      </MobileContainer>
      <Closure buttons={button} closing={closing} buttonsAlign="center" setNavigationIndex={setNavigationIndex} />
    </Wrapper>
  );
}

export default GraphicComponent;
