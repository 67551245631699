import React from 'react';
import {
  ContentLoaderStyled,
  Wrapper,
} from '../../styles/loaders/NavigationPlaceholder';

export const NavPlaceholder = () => (
  <Wrapper>
    <ContentLoaderStyled>
      <rect x="0" y="40" rx="3" ry="3" width="100" height="100" />
      <rect x="160" y="60" rx="3" ry="3" width="180" height="50" />
      <rect x="360" y="60" rx="3" ry="3" width="180" height="50" />
      <rect x="560" y="60" rx="3" ry="3" width="180" height="50" />
      <rect x="760" y="60" rx="3" ry="3" width="180" height="50" />
      <rect x="1000" y="40" rx="3" ry="3" width="100" height="100" />
    </ContentLoaderStyled>
  </Wrapper>
);
