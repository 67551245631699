// Import Dependencies
import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import styled from 'styled-components';
// Import Components
import ClickableText from '../partials/ClickableText';
// Import Variables
import { NavigationItems, NavigationLogin } from '../../mocks/mock';
import logo from '../../assets/logo-pflegepartner-dunkelblau-tuerkis.svg';
import { NavPlaceholder } from '../loaders/NavigationPlaceholder';
import {
  BorderBottom,
  Button,
  GridItem,
  ImageWrapper,
  InnerWrapper,
  Sub,
  Upper,
  Wrapper,
  Image,
} from '../../styles/components/Navigation';

interface Props {
  handleZip: any;
}
const LoginButton = ({ handleZip }: Props) => (
  <Button onClick={() => handleZip()}>
    <Upper> Login </Upper>
    <br />
    <Sub>{NavigationLogin.text}</Sub>
  </Button>
);

interface NavProps {
  loading: boolean;
  value: number;
  navigationElements: any;
  setValue: any;
  handleOpenOpenZip: () => void;
  zipFunc: {
    setTitle: any;
    setText: any;
    setButtonText: any;
    setUkv: any;
    setVkb: any;
  };
}

const GridContainer = styled(Grid)`
  padding-top: 20px;
`;

const Navigation = ({
  loading,
  value,
  setValue,
  zipFunc,
  handleOpenOpenZip,
  navigationElements,
}: NavProps) => {
  const history = useHistory();
  navigationElements = Object.values(navigationElements);
  navigationElements.push('Blog');
  !loading
    && NavigationItems.forEach(
      (item, index) => (item.text = navigationElements[index + 1]),
    );
  /*
   * This Function checks after a F5 Reload which tab is currently open and set  the Value to the specific
   * key within the NavItem Array.
   * The return is for supressing the error message
   */
  useEffect(() => {
    if (history.location.pathname !== NavigationItems[value].to) {
      NavigationItems.filter((item, index) => {
        if (history.location.pathname === item.to) {
          setValue(index);
        }
        return index;
      });
    }
  });
  const handleChange = (newValue: number) => {
    history.push(NavigationItems[newValue].to);
    setValue(newValue);
  };

  const handleZip = () => {
    if (zipFunc) {
      zipFunc.setTitle('Zum Login');
      zipFunc.setText(
        '„Mein PflegePartner” bieten wir gemeinsam mit unseren Partnern an. Um fortzufahren, geben Sie bitte Ihre Postleitzahl ein:',
      );
      zipFunc.setButtonText('Senden');
      zipFunc.setUkv(
        'https://anmeldung.vkb.de/auth/realms/pflegepartner-test/protocol/openid-connect/auth?client_id=pflegeportal-frontend&redirect_uri=https%3A%2F%2Fpflegepartner.ukv.de%2Fppd%2F%23%2Fukv%2Fportal%2Fdashboard&state=b6b7dfb2-75d0-441c-844a-7bc1a40feaa0&nonce=0ebeb72a-6a33-4fc4-99d5-086b5a38b1cc&response_mode=fragment&response_type=code&scope=openid&login_hint=ukv',
      );
      zipFunc.setVkb(
        'https://anmeldung.vkb.de/auth/realms/pflegepartner-test/protocol/openid-connect/auth?client_id=pflegeportal-frontend&redirect_uri=https%3A%2F%2Fpflegepartner.vkb.de%2Fppd%2F%23%2Fvkb%2Fportal%2Fdashboard&state=be2d4501-836d-4972-8c69-b2f51f233638&nonce=89f75c9c-688c-48c5-8b39-d6dfb8d93435&response_mode=fragment&response_type=code&scope=openid&login_hint=vkb',
      );
      handleOpenOpenZip();
    }
  };
  return loading ? (
    <NavPlaceholder />
  ) : (
    <Wrapper container>
      <GridContainer container justify="center">
        <ImageWrapper onClick={() => handleChange(0)}>
          <Image src={logo} alt="image" />
        </ImageWrapper>
        <InnerWrapper>
          <Grid container>
            {NavigationItems.map((item: any, index: number) => (
              <GridItem>
                <a key={index} href={item.to} style={{ all: 'unset', display: 'none' }} />
                <ClickableText
                  item={item}
                  value={value}
                  clickHandler={handleChange}
                  index={index}
                  background={{}}
                  isMainPage
                />
              </GridItem>
            ))}
            <BorderBottom />
          </Grid>
        </InnerWrapper>
      </GridContainer>
    </Wrapper>
  );
};

export default Navigation;
