export const INPAGE_NAVIGATION = ` 
        backgroundLg
        backgroundMd
        backgroundXs
        idNav1
        idNav2
        idNav3
        idNav4
        idNav5
        idNav6
        indexNav1
        indexNav2
        indexNav3
        indexNav4
        indexNav5
        indexNav6
        textNav1
        textNav2
        textNav3
        textNav4
        textNav5
        textNav6
        introduction
        title
`;
