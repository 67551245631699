// Config and setups
import { ApolloClient, HttpLink, NormalizedCacheObject } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import { cache } from './cache';

const link = new HttpLink({ uri: process.env.REACT_APP_API_URL });

const authLink = setContext((_, { headers }) => {
  const token = process.env.REACT_APP_API_TOKEN;
  return {
    headers: {
      ...headers,
      authorization: token ? `${token}` : '',
    },
  };
});

const client: ApolloClient<NormalizedCacheObject> = new ApolloClient({
  link: authLink.concat(link),
  credentials: 'same-origin',
  cache,
});

export default client;
