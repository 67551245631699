// Import
import React from 'react';
// Import Components
import { Grid } from '@material-ui/core';
import { BlueButton } from '../cta/BlueButton';
import { WhiteButton } from '../cta/WhiteButton';
import { Container, GridItem } from '../../styles/partials/ButtonsContainer';
// Import Variables

interface ButtonsProps {
  buttons: any;
  setNavigationIndex?: (index: number) => void;
}

const ButtonsContainer = ({ buttons, setNavigationIndex }: ButtonsProps) => (
  <>
    {buttons[0]?.title && (
      <Container>
        <Grid container justify="center">
          {buttons.map(
            (button: any, index: number) => {
              if(button?.title?.includes('registrieren')) return <></>;
              return button.title
              && (button.color === 'blue' ? (
                  <GridItem item key={index} last={buttons.length - 1 === index}>
                    <BlueButton
                        datatype={button.datatype}
                        text={button.title}
                        border={false}
                        url={button.url}
                        func={button?.func}
                        setNavigationIndex={setNavigationIndex}
                    />
                  </GridItem>
              ) : (
                  <GridItem key={index} last={buttons.length === index}>
                    <WhiteButton
                        text={button.title}
                        border={false}
                        url={button.url}
                        func={button?.func}
                        datatype={button.datatype}
                    />
                  </GridItem>
              ))
            }
          )}
        </Grid>
      </Container>
    )}
  </>
);
export default ButtonsContainer;
