// Import Dependencies
import React from 'react';
import {
  Wrapper,
  Logo, LogoWrapper, Title, TitleWrapper, Description, CtaWrapper, Cta, LinkWrapper,
} from '../../styles/partials/StickySidebar';
import Avatar from '../../assets/avatar.svg';

export const StickyComponent = () => (
  <Wrapper>
    <LogoWrapper>
      <Logo src={Avatar} />
    </LogoWrapper>
    <TitleWrapper>
      <Title>Pflegebedarfs-Check</Title>
    </TitleWrapper>
    <Description>
      Welche Möglichkeit funktioniert in Ihrer persönlichen Situation?
      <br />
      Unser Bedarfs-Check unterstützt Sie, den richtigen Weg für die Pflege zu finden.
    </Description>
    <CtaWrapper>
      <LinkWrapper to="/bedarfscheck" target="_blank">
        <Cta>
          Starten
        </Cta>
      </LinkWrapper>
    </CtaWrapper>
  </Wrapper>
);

export default StickyComponent;
