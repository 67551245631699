import styled from 'styled-components';
import Box from '@material-ui/core/Box';

import {
  colors, Fonts, MDBreakPoint, XSBreakPoint,
} from '../../Theme';

export const TextContainer = styled.div`
  // background: aqua;
  margin-left: 0 !important;
  margin-right: 10px;
  @media (max-width: ${XSBreakPoint}) {
    margin: 0;
    padding: 0 !important;
  }
`;
export const Title = styled.div`
  color: ${colors.darkBlue};
  font: ${Fonts.fontKalamBold};
  font-size: 31px;
  line-height: 40px !important;

  @media (max-width: ${MDBreakPoint}) {
    font-size: 26px !important;
    line-height: 34px !important;
  }
  @media (max-width: ${XSBreakPoint}) {
    text-align: center;
    font-size: 23px !important;
    line-height: 30px !important;
  }
`;
export const Subtitle = styled.div`
  color: ${colors.darkBlue};
  font: ${Fonts.fontIntroText};
  margin-bottom: 30px;
  font-size: 37px !important;
  line-height: 48px !important;

  @media (max-width: ${MDBreakPoint}) {
    font-size: 29px !important;
    line-height: 38px !important;
  }
  @media (max-width: ${XSBreakPoint}) {
    text-align: center;
    font-size: 26px !important;
    line-height: 34px !important;
  }
`;
export const Text = styled.div`
  font: ${Fonts.fontText};
  max-width: 800px;
  @media (max-width: ${XSBreakPoint}) {
    max-width: 1000px;
    padding: 0 16px;
    width: 100%;
  }
`;

export const Wrapper = styled.div``;
export const Desktop = styled.div`
  @media (max-width: ${XSBreakPoint}) {
    display: none;
  }
`;
export const Mobile = styled.div`
  display: none;
  @media (max-width: ${XSBreakPoint}) {
    display: block;
  }
`;
export const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
  @media (max-width: ${MDBreakPoint}) {
    max-height: 360px;
  }
`;

// @ts-ignore
export const StyledBox = styled(Box)`
  @media (max-width: ${XSBreakPoint}) {
    padding: 24px 0 0 0 !important;
  }
`;
