import styled, { css } from 'styled-components';
import { Grid } from '@material-ui/core';
import { BackgroundConverter } from '../../utils/BackgroundConverter';
import {
  colors, Fonts, MDBreakPoint, XSBreakPoint,
} from '../../Theme';
import { H2, H4 } from '../Styles';

interface WrapperProps {
  background: { type: string; url: string };
  isBlog?: boolean;
}

export const OuterWrapper = styled(Grid)<WrapperProps>`
  min-height: 350px;
  padding: ${({ isBlog }) => (isBlog ? '0 16px' : '50px 16px')};
  ${({ background }) => BackgroundConverter(background.type, background.url)}}
`;

export const InnerWrapper = styled.div`
  max-width: 1300px;
  display: block;
`;

export const Content = styled(Grid)<{ isWithImage?: boolean }>`
  ${(props) => !props.isWithImage
    && css`
      max-width: 1300px !important;
      @media (max-width: ${MDBreakPoint}) {
        width: 360px;
      }
    `};
`;

export const Title = styled(H2)`
  color: ${colors.darkBlue};
`;
export const Subtitle = styled(H4)`
  color: ${colors.darkBlue};
`;

export const Text = styled(Grid)`
  color: ${colors.black};
  font: ${Fonts.fontText};
  max-width: 1000px;
  @media (max-width: ${MDBreakPoint}) {
    width: 100%;
    max-width: 1000px;
  }
`;
export const Image = styled.img`
  max-width: 1000px;
  margin: 0 auto;
  width: 100%;
  text-align: center;
  display: block;
  @media (max-width: ${MDBreakPoint}) {
    display: none;
  }
`;
export const ImageXs = styled.img`
  display: none;
  @media (max-width: ${MDBreakPoint}) {
    display: block;
    width: 100%;
    padding-right: 0;
    height: auto;
  }
`;

export const LeftText = styled.span`
  width: 100%;
  color: ${colors.black};
  font: ${Fonts.fontText};
  @media (max-width: ${XSBreakPoint}) {
    width: 100%;
    max-width: 1000px;
    padding: 30px 20px 0;
  }
`;
export const ImageCredits = styled.p`
  max-width: 1000px;
  padding: 0 16px !important;
  font: ${Fonts.fontTextItalic};
  color: ${colors.darkGrey};
  text-align: center;
  width: 100%;
  @media (max-width: 1280px) {
    line-height: 24px;
  }
`;

export const ImageCreditsLeft = styled.p`
  font: ${Fonts.fontTextMobile};
  color: ${colors.darkGrey};
  max-width: 1000px;
  padding: 0 16px;
  margin-right: 50px;
  text-align: center;
`;

export const ImageCreditsRight = styled.p`
  font: ${Fonts.fontTextMobile};
  color: ${colors.darkGrey};
  max-width: 1000px;
  margin-left: 50px;
  padding: 0 16px;
`;

export const RightText = styled.div`
  width: 100%;
  max-width: 1000px;
  color: ${colors.black};
  font: ${Fonts.fontText};
  @media (max-width: ${MDBreakPoint}) {
    width: 100%;
    max-width: 1000px;
  }
`;

export const TopImage = styled.img`
  margin: 0 auto;
  height: 90%;
  width: 90%;
  min-width: 90px;
  min-height: 90px;
  max-width: 150px;
  max-height: 150px;
`;

export const MobileImage = styled(Grid)`
  text-align: center;
  margin: 0 auto;
`;
