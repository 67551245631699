import React from 'react';
import '../../index.css';
import { StyledSVG } from '../../styles/partials/SwipeSlide';

interface SVGProps {
  svg: any;
  height?: number;
  width?: number;
}

const SVG = ({ svg, height, width }: SVGProps) => (
  <StyledSVG
    role="img"
    className="wrapperBig"
    src={svg}
    wrapper="span"
    width={width}
    height={height}
  />
);

export default SVG;
