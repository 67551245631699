import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { H2 } from '../Styles';
import { MDBreakPoint } from '../../Theme';

export const BlogsContainer = styled.div`
  background-color: #fff;
  padding: 50px 0;
`;

export const ButtonContainer = styled(Grid)`
  text-align: center !important;
  padding: 56px 0 0;
  @media (max-width: ${MDBreakPoint}) {
    padding: 48px 0 0;
  }
  @media (max-width: ${MDBreakPoint}) {
    padding: 40px 0 0;
  }
`;

export const Container = styled(Grid)`
  margin: 0 auto !important;
  max-width: 1556px !important;
  @media (max-width: 1280px) {
    max-width: 1000px !important;
  }
  @media (max-width: 960px) {
    max-width: 480px !important;
    margin-top: 0 !important;
  }
`;

export const Title = styled(H2)<{ recommendation?: boolean }>`
  text-align: center;
  color: #002864;
  margin: ${({ recommendation }) => (recommendation ? '0 0 40px 0 !important' : '64px 0 40px 0 !important')};
  ${({ recommendation }) => recommendation && 'padding-top:  0 !important'};
  @media (max-width: 1280px) {
    margin: 56px 0 32px 0 !important;
  }

  @media (max-width: 960px) {
    margin: 48px 0 24px 0 !important;
  }

  @media (max-width: 600px) {
    margin: 24px 0 !important;
  }
`;
