export const GRAPHIC_COMPONENT = `
      icon1
      background1
      iconTitle1
      iconText1
      icon2
      background2
      iconTitle2
      iconText2
      icon3
      background3
      iconTitle3
      iconText3
      icon4
      background4
      iconTitle4
      iconText4
      icon5
      background5
      iconTitle5
      iconText5
      icon6
      background6
      iconTitle6
      iconText6
      backgroundLocation1
      backgroundLocation2
      backgroundLocation3
      backgroundLocation4
      backgroundLocation5
      backgroundLocation6
`;
