import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import { colors, MDBreakPoint, XSBreakPoint } from '../../Theme';

export const Container = styled.div`
  padding: 50px 0 25px 0;
  text-align: center;
  width: 100%;
  @media (max-width: ${MDBreakPoint}) {
    padding: 25px 0 0 0;
  }
`;

export const GridItem = styled(Grid)<{ last: boolean }>`
  margin-right: ${({ last }) => (last ? 'none' : '40px !important;')};
  @media (max-width: ${MDBreakPoint}) {
    padding-top: 20px;
  }
  @media (max-width: ${XSBreakPoint}) {
    margin: 20px !important;
    padding-top: 0;
  }
`;

export const Empty = styled(Grid)`
  text-align: center;
  color: ${colors.darkBlue};
`;
