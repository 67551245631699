import styled from 'styled-components';
import { colors, Fonts, MDBreakPoint } from '../../Theme';

interface BorderProps {
  border: boolean;
  fullWidth?: boolean;
}

export const Button = styled.button<BorderProps>`
  background: ${colors.middleBlue};
  border-radius: 40px;
  color: ${colors.white};
  font: ${Fonts.fontButtonText};
  padding: 15px 40px;
  text-align: center;
  display: inline-block;
  border: none;
  margin: 0 auto;
  @media (max-width: ${MDBreakPoint}) {
  }
  :focus {
    outline: none;
  }
  :hover {
    cursor: pointer;
  }
`;
