interface Props {
    __typename?: any;
    url?: string;
    title?: string;
    description?: any;
    ogImage?: string;
    ogAlt?: string;
    ogTitle?: string;
    ogDescription?: string;
    ogSiteName?: string;
    ogType?: string;
    ogLocal?: string;
    ogImageType?: string;
    indexed?: boolean;
}

// Handle the metadata (title and description of pages)
export const metaDataHandler = (
  {
    url,
    title,
    description,
    ogImage,
    ogAlt,
    ogTitle,
    ogDescription,
    ogSiteName,
    ogType,
    ogLocal = 'de_DE',
    ogImageType,
    indexed,
  }: Props,
) => {
  if (ogImage) {
    const img = new Image();
    img.src = ogImage;
    img.onload = () => {
      document.querySelector('meta[property=og\\:image\\:height]')?.setAttribute('content', img.height.toString());
       document.querySelector('meta[property=og\\:image\\:width]')?.setAttribute('content', img.width.toString());
    };
  }
  description &&  document.querySelector('meta[name=description]')?.setAttribute('content', description);
  document.title = title?.includes('https') ? 'PflegePartner' : title || 'PflegePartner';
  title &&  document.querySelector('meta[name=title]')?.setAttribute('content', title);

  ogImage &&  document.querySelector('meta[property=og\\:image]')?.setAttribute('content', ogImage);
  ogImage &&  document.querySelector('meta[property=og\\:image\\:secure]')?.setAttribute('content', ogImage);

  ogTitle &&  document.querySelector('meta[property=og\\:title]')?.setAttribute('content', ogTitle);
  ogDescription
    &&  document.querySelector('meta[property=og\\:description]')?.setAttribute('content', ogDescription);

  url &&  document.querySelector('meta[property=og\\:url]')?.setAttribute('content', url);

  ogSiteName &&  document.querySelector('meta[property=og\\:site_name]')?.setAttribute('content', ogSiteName);
  ogType &&  document.querySelector('meta[property=og\\:type]')?.setAttribute('content', ogType);
  ogLocal &&  document.querySelector('meta[property=og\\:locale]')?.setAttribute('content', ogLocal);

  url &&  document.querySelector('link[rel=canonical]')?.setAttribute('href', url);

  ogImageType
    &&  document.querySelector('meta[property=og\\:image\\:type]')?.setAttribute(
      'content',
      `image:${ogImageType}`,
    );

  ogAlt &&  document.querySelector('meta[property=og\\:image\\:alt]')?.setAttribute('content', ogAlt);
  // const ind = indexed ? 'index' : 'noindex';
  indexed ?  document.querySelector('meta[name=robots]')?.setAttribute('content', 'all') :  document.querySelector('meta[name=robots]')?.setAttribute('content', 'noindex');
};
