// Import Dependencies
import React, {Dispatch, SetStateAction, useEffect} from 'react';
import { useQuery } from '@apollo/client';
// Import Components
import { useLocation } from 'react-router';
import { TextComponent } from '../components/TextComponent';
import TabsComponent from '../components/TabsComponent';
import { CardComponent } from '../components/CardComponent';
import SliderComponent from '../components/SliderComponent';
import IconComponent from '../components/IconComponent';
import { StepsComponent } from '../components/StepsComponent';
import BlogsComponent from '../components/BlogsComponent';
import ZitatComponent from '../components/ZitatComponent';
import { StageComponent } from '../components/StageComponent';
import { metaDataHandler } from '../../utils/MetaInfoHandler';
import { PagePlaceholder } from '../loaders/PagePlaceholder';
import NothingToShow from '../components/NothingToShow';
// Import Variables
import { GET_BLOG } from '../../graphql/queries';
import { Blog } from '../../graphql/queryTemplate/Blog';
import { emptyArt } from '../../mocks/mockBlog';
import icon from '../../assets/bearbeitung.png';

const sortByDate = (a: any, b: any) => new Date(b.savedOn).getTime() - new Date(a.savedOn).getTime();

interface BlogPageProps {
  blogName: string;
  setValue: any;
  stage: any;
  blogMetadata: any;
  meta: any;
  zipFunc: any;
  handleOpenOpenZip?: any;
  setOpenDialog: Dispatch<SetStateAction<boolean>>;
}

const BlogPage = ({
  blogName, setValue, stage, blogMetadata, meta, zipFunc, handleOpenOpenZip, setOpenDialog
}: BlogPageProps) => {
  const blog = Blog();

  const handleZipSlider1 = () => {
    zipFunc.setTitle('Kostenlos registrieren');
    zipFunc.setText(
      '„Mein PflegePartner” bieten wir gemeinsam mit unseren Partnern an. Um fortzufahren, geben Sie bitte Ihre Postleitzahl ein:',
    );
    zipFunc.setButtonText('Senden');
    zipFunc.setUkv(
      'https://pflegepartner.ukv.de/ppd/#/meinpflegepartner/public/registrierung',
    );
    zipFunc.setVkb(
      'https://pflegepartner.vkb.de/ppd/#/meinpflegepartner/public/registrierung',
    );
    handleOpenOpenZip();
  };
  const handleZipSlider2 = () => {
    zipFunc.setTitle('Zum Login');
    zipFunc.setText(
      '„Mein PflegePartner” bieten wir gemeinsam mit unseren Partnern an. Um fortzufahren, geben Sie bitte Ihre Postleitzahl ein:',
    );
    zipFunc.setButtonText('Senden');
    zipFunc.setUkv(
      'https://anmeldung.vkb.de/auth/realms/pflegepartner-test/protocol/openid-connect/auth?client_id=pflegeportal-frontend&redirect_uri=https%3A%2F%2Fpflegepartner.ukv.de%2Fppd%2F%23%2Fukv%2Fportal%2Fdashboard&state=b6b7dfb2-75d0-441c-844a-7bc1a40feaa0&nonce=0ebeb72a-6a33-4fc4-99d5-086b5a38b1cc&response_mode=fragment&response_type=code&scope=openid&login_hint=ukv',
    );
    zipFunc.setVkb(
      'https://anmeldung.vkb.de/auth/realms/pflegepartner-test/protocol/openid-connect/auth?client_id=pflegeportal-frontend&redirect_uri=https%3A%2F%2Fpflegepartner.vkb.de%2Fppd%2F%23%2Fvkb%2Fportal%2Fdashboard&state=be2d4501-836d-4972-8c69-b2f51f233638&nonce=89f75c9c-688c-48c5-8b39-d6dfb8d93435&response_mode=fragment&response_type=code&scope=openid&login_hint=vkb',
    );
    handleOpenOpenZip();
  };

  const skip = !!blog[blogName];
  const { loading } = useQuery(GET_BLOG, {
    variables: { blog: blogName },
    context: {
      headers: {
        cacheIdent: blogName,
      },
    },
    fetchPolicy: 'cache-first',
    skip,
  });
  setValue(4);

  let objects: any = null;
  if (!loading) {
    if (blog[blogName]) {
      objects = Object.values(blog[blogName]).flat().sort(sortByDate);
    }
    const metaTitle = meta.ogTitle || `${blogName} | PflegePartner`;

    metaDataHandler(
      {
        url: `https://pflegepartner.de/blog/${blogName}`,
        title: metaTitle,
        description: stage.description,
        ogImage: blogMetadata.metaImage,
        ogAlt: meta.ogAlt,
        ogTitle: meta.ogTitle,
        ogDescription: meta.ogDescription,
        ogSiteName: meta.ogName,
        // stage.title,
        ogType: meta.ogType,
        indexed: meta.indexed,
      },
    );
  }
  const { pathname, hash } = useLocation();
  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, []);
  return (
    <>
      <StageComponent
        title={stage.title}
        subtitle={stage.subtitle}
        text={stage.text}
        backgroundLg={stage.img}
        backgroundMd={stage.imgMd}
        backgroundXs={stage.imgXs}
        setOpenDialog={setOpenDialog}
      />
      {!loading ? (
        <>
          {objects && objects.length > 0 ? (
            objects.map((element: any) => {
              switch (element.type) {
                case 'text':
                  return (
                    (element.closing
                      || element.introduction
                      || element.leftImage
                      || element.rightImage
                      || element.rightText
                      || element.leftText
                      || element.title
                      || element.subtitle) && <TextComponent {...element} isBlog />
                  );
                case 'slider':
                  return (
                    <SliderComponent
                      {...element}
                      isBlog
                      func={handleZipSlider1}
                      func2={handleZipSlider2}
                    />
                  );
                case 'icons':
                  return <IconComponent {...element} isBlog />;
                case 'tabs':
                  return <TabsComponent {...element} isBlog />;
                case 'steps':
                  return <StepsComponent {...element} isBlog />;
                case 'cards':
                  return <CardComponent {...element} isBlog />;
                case 'zitat':
                  return (
                    (element.closing
                      || element.introduction
                      || element.text
                      || element.title
                      || element.name) && <ZitatComponent {...element} isBlog />
                  );
                default:
              }
            })
          ) : (
            <NothingToShow text={emptyArt} icon={icon} color="blue" />
          )}
        </>
      ) : (
        <PagePlaceholder />
      )}
      <BlogsComponent
        category={{ name: 'Neueste Artikel', icon: null }}
        recommendation
        current={blogName}
      />
    </>
  );
};

export default BlogPage;
