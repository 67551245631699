import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import styled from 'styled-components';
import ListItemText from '@material-ui/core/ListItemText';
import { useLocation } from 'react-router';
import { colors, Fonts } from '../../Theme';

export const drawerWidth = 240;

export const useStyles = makeStyles((theme: Theme) => createStyles({
  root: {
    display: useLocation().pathname === '/bedarfscheck' ? 'none' : 'flex',
    color: 'secondary',
  },
  drawer: {
    [theme.breakpoints.up(1080)]: {
      width: drawerWidth,
      flexShrink: 0,
      display: 'none',
      paddingBottom: 0,
    },
    [theme.breakpoints.down(1080)]: {
      paddingBottom: '50px',
    },
  },
  appBar: {
    [theme.breakpoints.up(1080)]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      display: 'none',
    },
  },
  menuButton: {
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.up(1080)]: {
      display: 'none',
      color: 'blue !important',
    },
  },
  closeButton: {
    marginLeft: '3px',
    marginTop: theme.spacing(1),
  },
  pflegePartner: {
    marginRight: theme.spacing(2),
  },
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
    padding: '0!important',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

export const Pflegepartner = styled.span`
  color: ${colors.darkBlue};
  font: ${Fonts.fontKalamBoldSmall};
  font-size: 16px !important;
`;

export const StlyedListItemText = styled(ListItemText)`
  .MuiTypography-body1 {
    font: ${Fonts.fontMainNavInactive} !important;
    font-size: 16px !important;
    line-height: 20px !important;
  }
  color: ${colors.darkBlue};
`;
