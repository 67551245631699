// Import Dependencies
import React, {Dispatch, SetStateAction, useEffect} from 'react';
import { useLocation } from 'react-router';
import IconComponent from '../components/IconComponent';
import { StageComponent } from '../components/StageComponent';
import { ProcessComponent } from '../components/ProcessComponent';

import { CardComponent } from '../components/CardComponent';
import GraphicComponent from '../components/GraphicComponent';
import SliderComponent from '../components/SliderComponent';
import { WegeLeiten } from '../../graphql/queryTemplate/WegeLeiten';
import { metaDataHandler } from '../../utils/MetaInfoHandler';
import { Metadata } from '../../graphql/queryTemplate/Metadata';
import {
  Module,
  PagePlaceholderStyled,
  Wrapper,
} from '../../styles/pages/WegeLeitenPage';
import { TextComponent } from '../components/TextComponent';
import { Scroller } from '../../utils/Scroller';

interface WegeLeitenProps {
  handleOpenOpenZip: () => void;
  zipFunc: {
    setTitle: any;
    setText: any;
    setButtonText: any;
    setUkv: any;
    setVkb: any;
  };
  setNavigationIndex: (index: number) => void;
  setOpenDialog: Dispatch<SetStateAction<boolean>>;
}

export const WegeLeitenPage = ({
    handleOpenOpenZip,
    zipFunc,
    setNavigationIndex,
    setOpenDialog,
}: WegeLeitenProps) => {
  const handleZipCards = () => {
    zipFunc.setTitle('Pflegebudget berechnen');
    zipFunc.setText(
      'Den Pflegebudget-Rechner bieten wir gemeinsam mit unseren Partnern an. Um fortzufahren, geben Sie bitte Ihre Postleitzahl ein:',
    );
    zipFunc.setButtonText('Senden');
    zipFunc.setUkv(
      'https://pflegepartner.ukv.de/ppd/#/vkb/public/rechnerpb/start',
    );
    zipFunc.setVkb(
      ' https://pflegepartner.vkb.de/ppd/#/vkb/public/rechnerpb/start',
    );
    handleOpenOpenZip();
  };
  const handleZipSlider1 = () => {
    zipFunc.setTitle('Kostenlos registrieren');
    zipFunc.setText(
      '„Mein PflegePartner” bieten wir gemeinsam mit unseren Partnern an. Um fortzufahren, geben Sie bitte Ihre Postleitzahl ein:',
    );
    zipFunc.setButtonText('Senden');
    zipFunc.setUkv(
      'https://pflegepartner.ukv.de/ppd/#/meinpflegepartner/public/registrierung',
    );
    zipFunc.setVkb(
      'https://pflegepartner.vkb.de/ppd/#/meinpflegepartner/public/registrierung',
    );
    handleOpenOpenZip();
  };
  const handleZipSlider2 = () => {
    zipFunc.setTitle('Zum Login');
    zipFunc.setText(
      '„Mein PflegePartner” bieten wir gemeinsam mit unseren Partnern an. Um fortzufahren, geben Sie bitte Ihre Postleitzahl ein:',
    );
    zipFunc.setButtonText('Senden');
    zipFunc.setUkv(
      'https://anmeldung.vkb.de/auth/realms/pflegepartner-test/protocol/openid-connect/auth?client_id=pflegeportal-frontend&redirect_uri=https%3A%2F%2Fpflegepartner.ukv.de%2Fppd%2F%23%2Fukv%2Fportal%2Fdashboard&state=b6b7dfb2-75d0-441c-844a-7bc1a40feaa0&nonce=0ebeb72a-6a33-4fc4-99d5-086b5a38b1cc&response_mode=fragment&response_type=code&scope=openid&login_hint=ukv',
    );
    zipFunc.setVkb(
      'https://anmeldung.vkb.de/auth/realms/pflegepartner-test/protocol/openid-connect/auth?client_id=pflegeportal-frontend&redirect_uri=https%3A%2F%2Fpflegepartner.vkb.de%2Fppd%2F%23%2Fvkb%2Fportal%2Fdashboard&state=be2d4501-836d-4972-8c69-b2f51f233638&nonce=89f75c9c-688c-48c5-8b39-d6dfb8d93435&response_mode=fragment&response_type=code&scope=openid&login_hint=vkb',
    );
    handleOpenOpenZip();
  };
  const handleZipCardBackside = () => {
    zipFunc.setTitle('Zur Patientenverfügung');
    zipFunc.setText(
      '„Mein PflegePartner” bieten wir gemeinsam mit unseren Partnern an. Um fortzufahren, geben Sie bitte Ihre Postleitzahl ein:',
    );
    zipFunc.setButtonText('Senden');
    zipFunc.setUkv(
      'https://ukv.de/patientenverfuegung',
    );
    zipFunc.setVkb(
      'https://www.vkb.de/patientenverfuegung',
    );
    handleOpenOpenZip();
  };
  const metaDescription = 'Sie müssen die Pflege eines Angehörigen organisieren? Der PflegePartner begleitet und unterstützt Sie mit verständlichen Informationen.';
  const metaTitle = 'Hilfe für pflegende Angehörige | PflegePartner';
  const metaProps = {
    url: 'https://pflegepartner.de/',
    title: metaTitle,
    description: metaDescription,
  };
  metaDataHandler(metaProps);

  const MetaData = Metadata();
  const PageData = WegeLeiten();
  !MetaData.isLoading && metaDataHandler(MetaData.wegeleiten);
  const { pathname, hash } = useLocation();
  useEffect(() => setNavigationIndex && setNavigationIndex(0), []);
  return (
    <Wrapper container direction="column" id="top">
      {PageData.isLoading ? (
        <PagePlaceholderStyled />
      ) : (
        <>
          <Scroller hash={hash} />
          <StageComponent {...PageData.nav[0]} setOpenDialog={setOpenDialog}/>
          <Wrapper container direction="column" alignItems="center">
            <Module id="pflegefall" item>
              <ProcessComponent {...PageData.process[0]} elementsInSlide={2} />
            </Module>
            <Module id="bedarfscheck" item>
              <TextComponent {...PageData.textNew[0]} />
            </Module>
            <Module id="finanzierung" item>
              <GraphicComponent {...PageData.graphic[0]} setNavigationIndex={setNavigationIndex} />
            </Module>
            <Module id="angehoerige" item>
              <CardComponent {...PageData.cards[0]} func={handleZipCards} func2={handleZipCardBackside} />
            </Module>
            <Module id="pflegepartner" item>
              <IconComponent {...PageData.icon[0]} setNavigationIndex={setNavigationIndex} />
            </Module>
          </Wrapper>
        </>
      )}
    </Wrapper>
  );
};
