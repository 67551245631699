// Import Dependencies
import React from 'react';
import { Grid } from '@material-ui/core';
// Import Components
import { TextChecker } from '../../utils/RichTextConverter';
import Introduction from '../partials/Introduction';
import Closure from '../partials/Closure';
import {
  Content,
  TopImage,
  InnerWrapper,
  Image,
  LeftText,
  MobileImage,
  OuterWrapper,
  RightText,
  ImageCreditsLeft,
  ImageCreditsRight,
  ImageXs,
} from '../../styles/components/TextComponent';
import RichTextExist from '../../utils/RichTextExist';
// import Variabel

interface TextInterface {
  background: { type: string; url: string };
  topImg?: string;
  title?: string;
  subtitle?: string;
  introduction?: any;
  rightImage?: string;
  leftText?: any;
  rightText?: any;
  leftImage?: string;
  closing?: any;
  button?: any;
  func?: any;
  creditsLeft?: string;
  creditsRight?: string;
  rightImageXs?: string;
  leftImageXs?: string;
  isBlog?: boolean;
}

interface ImagesProps {
  img?: string;
  imgXs?: string;
}
const AdaptiveImage = ({ img, imgXs }: ImagesProps) => {
  const LG = img && img?.length > 2 ? img : imgXs;
  const XS = imgXs && imgXs?.length > 2 ? imgXs : img;

  return (
    <>
      {(img || imgXs) && <Image src={LG} alt="image" />}
      {(img || imgXs) && <ImageXs src={XS} alt="image" />}
    </>
  );
};

export const TextComponent = ({
  background,
  title,
  subtitle,
  introduction,
  rightImage,
  rightImageXs,
  creditsLeft,
  creditsRight,
  leftText,
  rightText,
  leftImage,
  leftImageXs,
  closing,
  button,
  topImg,
  func,
  isBlog,
}: TextInterface) => {
  if (button?.length > 0) {
    if (func) button[0].func = func;
  }
  background = background || { type: 'color', url: 'blue' };
  return (
    <OuterWrapper
      container
      background={background}
      alignItems="center"
      justify="center"
      xs={12}
      isBlog={isBlog}
    >
      <InnerWrapper>
        <Content container alignItems="center" justify="center">
          {topImg && (
            <MobileImage item alignItems="center" justify="center">
              <TopImage src={topImg} height={80} width={100} />
            </MobileImage>
          )}
        </Content>
        {(title || subtitle || introduction) && (
          <Introduction
            title={title}
            subtitle={subtitle}
            introduction={introduction}
            alignText="left"
          />
        )}
        <Content item container alignItems="center" justify="center" xs={12}>
          {(leftImage || RichTextExist(rightText)) && (
            <Content
              item
              container
              alignItems="center"
              justify="center"
              isWithImage={!!leftImage}
            >
              {(leftImage || leftImageXs) && (
                <Grid item xs={RichTextExist(rightText) ? 6 : 12} alignItems="center" justify="center">
                  <AdaptiveImage img={leftImage} imgXs={leftImageXs} />
                  {creditsLeft && creditsLeft.length > 0 && (
                    <ImageCreditsLeft>{creditsLeft}</ImageCreditsLeft>
                  )}
                </Grid>
              )}
              {RichTextExist(rightText) && (
                <Grid item xs={leftImage ? 6 : 12} alignItems="center" justify="center">
                  <RightText>{TextChecker(rightText)}</RightText>
                </Grid>
              )}
            </Content>
          )}

          {(rightImage || RichTextExist(leftText)) && (
            <Content
              item
              container
              alignItems="center"
              justify="center"
              isWithImage={!!rightImage}
            >
              {RichTextExist(leftText) && (
                <Grid item xs={12} alignItems="center" justify="center">
                  <LeftText>{TextChecker(leftText)}</LeftText>
                </Grid>
              )}
              {(rightImage || rightImageXs) && (
                <Grid item xs={12} alignItems="center" justify="center">
                  <AdaptiveImage img={rightImage} imgXs={rightImageXs} />
                  {creditsRight && creditsRight.length > 0 && (
                    <ImageCreditsRight>{creditsRight}</ImageCreditsRight>
                  )}
                </Grid>
              )}
            </Content>
          )}
        </Content>

        {(closing || button?.length > 0) && (
          <Closure buttons={button} closing={closing} buttonsAlign="center" />
        )}
      </InnerWrapper>
    </OuterWrapper>
  );
};
