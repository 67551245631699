import { gql } from '@apollo/client';
import { TEXT_KOMPONENT } from './queryModel/TextComponent';
import { CARD_COMPONENT } from './queryModel/CardComponent';
import { INPAGE_NAVIGATION } from './queryModel/InPageNavigation';
import { BUTTONS } from './queryModel/partials/Button';
import { INTRO_OUTRO } from './queryModel/partials/IntroOutro';
import { BACKGROUND_COLOR } from './queryModel/partials/BackgroundColor';
import { PROCESS_COMPONENT } from './queryModel/ProzessComponent';
import { ICONS_COMPONENT } from './queryModel/IconsComponent';
import { GRAPHIC_COMPONENT } from './queryModel/GraphicComponent';
import { FINANCIAL_CARE } from './queryModel/FinancialCareComponent';
import { CARE_COMPONENT } from './queryModel/CareComponent';
import { STEPS_COMPONENT } from './queryModel/StepsComponent';
import { SLIDER_COMPONENT } from './queryModel/SliderComponent';
import { TABS } from './queryModel/TabComponent';
import { PAGE_METADATA, ABOUT_PAGE_METADATA } from './queryModel/MetaData';
import { BLOGS } from './queryModel/Blogs';
import { TIMESTAMPS } from './queryModel/partials/TimeStamps';
import { ZITAT } from './queryModel/ZitatComponent';

export const GET_META = gql`
    {
        listPzMetadata {
            data {
                ${PAGE_METADATA}
            }
            error {
                  code
                  data
                  message
                }
        }
        listPfMetadata {
            data {
                ${PAGE_METADATA}
            }
            error {
                  code
                  data
                  message
                }
        }
        listWlMetadata {
            data {
                ${PAGE_METADATA}
            }
        }
        listComplianceMatadata {
            data {
                ${PAGE_METADATA}
            }
            error {
                  code
                  data
                  message
                }
        }
        listImpressumMetadata {
            data {
                ${PAGE_METADATA}
            }
            error {
                  code
                  data
                  message
                }
        }
        listUuMetadata{
            data{
                ${ABOUT_PAGE_METADATA}
            }
            error {
                  code
                  data
                  message
                }
        }
        listNavigationElementes {
            data {
                first
                second
                third
                forth
            }
            error {
                  code
                  data
                  message
                }
        }
    }  
`;

export const GET_ABOUT_US = gql`
{
  listInPageNavigation2s {
    data {
        ${INPAGE_NAVIGATION}
        }
        error {
                  code
                  data
                  message
                }
    }
    listKachelKomponente2s {
        data {
            ${BACKGROUND_COLOR}
            ${INTRO_OUTRO}
            ${BUTTONS}
            ${CARD_COMPONENT}
        }
        error {
                  code
                  data
                  message
                }
    }
    listTextKomponente1s{
        data {
            ${BACKGROUND_COLOR}
            ${INTRO_OUTRO}
            ${BUTTONS}
            ${TEXT_KOMPONENT}
        }
        error {
                  code
                  data
                  message
                }
    }
}`;

export const GET_WEGE_LEITEN = gql`
    {
        listInPageNavigation1s {
            data {
                ${INPAGE_NAVIGATION}
            }
            error {
                  code
                  data
                  message
                }
        }
        listWlTextKomponentes {
            data {
                ${BACKGROUND_COLOR}
                ${INTRO_OUTRO}
                ${BUTTONS}
                ${TEXT_KOMPONENT}
            }
            error {
                  code
                  data
                  message
                }
        }
        listProzessKomponentes {
            data {
                ${BACKGROUND_COLOR}
                ${INTRO_OUTRO}
                ${BUTTONS}
               ${PROCESS_COMPONENT}
            }
            error {
                  code
                  data
                  message
                }
        }
        listPflegeOrganisierens{    #Grafik component
            data {
                ${BACKGROUND_COLOR}
                ${INTRO_OUTRO}
                ${BUTTONS}
                ${GRAPHIC_COMPONENT}
            }
            error {
                  code
                  data
                  message
                }
        }
        listIconKomponentes {
          data {
            ${BACKGROUND_COLOR}
            ${INTRO_OUTRO}
            ${BUTTONS}
            ${ICONS_COMPONENT}
          }
          error {
                  code
                  data
                  message
                }
        }
        listSliderKomponentes{
            data {
                ${BACKGROUND_COLOR}
                ${INTRO_OUTRO}
                ${BUTTONS}
                ${SLIDER_COMPONENT}
            }
            error {
                  code
                  data
                  message
                }
        }
        listKachelKomponente1s {
            data {
                ${BACKGROUND_COLOR}
                ${INTRO_OUTRO}
                ${BUTTONS}
                ${CARD_COMPONENT}
            }
            error {
                  code
                  data
                  message
                }
        }
    }`;

export const GET_PFLEGE_FINANZIEREN = gql`{
    listPfInPageNavigations {
        data {
            ${INPAGE_NAVIGATION}
        }
        error {
                  code
                  data
                  message
                }
    }
    listPfTabKomponentes {
        data{
            ${BACKGROUND_COLOR}
            ${INTRO_OUTRO}
            ${BUTTONS}
            ${TABS}
        }
        error {
                  code
                  data
                  message
                }
    }
    listPfTextKomponente1s{
        data{
            ${BACKGROUND_COLOR}
            ${INTRO_OUTRO}
            ${BUTTONS}
            ${TEXT_KOMPONENT}
        }
        error {
                  code
                  data
                  message
                }
    }
    listPfProzessKomponentes {
        data{
            ${BACKGROUND_COLOR}
            ${INTRO_OUTRO}
            ${BUTTONS}
            ${PROCESS_COMPONENT}
        }
        error {
                  code
                  data
                  message
                }
    }
    listPfTextKomponentes {
        data{
            ${BACKGROUND_COLOR}
            ${INTRO_OUTRO}
            ${BUTTONS}
            ${TEXT_KOMPONENT}
        }
        error {
                  code
                  data
                  message
                }
    }
    listPfPflegeKomponentes {
        data{
            ${BACKGROUND_COLOR}
            ${INTRO_OUTRO}
            ${BUTTONS}
            ${CARE_COMPONENT}
        }
        error {
                  code
                  data
                  message
                }
    }
    listGutachterbesuchKomponents {
        data{
            ${BACKGROUND_COLOR}
            ${INTRO_OUTRO}
            ${BUTTONS}
            ${STEPS_COMPONENT}
        }
        error {
                  code
                  data
                  message
                }
    }
    listSliderKomponents {
        data {
            ${BACKGROUND_COLOR}
            ${INTRO_OUTRO}
            ${BUTTONS}
            ${SLIDER_COMPONENT}
        }
    }
    listTextKomponentes{
        data{
            ${BACKGROUND_COLOR}
            ${INTRO_OUTRO}
            ${BUTTONS}
            ${TEXT_KOMPONENT}   
        }
        error {
                  code
                  data
                  message
                }
    }
    listFinanzielBelastungs {
        data {
            ${BACKGROUND_COLOR}
            ${INTRO_OUTRO}
            ${BUTTONS}
            ${FINANCIAL_CARE}
        }
        error {
                  code
                  data
                  message
                }
    }
    listKachelKomponentes {
        data {
            ${BACKGROUND_COLOR}
            ${INTRO_OUTRO}
            ${BUTTONS}
            ${CARD_COMPONENT}
        }
        error {
                  code
                  data
                  message
                }
    }
}`;

export const GET_ZUHAUSE = gql`
    {
    listPzInPageNavigations{
        data {
            ${INPAGE_NAVIGATION}
        }
        error {
                  code
                  data
                  message
                }
    }
    listPzTabKomponentes{
        data {
            ${BACKGROUND_COLOR}
            ${INTRO_OUTRO}
            ${BUTTONS}
            ${TABS}
        }
    }
    listPzProzessKomponentes{
        data {
            ${BACKGROUND_COLOR}
            ${INTRO_OUTRO}
            ${BUTTONS}
            ${PROCESS_COMPONENT}
        }
        error {
                  code
                  data
                  message
                }
    }
    listPflegeUndBetreungsangebote1s{
        data {
            ${BACKGROUND_COLOR}
            ${INTRO_OUTRO}
            ${BUTTONS}
            ${CARD_COMPONENT}
        }
        error {
                  code
                  data
                  message
                }
    }
    listUnterstutzungFurDenAlltag1s{
        data {
            ${BACKGROUND_COLOR}
            ${INTRO_OUTRO}
            ${BUTTONS}
            ${TEXT_KOMPONENT}
        }
        error {
                  code
                  data
                  message
                }
    }
    listMeinPflegePartners{
        data {
            ${BACKGROUND_COLOR}
            ${INTRO_OUTRO}
            ${BUTTONS}
            ${SLIDER_COMPONENT}
        }
        error {
                  code
                  data
                  message
                }
    }
    listMedikamenteHeilmittelUndHilfsmittels{
        data {
            ${BACKGROUND_COLOR}
            ${INTRO_OUTRO}
            ${BUTTONS}
            ${TABS}
        }
        error {
                  code
                  data
                  message
                }
    }
    listWohnungOderHaus {
            data {
                ${BACKGROUND_COLOR}
                ${INTRO_OUTRO}
                ${BUTTONS}
                ${STEPS_COMPONENT}
            }
            error {
                  code
                  data
                  message
                }
        }
    listUmzugInsPflegeheims{
        data {
            ${BACKGROUND_COLOR}
            ${INTRO_OUTRO}
            ${BUTTONS}
            ${TEXT_KOMPONENT}
        }
        error {
                  code
                  data
                  message
                }
    }
    listPzTextKomponentes{
        data {
            ${BACKGROUND_COLOR}
            ${INTRO_OUTRO}
            ${BUTTONS}
            ${TEXT_KOMPONENT}
        }
        error {
                  code
                  data
                  message
                }
    }
}`;

export const GET_BLOGS = gql`{
    listBlogs(limit: 100) {
        data {
            createdOn
            publishDate
            ${BLOGS}
        }
        error {
                  code
                  data
                  message
                }
    }
}`;

export const GET_BLOG = gql`
    query ($blog:String!){
        listBlZitatKomponentes(where:{blog:$blog}){
            data {
                blog
                ${TIMESTAMPS}
                ${BACKGROUND_COLOR}
                ${INTRO_OUTRO}
                ${BUTTONS}
                ${ZITAT}
            }
        },
        listBlTextComponents(where:{blog:$blog}) {
        data {
            blog
            ${TIMESTAMPS}
            ${BACKGROUND_COLOR}
            ${INTRO_OUTRO}
            ${BUTTONS}
            ${TEXT_KOMPONENT}
            creditsLeft
            creditsRight
        }
    }, 
    listBlIconsKomponentes(where:{blog:$blog}){
        data{
            blog
            ${TIMESTAMPS}
            ${BACKGROUND_COLOR}
            ${INTRO_OUTRO}
            ${BUTTONS}
            ${ICONS_COMPONENT}
        }
    },
    listBlKachelKomponentes(where:{blog:$blog}){
        data{
            blog
            ${TIMESTAMPS}
            ${BACKGROUND_COLOR}
            ${INTRO_OUTRO}
            ${BUTTONS}
            ${CARD_COMPONENT}
        }
    },
    listBlSliderKomponentes(where:{blog:$blog}){
        data{
            blog
            ${TIMESTAMPS}
            ${BACKGROUND_COLOR}
            ${INTRO_OUTRO}
            ${BUTTONS}
            ${SLIDER_COMPONENT}
        }
    },
    listBlTabComponents(where:{blog:$blog}){
        data{
            blog
            ${TIMESTAMPS}
            ${BACKGROUND_COLOR}
            ${INTRO_OUTRO}
            ${BUTTONS}
            ${TABS}
        }
    }
    listBlStepsKomponentes(where:{blog:$blog}){
        data{
            blog
            ${TIMESTAMPS}
            ${BACKGROUND_COLOR}
            ${INTRO_OUTRO}
            ${BUTTONS}
            ${STEPS_COMPONENT}
        }
    }
}`;
