// Import Dependencies
import React, { useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
// Import Components
import { ArrowForwardIos, ArrowBackIos } from '@material-ui/icons';
import { Slide } from '../partials/Slide';
import { colors, Fonts } from '../../Theme';
import Introduction from '../partials/Introduction';
import {
  InnerWrapper,
  LeftButton,
  RightButton,
  Slider,
  StyledTab,
  StyledTabs,
  SwipeableContent,
  useStyles,
  Wrapper,
  Image,
  ImageXs,
} from '../../styles/components/SliderComponent';
import Closure from '../partials/Closure';
// Import Vars

interface ImagesProps {
  img?: string;
  imgXs?: string;
  slides?: any;
}

const AdaptiveImage = ({ img, imgXs, slides }: ImagesProps) => {
  const LG = img && img?.length > 2 ? img : imgXs;
  const XS = imgXs && imgXs?.length > 2 ? imgXs : img;

  return (
    <>
      {(img || imgXs) && <Image src={LG} slides={slides} alt="image" />}
      {(img || imgXs) && <ImageXs src={XS} slides={slides} alt="image" />}
    </>
  );
};

interface SlidesProps {
  background: { type: string; url: string };
  title?: string;
  subtitle?: string;
  introduction?: any;
  func?: any;
  func2?: any;
  closing?: any;
  isBlog?: boolean;
  buttons?: { title: string; color: string; url: string }[];
  slides: {
    card: {
      title: string;
      subtitle: string;
      text: any;
      img: string;
      imgXs: string;
      button: any;
    };
    label: string;
  }[];
}

export default function SliderComponent({
  slides,
  title,
  subtitle,
  introduction,
  background,
  closing,
  buttons,
  func,
  func2,
  isBlog,
}: SlidesProps) {
  const theme = useTheme();
  const [value, setValue] = useState(0);
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => setValue(newValue);
  const handleChangeIndex = (index: number) => setValue(index);
  const handleNext = (index: number, direction: string) => (direction === 'l'
    ? setValue((index + slides.length - 1) % slides.length)
    : setValue((index + 1) % slides.length));
  const classes = useStyles();
  return (
    <Wrapper background={background} isBlog={isBlog}>
      {(title || subtitle || introduction) && (
        <Introduction
          title={title}
          subtitle={subtitle}
          introduction={introduction}
          alignText="left"
        />
      )}
      <Slider container direction="row" style={{ margin: '0 auto' }}>
        <LeftButton>
          <ArrowBackIos
            type="button"
            onClick={() => handleNext(value, 'l')}
            style={{
              fontSize: '2rem',
              flex: 3,
              color: colors.lightTurkis,
              cursor: 'pointer',
            }}
          />
        </LeftButton>
        <InnerWrapper>
          <AdaptiveImage img={slides[value].card.img} imgXs={slides[value].card.imgXs} slides={slides[value]} />
          <StyledTabs
            value={value}
            onChange={handleChange}
            scrollButtons="off"
            variant="scrollable"
            classes={{
              indicator: classes.indicator,
              root: classes.root,
            }}
            TabIndicatorProps={{
              style: { color: '#0082FF', background: '#69d2e6' },
            }}
            style={{
              font: Fonts.fontTextLink,
              width: '100%',
              color: '#0082FF',
            }}
          >
            {slides.map((item, index) => (
              <StyledTab
                label={item.label}
                textColor="primary"
                key={index}
                style={{
                  fontSize: '18px',
                  fontFamily: 'Raleway',
                  fontWeight: 'bold',
                }}
              />
            ))}
          </StyledTabs>
          <SwipeableContent
            axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
            index={value}
            onChangeIndex={handleChangeIndex}
          >
            {slides.map((item, index) => {
              if (item.card.button.length !== 0) {
                if (func) {
                  item.card.button[0].func = func;
                }
                if (func2) {
                  item.card.button[1].func = func2;
                }
              }
              return (
                item.card.text
                && item.card.text[0]
                && (item.card.text[0].data.text.length > 2
                  || (item.card.text[0]
                    && item.card.text[0].data.text
                    && item.card.text[0].data.text.length > 2)) && (
                    <Slide
                      key={index}
                      value={value}
                      index={index}
                      dir={theme.direction}
                      card={item.card}
                    />
                )
              );
            })}
          </SwipeableContent>
        </InnerWrapper>
        <RightButton>
          <ArrowForwardIos
            type="button"
            onClick={() => handleNext(value, 'r')}
            style={{
              fontSize: '2rem',
              flex: 3,
              color: colors.lightTurkis,
              cursor: 'pointer',
            }}
          />
        </RightButton>
      </Slider>
      <Closure closing={closing} buttons={buttons} buttonsAlign="center" />
    </Wrapper>
  );
}
