import React from 'react';
import { Grid } from '@material-ui/core';
import { TextChecker } from '../../utils/RichTextConverter';
import RoundIcons from './RoundIcons';
import {
  Bold,
  ContentWrapper,
  DifferenceCosts,
  DifferenceText,
  Grad,
  Wrapper,
  Text,
  Img,
  Line,
  MonthlyCosts,
  PflegeText,
  PflegeTitle,
  Separator,
  Slider,
  StyleLink,
  StyleText,
  TabWrapper,
  TextTabs,
  TitleTabs,
} from '../../styles/partials/SwipeSlide';

interface SwiperProps {
  element: any;
  first: boolean;
  last: boolean;
  isIcon?: boolean;
  isPflege?: boolean;
  isTabs?: boolean;
  isImage?: boolean;
  textHeight?: number;
}
const SwipeSlide = ({
  element,
  first,
  last,
  isIcon,
  isPflege,
  isTabs,
  isImage,
  textHeight,
}: SwiperProps) => (
  <>
    {isIcon && (
      <ContentWrapper datatype="iconNavigation">
        <Grid container direction="column" alignItems="center" justify="center">
          <RoundIcons icon={element.icon} width={50} height={50} />
        </Grid>
        <TextTabs height={textHeight}>{element.text}</TextTabs>
        <Line width={70} />
        <Slider first={first} last={last} icon />
      </ContentWrapper>
    )}
    {isPflege && (
      <Wrapper datatype="tabNavigation">
        <Grad>
          <Img src={element.icon} alt="image" />
          <PflegeTitle>{element.text}</PflegeTitle>
        </Grad>
        <Line width={50} />
        <Slider first={first} last={last} isPflege />
        {element.example && (
          <>
            <div>{element.example}</div>
            <Separator />
            <Bold>Beispiel</Bold>
          </>
        )}
        {!element.costs && (
          <PflegeText align="center">{TextChecker(element.content)}</PflegeText>
        )}
        {element.costs && (
          <>
            <div>{element.content}</div>
            <Separator />
            <MonthlyCosts>{element.costs.monthlyCost}</MonthlyCosts>
            <DifferenceCosts>{element.costs.differenceCost}</DifferenceCosts>
            <DifferenceText>{element.costs.differenceText}</DifferenceText>
          </>
        )}
      </Wrapper>
    )}
    {isTabs && (
      <>
        <TabWrapper datatype="tabNavigation">
          <Grid
            container
            direction="column"
            alignItems="center"
            justify="center"
          >
            {isImage ? (
              <>
                <img src={element.icon} alt="image" />
              </>
            ) : (
              <>
                <RoundIcons icon={element.icon} width={50} height={50} />
              </>
            )}
            <TitleTabs height={textHeight}>{element.text}</TitleTabs>
          </Grid>
          <Line width={isImage ? 70 : 40} />
          <Slider first={first} last={last} />
          <StyleText align={isImage ? 'center' : 'left'} />
          <Text align={isImage ? 'center' : 'left'}>
            {TextChecker(element.content)}
            {element.url && (
              <StyleLink url={element.url} text={element.linkText} />
            )}
          </Text>
        </TabWrapper>
      </>
    )}
  </>
);

export default SwipeSlide;
