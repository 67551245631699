// Import Dependencies
import React from 'react';
import Grid from '@material-ui/core/Grid';

import { TextChecker } from '../../utils/RichTextConverter';
import ButtonsContainer from './ButtonsContainer';
import {
  Desktop,
  Image,
  Text,
  Mobile,
  StyledBox,
  Subtitle,
  TextContainer,
  Title,
  Wrapper,
} from '../../styles/partials/Slide';
/*
 * Import Components
 * Import Variables
 */

interface SlideProps {
  value: number;
  index: number;
  dir: any;
  card: {
    title: string;
    subtitle: string;
    text: any;
    img: string;
    button: any;
  };
}

export const Slide = ({
  card, value, index, dir, ...other
}: SlideProps) => (
  <Wrapper
    role="tabpanel"
    hidden={value !== index}
    id={`full-width-tabpanel-${index}`}
    aria-labelledby={`full-width-tab-${index}`}
    {...other}
  >
    {value === index && (
      <StyledBox p={3} style={{ marginLeft: '0px', paddingLeft: '0px' }}>
        <Desktop>
          <Grid container direction="row" justify="center">
            <Grid item lg={card.img ? 7 : 12} md={card.img ? 6 : 12}>
              <TextContainer>
                <Title>{card.title}</Title>
                <Subtitle>{card.subtitle}</Subtitle>
                <Text>{TextChecker(card.text)}</Text>
                {card.button.length > 0 && (
                  <ButtonsContainer buttons={card.button} />
                )}
              </TextContainer>
            </Grid>
            {card.img && (
              <Grid item lg={5} md={6}>
                <Image src={card.img} alt="image" />
              </Grid>
            )}
          </Grid>
        </Desktop>
        <Mobile>
          <Grid justify="center" container>
            <TextContainer>
              <Title>{card.title}</Title>
              <Subtitle>{card.subtitle}</Subtitle>
              <Text>{TextChecker(card.text)}</Text>
              {card.button.length !== 0 && (
                <ButtonsContainer buttons={card.button} />
              )}
            </TextContainer>
          </Grid>
        </Mobile>
      </StyledBox>
    )}
  </Wrapper>
);
