// Import Dependencies
import React, { useState } from 'react';
import { Tabs } from '@material-ui/core';
// Import Components
import RoundIcons from '../partials/RoundIcons';
import TabContent from '../partials/TabContent';
// Imports Variables
import SwiperWrapper from '../partials/SwiperWrapper';
import { colors } from '../../Theme';
import Introduction from '../partials/Introduction';
import Closure from '../partials/Closure';
import {
  InnerWrapper,
  MobileWrapper,
  OuterWrapper,
  StyledTab,
  TabsWrapper,
  useStyles,
} from '../../styles/components/TabsComponent';

interface TabsProps {
  background: { type: string; url: string };
  title?: string;
  subtitle?: string;
  introduction?: any;
  closing?: any;
  buttons?: any;
  tabs: {
    title: string;
    icon: string;
    text: any;
    closing?: any;
    example?: any;
    costs?: any;
    url?: any;
    linkText?: any;
    datatype?: any;
  }[];
  isRound?: boolean;
  isBlog?: boolean;
}

export const TabsComponent = ({
  title,
  subtitle,
  introduction,
  tabs,
  background,
  closing,
  buttons,
  isRound,
  isBlog,
}: TabsProps) => {
  const [value, setValue] = useState(0);
  const classes = useStyles();
  const sliderTabs = [
    ...tabs.map((element) => ({
      text: element.title,
      icon: element.icon,
      content: element.text,
      closing: element.closing,
      linkText: element?.linkText,
      url: element?.url,
    })),
  ];
  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => setValue(newValue);
  return (
    <OuterWrapper
      container
      alignItems="center"
      direction="column"
      background={background}
      isBlog={isBlog}
    >
      {(title || subtitle || introduction) && (
        <Introduction
          title={title}
          subtitle={subtitle}
          introduction={introduction}
          alignText="left"
        />
      )}
      <InnerWrapper>
        <TabsWrapper container item alignItems="center" direction="column">
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons="off"
            classes={{
              indicator: classes.indicator,
              root: classes.root,
            }}
            style={{
              fontFamily: 'Raleway',
              width: '100%',
              color: colors.middleBlue,
            }}
          >
            {tabs.map((item, index) => (item?.icon ? (
              <StyledTab
                datatype="tabNavigation"
                key={index}
                label={item.title}
                icon={
                    isRound ? (
                      <RoundIcons icon={item.icon} height={60} width={80} />
                    ) : (
                      <img src={item.icon} alt="image" />
                    )
                  }
              />
            ) : (
              <StyledTab key={index} label={item.title} />
            )))}
          </Tabs>
          {tabs.map((item, index) => (
            <TabContent
              value={value}
              index={index}
              content={item.text}
              closing={item.closing}
              example={item.example}
              costs={item.costs}
              linkText={item.linkText}
              url={item.url}
              key={index}
              datatype={item.datatype}
            />
          ))}
        </TabsWrapper>
      </InnerWrapper>
      <MobileWrapper>
        <SwiperWrapper elements={sliderTabs} isTabs />
      </MobileWrapper>
      <Closure buttons={buttons} closing={closing} buttonsAlign="left" />
    </OuterWrapper>
  );
};

export default TabsComponent;
