import styled from 'styled-components';
import { colors, MDBreakPoint, XSBreakPoint } from '../../Theme';
import { ButtonProps } from '../../components/cta/ButtonIcon';

export const Button = styled.button<ButtonProps>`
  background: ${({ index, chosen }) => (chosen === index ? `${colors.darkBlue}` : 'white')};
  border: none;
  outline: none;
  padding: 0px 40px;
  margin: 20px 20px;
  height: 53px;
  justify-content: center;
  border-radius: 100px;
  display: inline-flex;
  align-items: center;
  font: normal normal 600 18px/27px Raleway;
  box-shadow: ${({ index, chosen }) => (chosen === index
    ? '  rgba(0,0,0,0) 0px 3px 6px;'
    : '  rgba(0,0,0,0.16) 0px 3px 6px;')};
  color: ${({ index, chosen }) => (chosen === index ? 'white' : `${colors.middleBlue}`)};
  @media (max-width: ${XSBreakPoint}) {
    margin: 15px 20px;
    padding: 0px 24px;
  }
  @media (max-width: ${MDBreakPoint}) {
    margin: 16px 20px;
    padding: 0px 32px;
  }

  :hover {
    cursor: pointer;
  }
`;
export const Img = styled.img<ButtonProps>`
  margin: 0px 10px 0 -24px;
  margin: 0px 10px 0 0px;
  width: 30px;
  filter: ${({ index, chosen }) => (chosen === index
    ? 'invert(1)'
    : 'invert(35%) sepia(29%) saturate(5162%) hue-rotate(195deg) brightness(100%) contrast(107%)')};

  @media (max-width: ${XSBreakPoint}) {
    margin: 0px 10px 0 0px;
  }
  @media (max-width: ${MDBreakPoint}) {
    margin: 0px 10px 0 0px;
`;

export const ButtonText = styled.p`
  white-space: nowrap;
`;
