import styled, { css } from 'styled-components';
import { Grid } from '@material-ui/core';
import { BackgroundConverter } from '../../utils/BackgroundConverter';
import { InnerWrapperWidth, XSBreakPoint, XSWrapperWidth } from '../../Theme';

interface WrapperProps {
  background: { type: string; url: string };
  isBlog?: boolean;
}
export const Wrapper = styled(Grid)<WrapperProps>`
  padding: ${({ isBlog }) => (isBlog ? '0 16px' : '50px 16px')};
  ${({ background }) => BackgroundConverter(background.type, background.url)}
`;

export const InnerWrapper = styled.div`
  max-width: ${InnerWrapperWidth};
  @media (max-width: ${XSBreakPoint}) {
    max-width: ${XSWrapperWidth};
  }
`;

export const StyledGrid = styled(Grid)`
  margin: 50px 0;
  padding-bottom: 50px;
`;
export const CardsContainer = styled(Grid)<{ isDuo?: boolean }>`
  margin-top: 50px !important;
  ${(props) => props.isDuo
    && css`
      width: 700px !important;
      margin: auto !important;
      padding-top: 50px !important;
      @media (max-width: ${XSBreakPoint}) {
        width: ${XSWrapperWidth}!important;
      }
    `}
  .MuiCard-root {
    border-radius: 12px;
  }
`;
