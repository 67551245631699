export const SLIDER_COMPONENT = `
      tabLabel1
      title1
      subtitle1
      buttonSlideText11
      buttonSlideLink11
      buttonSlideColor11
      buttonSlideText12
      buttonSlideLink12
      buttonSlideColor12
      text1
      img1Xs
      img1
      
      tabLabel2
      title2
      subtitle2
      buttonSlideText21
      buttonSlideLink21
      buttonSlideColor21
      buttonSlideText22
      buttonSlideLink22
      buttonSlideColor22
      text2
      img2
      img2Xs
`;
