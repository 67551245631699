import { Grid } from '@material-ui/core';
import React from 'react';
import { categories } from '../../mocks/mockBlog';
import ButtonIcon from '../cta/ButtonIcon';
import { Categories, Polygon } from '../../styles/pages/BlogsDashboard';

interface Props {
  setCategory: any;
  chosen: number;
}

export const CategoriesComponent = ({ setCategory, chosen }: Props) => (
  <Categories
    container
    xs={12}
    md={11}
    lg={9}
    spacing={2}
    direction="row"
    justify="center"
  >
    {categories.map((category, index) => (
      <Grid container item xs={12} md={4} spacing={3} justify="center">
        <ButtonIcon
          data={category}
          index={index}
          chosen={chosen}
          setCategory={() => setCategory(index)}
        />
      </Grid>
    ))}
    <Polygon />
  </Categories>
);
