import styled from 'styled-components';

export const ListContainer = styled.div`
  display: flex;
  padding: 0;
  margin: 15px 0;
  line-height: 27px;
`;

export const ListItem = styled.li`
  float: right;
  list-style: none;
`;
export const Ul = styled.ul`
  padding: 0;
  a {
    display: block;
  }
`;
export const Ol = styled.ol`
  padding: 0;
  a {
  }
`;
