export const BackgroundConverter = (type?: string, url?: string) => {
  switch (type) {
    case 'color':
      if (url === 'blue') {
        return 'background: #e0f5f9;';
      }
      if (url === 'darkBlue' || url === 'darkblue') {
        return 'background: #69D2E6;';
      }
      return 'background: #ffffff;';
    case 'image':
      return `
          background: url(${url}) no-repeat;
          background-size: cover;
        `;
    default:
      return `
          background: #ffffff;
        `;
  }
};
