import styled from 'styled-components';
import Box from '@material-ui/core/Box';
import {
  colors, Fonts, MDBreakPoint, XSBreakPoint,
} from '../../Theme';
import { BlueLink } from '../../components/cta/BlueLink';
import { H1 } from '../Styles';

export const Wrapper = styled.div`
  max-width: 1000px;
  color: ${colors.black};
  font: ${Fonts.fontText};
  // value for Testing
  @media (max-width: ${MDBreakPoint}) {
    width: 1080px;
  }
  @media (max-width: ${XSBreakPoint}) {
    width: 360px;
  }
`;
export const Bold = styled.div`
  font-weight: 800;
  color: ${colors.darkBlue};
  margin: 10px 0 0 0;
`;
export const Separator = styled.div`
  border-bottom: 2px solid ${colors.darkBlue};
  padding: 10px 0;
  max-width: 150px;
  margin-right: auto;
  margin-left: auto;
`;

// @ts-ignore
export const StyleText = styled(Box)`
  @media (max-width: ${MDBreakPoint}) {
    color: ${colors.darkBlue};
  }
`;
// @ts-ignore
export const Text = styled(Box)`
  @media (max-width: ${MDBreakPoint}) {
    color: ${colors.darkBlue};
  }
`;

export const MonthlyCosts = styled.div`
  color: ${colors.darkBlue};
  margin: 10px 0;
`;
export const DifferenceCosts = styled(H1)`
  color: ${colors.darkBlue};
`;
export const DifferenceText = styled.div`
  color: ${colors.darkBlue};
`;

export const StyleLink = styled(BlueLink)`
  padding: 20px 0 0 20px;
  @media (max-width: ${MDBreakPoint}) {
    padding: 0;
    font-size: 16px !important;
  }
`;
