import { makeVar, ReactiveVar } from "@apollo/client";
import { TabsMapper } from "../../utils/mapper/TabMapper";
import { TextMapper } from "../../utils/mapper/TextMapper";
import { SliderMapper } from "../../utils/mapper/SliderMapper";
import { CardMapper } from "../../utils/mapper/CardMapper";
import { IconsMapper } from "../../utils/mapper/IconsMapper";
import { StepsMapper } from "../../utils/mapper/StepsMapper";
import { ZitatMapper } from "../../utils/mapper/ZitatMapper";

let bl = {};
export const BLOGLIST = {
  listBlZitatKomponentes: {
    read(value: any) {
      bl = {};
      if (value !== undefined && value.data.length > 0) {
        Object.assign(bl, {
          zitats: value.data.map((data: any) => ZitatMapper(data)),
        });
        return Blog({
          ...Blog(),
          [`${value.data[0].blog}`]: bl,
        });
      }
    },
  },
  listBlTextComponents: {
    read(value: any) {
      if (value !== undefined && value.data.length > 0) {
        Object.assign(bl, {
          texts: value.data.map((data: any) => TextMapper(data)),
        });
        return Blog({
          ...Blog(),
          [`${value.data[0].blog}`]: bl,
        });
      }
    },
  },
  listBlTabComponents: {
    read(value: any) {
      if (value !== undefined && value.data.length > 0) {
        Object.assign(bl, {
          tabs: value.data.map((data: any) => TabsMapper(data)),
        });
        return Blog({
          ...Blog(),
          [`${value.data[0].blog}`]: bl,
        });
      }
    },
  },
  listBlSliderKomponentes: {
    read(value: any) {
      if (value !== undefined && value.data.length > 0) {
        Object.assign(bl, {
          slider: value.data.map((data: any) => SliderMapper(data)),
        });
        return Blog({
          ...Blog(),
          [`${value.data[0].blog}`]: bl,
        });
      }
    },
  },
  listBlKachelKomponentes: {
    read(value: any) {
      if (value !== undefined && value.data.length > 0) {
        Object.assign(bl, {
          cards: value.data.map((data: any) => CardMapper(data)),
        });
        return Blog({
          ...Blog(),
          [`${value.data[0].blog}`]: bl,
        });
      }
    },
  },
  listBlIconsKomponentes: {
    read(value: any) {
      if (value !== undefined && value.data.length > 0) {
        Object.assign(bl, {
          icons: value.data.map((data: any) => IconsMapper(data)),
        });
        return Blog({
          ...Blog(),
          [`${value.data[0].blog}`]: bl,
        });
      }
    },
  },
  listBlStepsKomponentes: {
    read(value: any) {
      if (value !== undefined && value.data.length > 0) {
        Object.assign(bl, {
          steps: value.data.map((data: any) => StepsMapper(data)),
        });
        return Blog({
          ...Blog(),
          [`${value.data[0].blog}`]: bl,
        });
      }
    },
  },
};

export const Blog: ReactiveVar<any> = makeVar<any>({
  isLoading: true,
});
