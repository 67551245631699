import styled from "styled-components";
import { Dialog, DialogTitle, TextField, DialogContentText, Grid, Button } from "@material-ui/core";
import { DialogActions } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import {ToggleButtonGroup} from '@material-ui/lab';
import {XSBreakPoint, XLSBreakPoint, XXSBreakPoint, MDBreakPoint} from "../../../../Theme";

export const DialogContainer = styled(Dialog)`
`;

export const ContentContainer = styled(Grid)`
  margin: 20px 0;
  gap: 1rem;
`;

export const LogoContainer = styled(Grid)`
  margin: 20px 0;
  gap: 0.5rem;
  flex-direction: row;
`;

export const Title = styled(DialogTitle)`
  float: left;
  color: #002864;
  
  h2 {
    font-size: 1.5rem !important;
  }

  @media (max-width: ${XLSBreakPoint}) {
    h2 {
      font-size: 1.2rem !important;
    }
  }

  @media (max-width: ${XXSBreakPoint}) {
    h2 {
      font-size: 1rem !important;
    }
  }
`;

export const Link = styled.a`
  text-decoration: none;
  color: #0082FF;
  font: normal normal 600 16px/27px Raleway,SemiBold;
`;

export const Subtitle = styled(DialogTitle)`
  float: left;
  width: unset !important;
  padding: 0 !important;
  color: #002864;
  
  h2 {
    font-size: 0.9rem !important;
  }
`;

export const Icon = styled(CloseIcon)`
  float: right;
  position: absolute;
  top: 10px;
  right: 10px;
  color: #0082FF;
  cursor: pointer;
  font-size: 1.1rem !important;
`;

export const ContentText = styled(DialogContentText)`
  font-size: 0.9rem !important;
  font-weight: 300 !important;
  color: #000000 !important;
`;

export const TextFieldInput = styled(TextField)`
  width: 250px;

  .MuiFormHelperText-root {
    margin-left: 2px;
    margin-right: 2px;
    text-align: center;
    font-size: 12px !important;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    letter-spacing: unset;
  }

  & .Mui-error {
    color: #FF0000FF !important;
  }
  
  .MuiOutlinedInput-root {
    & fieldset {
      border-radius: 20px;
      border-color: #002864;
    }
  }
`;

export const ToggleContainer = styled(ToggleButtonGroup)<{isGenderInvalid: boolean}>`

  .MuiToggleButtonGroup-groupedHorizontal:not(:last-child) {
    padding: 7px 15px;
    border-color: ${(props) => props.isGenderInvalid ? '#FF0000FF' : '#002864'};
    border-top-left-radius: 20px !important;
    border-bottom-left-radius: 20px !important;
    color: ${(props) => props.isGenderInvalid ? '#FF0000FF' : '#000000FF'};

    .MuiToggleButton-label {
      font-size: 0.9rem !important;
      text-transform: capitalize;
    }

    &.Mui-selected {
      background-color: #002864;
      color: white;
    }
    
    :hover {
      background-color: #002864;
      color: white;
    }
  }
  
  .MuiToggleButtonGroup-groupedHorizontal:not(:first-child) {
    padding: 7px 15px;
    border-color: ${(props) => props.isGenderInvalid ? '#FF0000FF' : '#002864'};
    border-top-right-radius: 20px !important;
    border-bottom-right-radius: 20px !important;
    color: ${(props) => props.isGenderInvalid ? '#FF0000FF' : '#000000FF'};

    .MuiToggleButton-label {
      font-size: 0.9rem !important;
      text-transform: capitalize;
    }

    &.Mui-selected {
      background-color: #002864;
      color: white;
    }
    
    :hover {
      background-color: #002864;
      color: white;
    }
  }
`;

export const ButtonContainer = styled(DialogActions)`
  justify-content: center !important;
`;

export const ButtonContained = styled(Button)`
  padding: 10px 40px !important;
  border-radius: 20px !important;
  margin-bottom: 15px !important;
  background-color: #0d7bfd !important;
  color: #ffffff !important;
  font-size: 0.9rem !important;
  font-weight: 510 !important;
  
  span {
    text-transform: none;
  }
`;

export const MediaLetterContainer = styled.img`
  position: absolute;
  width: 50px;
  margin-left: 10px;
  margin-top: -15px;

  @media (max-width: ${XSBreakPoint}) {
    width: 40px;
    margin-top: -10px;
  }
`;

export const MediaFaceContainer = styled.img`
  position: absolute;
  width: 40px;
  margin-left: 10px;
  margin-top: -8px;

  @media (max-width: ${XSBreakPoint}) {
    width: 34px;
    margin-top: -5px;
  }
`;

export const ErrorMessage = styled.div`
  margin-left: 21px !important;
  margin-top: -16px !important;
  color: #FF0000FF;
  font-size: 12px !important;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  font-weight: 400;
`;

export const MediaLogoContainer = styled.img`
  width: 40px;
  @media (max-width: ${XSBreakPoint}) {
    width: 34px;
  }
`;
