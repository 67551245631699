import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import {
  colors, Fonts, MDBreakPoint, XSBreakPoint,
} from '../../Theme';

export const Wrapper = styled(Grid)`
  width: 100%;
  @media (max-width: ${MDBreakPoint}) {
    padding: 0;
    height: 116px;
  }
  @media (max-width: 1080px) {
    padding: 0;
    height: 82px;
  }
  @media (max-width: ${XSBreakPoint}) {
    padding: 0;
    height: 96px;
  }
`;

export const TextWrapper = styled.span`
  max-width: 335px;
  color: ${colors.darkBlue};
  font: ${Fonts.fontText};
  text-align: left;
  padding: 10px 0 20px 10px;
  @media (max-width: ${MDBreakPoint}) {
    padding: 0;
    width: 220px;
    margin-bottom: 24px;
  }
  @media (max-width: 1080px) {
    font: ${Fonts.fontTextSmall};
    margin-bottom: 0px;
  }
  @media (max-width: ${XSBreakPoint}) {
    font-size: 16px;
    width: 190px;
    margin-bottom: 35px;
  }
`;
