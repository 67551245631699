// Import Dependencies
import React, {Dispatch, SetStateAction, useEffect} from 'react';
// Import Components
import { useLocation } from 'react-router';
import { CardComponent } from '../components/CardComponent';
import { StageComponent } from '../components/StageComponent';
import { TextComponent } from '../components/TextComponent';
import { AboutUs } from '../../graphql/queryTemplate/AboutUs';
import { Metadata } from '../../graphql/queryTemplate/Metadata';
import { metaDataHandler } from '../../utils/MetaInfoHandler';
import { PagePlaceholder } from '../loaders/PagePlaceholder';
import {
  InnerWrapper,
  Module,
  useStyles,
  Wrapper,
} from '../../styles/pages/AboutPage';
import { Scroller } from '../../utils/Scroller';

interface Props {
  setValue?: Dispatch<number>;
  setOpenDialog: Dispatch<SetStateAction<boolean>>;
}

export function AboutPage({ setValue, setOpenDialog }:Props) {
  const metaTitle = 'Über die Initiative | PflegePartner';
  const metaDescription = 'Der PflegePartner ist eine unabhängige Plattform und setzt auf ein starkes Netzwerk aus erfahrenen Partner mit Pflege-Expertise.';
  const metaProps = {
    url: 'https://pflegepartner.de/ueber-uns',
    title: metaTitle,
    description: metaDescription,
  };
  metaDataHandler(
    metaProps,
  );
  const { pathname, hash } = useLocation();

  const PageData = AboutUs();
  const MetaData = Metadata();
  !MetaData.isLoading && metaDataHandler(MetaData.about);

  useEffect(() => setValue && setValue(3), []);
  const classes = useStyles();
  return (
    <>
      <Wrapper
        container
        xs={12}
        direction="column"
        classes={{ root: classes.root }}
        id="top"
      >
        {PageData.isLoading ? (
          <PagePlaceholder />
        ) : (
          <>
            <Scroller hash={hash} />
            <StageComponent {...PageData.nav[0]} setOpenDialog={setOpenDialog}/>
            <InnerWrapper
              container
              xs={12}
              direction="column"
              alignItems="center"
            >
              <Module item xs={12}>
                <CardComponent {...PageData.kachel[0]} isAboutUs />
              </Module>
              <Module item xs={12} classes={{ root: classes.root }}>
                <TextComponent {...PageData.text[0]} />
              </Module>
            </InnerWrapper>
          </>
        )}
      </Wrapper>
    </>
  );
}
