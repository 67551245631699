import styled from 'styled-components';
import { Grid } from '@material-ui/core';
import {
  colors, Fonts, MDBreakPoint, XSBreakPoint,
} from '../../Theme';
import { H4 } from '../Styles';

interface WrapperInterface {
  img: string;
  first: boolean;
  last: boolean;
}

export const Wrapper = styled(Grid)`
  max-width: 520px !important;
  min-width: 370px !important;
`;
export const Card = styled(Grid)`
  background: linear-gradient(#0028647f, #0028647f),
    url(${(img: WrapperInterface) => img.img}) no-repeat;
  box-shadow: rgba(0, 0, 0, 0.16) 0 3px 6px;
  padding: 0 0 0 16px;
  height: 320px !important;
  background-size: cover;
  max-width: 520px !important;
  margin: 20px !important;
  @media (max-width: 1280px) {
    max-width: 476px !important;
    margin: 40px 20px auto !important;
  }
  :hover {
    cursor: pointer;
  }
`;

export const Title = styled(H4)`
  text-align: left !important;
  color: ${colors.white} !important;
  margin-bottom: 0;
  max-width: 100%;
  padding: 0 16px 0 0;
  margin-top: 30px;
`;

export const Category = styled.p`
  background-color: rgba(0, 40, 100, 0.6);
  display: inline-block;
  margin: 0 5px 5px !important;
  color: white;
  font: ${Fonts.fontTextMobile};
  font-size: 18px !important;
  padding: 4px 10px 3px;
  @media (max-width: ${MDBreakPoint}) {
    padding: 4px 10px 3px;
  }
  @media (max-width: ${XSBreakPoint}) {
    padding: 2px 10px 3px;
  }
`;
export const StyledMoment = styled(Category)`
  background-color: rgb(105, 210, 230, 0.6);
  color: white;
  font: ${Fonts.fontTextMobile};
  font-size: 18px !important;
  @media (max-width: ${MDBreakPoint}) {
    padding: 4px 10px 3px;
  }
  @media (max-width: ${XSBreakPoint}) {
    padding: 2px 10px 3px;
  }
`;

export const TitleContainer = styled(Grid)`
  max-width: 100%;
`;
export const Tags = styled(Grid)`
  max-width: 400px;
`;
