import React from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';
import { RichTextBlockRenderer, RichTextRenderer } from '@webiny/react-rich-text-renderer';
import reactStringReplace from 'react-string-replace';
import List from '../components/partials/List';
import { BlueLink } from '../components/cta/BlueLink';
import {
  CustomLink,
  H1, H2, H3, H4, H5, H6, StyledLink,
} from '../styles/Styles';
import {
  Paragraph,
  WebinyList, types, Header,
} from './interfaces';

interface RichTextProps {
  styles?: any;
}

const RichText = styled.span`
  font-weight: ${(props: RichTextProps) => props?.styles?.bold && 'bold'};
  font-style: ${(props: RichTextProps) => props?.styles?.italic && 'italic'};
  text-decoration: ${(props: RichTextProps) => props?.styles?.underline && 'underline'};
`;

const InnerLink = (text: any) => {
  const history = useHistory();
  return (
    <BlueLink
      datatype="internal"
      newLine
      text={text.text}
      func={() => {
        history.push(text.href);
        window.location = text.href;
      }}
    />
  );
};

const link = (text: string, href:string, newLine?: boolean, setValue?: any) => {
  if (href && href?.length > 2 && href.split('#')[1]?.length > 3) {
    return <InnerLink text={text} href={href} setValue={setValue} />;
  }
  return (newLine ? (
    <BlueLink datatype="internal" newLine text={text} url={href} />
  ) : (
    <StyledLink
      href={href}
      className="designed-link"
      target={(href.includes('https://pflegepartner.de') && href !== 'https://pflegepartner.de/bedarfscheck') ? '_self' : '_blank'}
      rel="noreferrer"
    >
      {text}
    </StyledLink>
  ));
};
const CustomCardLink = ({ text, openHandler }: {text: any, openHandler: () => void}) => (
  <div style={{ cursor: 'pointer' }} onClick={() => openHandler()}>
    <CustomLink>
      {text}
    </CustomLink>
  </div>
);

const stripHtml = (text: string) => text.replace(/<b>/gi, '').replace(/<\/b>/gi, '')
  .replace(/<i>/gi, '').replace(/<\/i>/gi, '')
  .replace(/<u class="(?:[^"]|"")*">/gi, '')
  .replace(/<\/u>/gi, '');

const getLink = (text: string) => (text.includes('<a href') ? text.split('"').find((text) => text.includes('http')) : undefined);

export const decorateText = (element: Paragraph | WebinyList | Header | string, newLine?: boolean, openHandler?: ()=>void, setValue?: any) => {
  if (typeof element === 'string') {
    element = {
      type: 'paragraph',
      data: {
        text: element,
      },
    } as Paragraph;
  }
  // @ts-ignore
  const linkReplacer = (match: string, index, offset, text: string) => {
    const strippedMatch = stripHtml(match);
    const relatedLinkArr = new RegExp(`<a href=\\\"(?:[^\"]|\"\")*\">${match}<\\/a>`, 'gi').exec(text);
    const href = getLink(relatedLinkArr ? relatedLinkArr[0] : text);
    return href !== undefined && (openHandler && (href?.includes('vkb') || href?.includes('ukv')) ? <CustomCardLink text={strippedMatch} openHandler={openHandler} /> : link(strippedMatch, href as string, newLine, setValue))
  }

  const concatenateLinksAndText = (innerHtml: any) => {
    return innerHtml.map((text:any, n: number) => {
      if (typeof text === "string") {
        return <RichText key={n} dangerouslySetInnerHTML={{__html: text}}></RichText>
      }
      return <RichText key={n}>{text}</RichText>
    });
  }

  const customRenderers: Record<string, RichTextBlockRenderer> = {
    // Override the default renderer for "delimiter" block
    paragraph: (block) => {
      const href = getLink(block.data.text);
      const { text } = block.data;

      const innerHtml = href !== undefined && reactStringReplace(text, /<span href=\"(?:[^"]|"")*"  >([^<]*)<\/a>/ig, (match: string, index, offset) => {
        return linkReplacer(match, index, offset, text.toLowerCase())
      }) || text;
      // @ts-ignore
      return (
          <>
            {typeof innerHtml === "string" && <RichText dangerouslySetInnerHTML={{__html: text}} />}
            {typeof innerHtml !== "string" && concatenateLinksAndText(innerHtml)}
          </>
      )
    },
  };

  return (
    <RichTextRenderer data={[element]} renderers={customRenderers} />
  );
};

export const TextChecker = (textArray: any, openHandler?: any, setValue?: any) => textArray?.map((element: any, index: number) => {
  switch (element.type as types) {
    case 'paragraph':
      let paragraph = element as Paragraph;
      if (element.children && element.children[0]) {
        paragraph = {
          type: 'paragraph',
          data: {
            text: element.children[0].text,
          },
        } as Paragraph;
      }

      return (
        paragraph
              && paragraph.data.text
          && (paragraph.data.text.length > 1) && (
            <p key={index} className="paragraph">{decorateText(paragraph, false, openHandler, setValue)}</p>
        )
      );
    case 'list':
      const listMap = {
        unordered: 'ul',
        ordered: 'ol',
      };
      const olList = element as WebinyList;

      return (
        <List
          key={index}
            // @ts-ignore
          sort={listMap[element.data.style]}
          elements={olList}
        />
      );
    case 'header':
      const header = element as Header;
      const exists: boolean = header && (header.data.text.length > 2 || header.data.text.length > 1);

      if (exists && header.data.level == 1) {
        return (
          <H1>{header.data.text}</H1>
        );
      }

      if (exists && header.data.level == 2) {
        return (
          <H2>{header.data.text}</H2>
        );
      }

      if (exists && header.data.level == 3) {
        return (
          <H3>{header.data.text}</H3>
        );
      }

      if (exists && header.data.level == 4) {
        return (
          <H4>{header.data.text}</H4>
        );
      }

      if (exists && header.data.level == 5) {
        return (
          <H5>{header.data.text}</H5>
        );
      }

      if (exists && header.data.level == 6) {
        return (
          <H6>{header.data.text}</H6>
        );
      }
    default:
      return (
        <>
          unknown
          {element.type}
        </>
      );
  }
});

export default TextChecker;
