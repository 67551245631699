import { makeVar, ReactiveVar } from "@apollo/client";

export const METADATA = {
  listWlMetadata: {
    read(value: any) {
      if (value !== undefined) {
        return Metadata({
          ...Metadata(),
          wegeleiten: {
            ...value.data[0],
            ogImageType: value.data[0].ogImage.split(".")[
              value.data[0].ogImage.split(".").length - 1
            ],
          },
        });
      }
    },
  },
  listPfMetadata: {
    read(value: any) {
      if (value !== undefined) {
        return Metadata({
          ...Metadata(),
          finanzieren: {
            ...value.data[0],
            ogImageType: value.data[0].ogImage.split(".")[
              value.data[0].ogImage.split(".").length - 1
            ],
          },
        });
      }
    },
  },
  listPzMetadata: {
    read(value: any) {
      if (value !== undefined) {
        return Metadata({
          ...Metadata(),
          zuhause: {
            ...value.data[0],
            ogImageType: value.data[0].ogImage.split(".")[
              value.data[0].ogImage.split(".").length - 1
            ],
          },
        });
      }
    },
  },
  listUuMetadata: {
    read(value: any) {
      if (value !== undefined) {
        return Metadata({
          ...Metadata(),
          about: {
            ...value.data[0],
            ogImageType: value.data[0].ogImage.split(".")[
              value.data[0].ogImage.split(".").length - 1
            ],
          },
        });
      }
    },
  },
  listComplianceMatadata: {
    read(value: any) {
      if (value !== undefined) {
        return Metadata({
          ...Metadata(),
          compliance: {
            ...value.data[0],
            ogImageType: value.data[0].ogImage.split(".")[
              value.data[0].ogImage.split(".").length - 1
            ],
          },
        });
      }
    },
  },
  listImpressumMetadata: {
    read(value: any) {
      if (value !== undefined) {
        return Metadata({
          ...Metadata(),
          impressum: {
            ...value.data[0],
            ogImageType: value.data[0].ogImage.split(".")[
              value.data[0].ogImage.split(".").length - 1
            ],
          },
        });
      }
    },
  },
  listPrivPolicyMetadata: {
    read(value: any) {
      if (value !== undefined) {
        return Metadata({
          ...Metadata(),
          privPolicy: {
            ...value.data[0],
            ogImageType: value.data[0].ogImage.split(".")[
              value.data[0].ogImage.split(".").length - 1
            ],
          },
        });
      }
    },
  },
  listNavigationElementes: {
    read(value: any) {
      if (value !== undefined) {
        return Metadata({
          ...Metadata(),
          navigationElements: value.data[0],
        });
      }
    },
  },
};

export const Metadata: ReactiveVar<any> = makeVar<any>({
  wegeleiten: {},
  finanzieren: {},
  zuhause: {},
  about: {},
  impressum: {},
  compliance: {},
  privPolicy: {},

  navigationElements: {},
  isLoading: true,
});
