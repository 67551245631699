import { BackgroundMapper } from "./partials/BackgroundMapper";
import { IntroOutro } from "./partials/IntroOutro";
import { ButtonMapper } from "./partials/ButtonMapper";

export const CardMapper = (obj: any) => {
  const TmpObj = {
    type: "cards",
    ...BackgroundMapper(obj),
    ...IntroOutro(obj),
    buttons: ButtonMapper(obj),
    cards: [
      {
        src: obj.img1,
        text: obj.text1,
        background: obj.backside1,
      },
      {
        src: obj.img2,
        text: obj.text2,
        background: obj.backside2,
      },
      {
        src: obj.img3,
        text: obj.text3,
        background: obj.backside3,
      },
      {
        src: obj.img4,
        text: obj.text4,
        background: obj.backside4,
      },
      {
        src: obj.img5,
        text: obj.text5,
        background: obj.backside5,
      },
      {
        src: obj.img6,
        text: obj.text6,
        background: obj.backside6,
      },
    ],
  };
  function filterArrayValues(arr: any) {
    arr = arr.filter(isEligible);
    return arr;
  }

  function isEligible(value: any) {
    if (value.src !== null || value.text !== null) {
      return value;
    }
  }

  TmpObj.cards = filterArrayValues(TmpObj.cards);
  return TmpObj;
};
