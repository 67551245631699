import styled from 'styled-components';
import {
  colors, Fonts, MDBreakPoint, XSBreakPoint,
} from '../Theme';

export const H1 = styled.h1`
  color: ${colors.darkBlue} !important;
  text-align: center;
  font: ${Fonts.fontH1} !important;
  @media (max-width: ${MDBreakPoint}) {
    font: ${Fonts.fontH1MD} !important;
  }
  @media (max-width: ${XSBreakPoint}) {
    font: ${Fonts.fontH1XS} !important;
  }
`;
export const H2 = styled.h2`
  color: ${colors.darkBlue};
  padding-top: 34px !important;
  text-align: center;
  font: ${Fonts.fontH2} !important;
  @media (max-width: ${MDBreakPoint}) {
    font: ${Fonts.fontH2MD} !important;
  }
  @media (max-width: ${XSBreakPoint}) {
    font: ${Fonts.fontH2XS} !important;
  }
`;
export const H3 = styled.h3`
  color: ${colors.darkBlue};
  text-align: center;
  font: ${Fonts.fontH3} !important;
  @media (max-width: ${MDBreakPoint}) {
    font: ${Fonts.fontH3MD} !important;
  }
  @media (max-width: ${XSBreakPoint}) {
    font: ${Fonts.fontH3XS} !important;
  }
`;
export const H4 = styled.h4`
  color: ${colors.darkBlue};
  text-align: center;
  font: ${Fonts.fontH4} !important;
  @media (max-width: ${MDBreakPoint}) {
    font: ${Fonts.fontH4MD} !important;
  }
  @media (max-width: ${XSBreakPoint}) {
    font: ${Fonts.fontH4XS} !important;
  }
`;
export const H5 = styled.h5`
  color: ${colors.darkBlue};
  text-align: center;
  font: ${Fonts.fontH5} !important;
  @media (max-width: ${MDBreakPoint}) {
    font: ${Fonts.fontH5MD}!important;
  }
  @media (max-width: ${XSBreakPoint}) {
    font: ${Fonts.fontH5XS}!important;
  }
`;
export const H6 = styled.h6`
  color: ${colors.darkBlue};
  text-align: center;
  font: ${Fonts.fontH6}!important;
  @media (max-width: ${MDBreakPoint}) {
    font: ${Fonts.fontH6MD}!important;
  }
  @media (max-width: ${XSBreakPoint}) {
    font: ${Fonts.fontH6XS}!important;
  }
`;

export const StyledLink = styled.a`
  font-family: Raleway;
  font-weight: 600;
  font-size: 18px;
  line-height: 150%/27px;
  color: rgb(0, 130, 255);
  text-decoration: underline;
  @media (max-width: ${XSBreakPoint}) {
    font-size: 16px;
    line-height: 150%/24px;
  }
`;

export const CustomLink = styled.p`
  font-family: Raleway;
  font-weight: 600;
  font-size: 18px;
  line-height: 150%/27px;
  color: rgb(0, 130, 255);
  text-decoration: underline;
  @media (max-width: ${XSBreakPoint}) {
    font-size: 16px;
    line-height: 150%/24px;
  }
`;
