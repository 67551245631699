import { BackgroundMapper } from "./partials/BackgroundMapper";
import { IntroOutro } from "./partials/IntroOutro";
import { ButtonMapper } from "./partials/ButtonMapper";

export const ProcessMapper = (obj: any) => {
  //  TODO URL IS MISSING
  const TmpObj = {
    ...BackgroundMapper(obj),
    ...IntroOutro(obj),
    button: ButtonMapper(obj),
    steps: [
      {
        img: obj.img1,
        title: obj.title1,
        subtitle: obj.subtitle1,
        url: obj.link1,
        link: obj.linkText1,
        text: obj.text1,
        icon: obj.img11,
        iconText: obj.imgText11,
      },
      {
        img: obj.img2,
        title: obj.title2,
        subtitle: obj.subtitle2,
        url: obj.link2,
        link: obj.linkText2,
        text: obj.text2,
        icon: obj.img21,
        iconText: obj.imgText21,
      },
      {
        img: obj.img3,
        title: obj.title3,
        subtitle: obj.subtitle3,
        url: obj.link3,
        link: obj.linkText3,
        text: obj.text3,
        icon: obj.img31,
        iconText: obj.imgText31,
      },
      {
        img: obj.img4,
        title: obj.title4,
        subtitle: obj.subtitle4,
        url: obj.link4,
        link: obj.linkText4,
        text: obj.text4,
        icon: obj.img41,
        iconText: obj.imgText41,
      },
    ],
  };
  function filterArrayValues(arr: any) {
    arr = arr.filter(isEligible);
    return arr;
  }

  function isEligible(value: any) {
    if (value.img !== null || value.title !== null) {
      return value;
    }
  }

  TmpObj.steps = filterArrayValues(TmpObj.steps);
  return TmpObj;
};
