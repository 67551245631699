import styled from 'styled-components';
import DialogTitle from '@material-ui/core/DialogTitle';
import { colors } from '../../Theme';

export const Error = styled.div`
  color: red;
`;
export const Text = styled.div`
  padding: 0 0 30px 0;
`;

export const Title = styled(DialogTitle)`
  color: ${colors.darkBlue};
  .MuiTypography-h6 {
    font-family: Raleway !important;
    font-weight: bold;
  }
`;
