import { ButtonMapper } from './partials/ButtonMapper';
import { BackgroundMapper } from './partials/BackgroundMapper';
import { IntroOutro } from './partials/IntroOutro';
import { Timestamps } from './partials/TimestampMapper';

export const TextMapper = (obj: any) => ({
  type: 'text',
  ...BackgroundMapper(obj),
  ...IntroOutro(obj),
  button: ButtonMapper(obj),
  topImg: obj.imageTop,
  leftImage: obj.leftImage,
  leftImageXs: obj.leftImageXs,
  creditsRight: obj.creditsRight,
  creditsLeft: obj.creditsLeft,
  leftText: obj.leftText,
  rightImage: obj.rightImage,
  rightImageXs: obj.rightImageXs,
  rightText: obj.rightText,
  ...Timestamps(obj),
});
