// Import Dependencies
import React from 'react';
import { Grid } from '@material-ui/core';
// Import Components
import { VerticalStepper } from '../partials/Stepper';
import { TextChecker } from '../../utils/RichTextConverter';
import Introduction from '../partials/Introduction';
import Closure from '../partials/Closure';
import {
  InnerWrapper,
  Wrapper,
  Text,
  Icon,
  Subtitle,
} from '../../styles/components/StepsComponent';
// Import Variables

interface GradientProps {
  background: any;
  title?: string;
  subtitle?: string;
  text?: any;
  introduction?: any;
  icon: { src: string; alt: string; display: boolean };
  subsubtitle?: string;
  closing?: any;
  button?: { text: string; url: string }[];
  elements?: { num: number; text: any }[];
  isBlog?: boolean;
}

export const StepsComponent = ({
  title,
  subtitle,
  text,
  introduction,
  subsubtitle,
  button,
  elements,
  icon,
  closing,
  background,
  isBlog,
}: GradientProps) => (
  <Wrapper
    container
    xs={12}
    justify="center"
    icon={icon.display}
    background={background}
    isBlog={isBlog}
  >
    <InnerWrapper>
      {(title || subtitle || introduction) && (
        <Introduction
          title={title}
          subtitle={subtitle}
          introduction={introduction}
          alignText="left"
        />
      )}

      {text && text[0].data.text !== '' && (
        <Text item>{TextChecker(text)}</Text>
      )}
      <Subtitle>{subsubtitle}</Subtitle>
    </InnerWrapper>
    <Grid item container justify="center" alignItems="center" xs={12}>
      {icon.display && <Icon src={icon.src} alt={icon.alt} />}
      <VerticalStepper elements={elements} />
      <Closure buttons={button} closing={closing} buttonsAlign="center" />
    </Grid>
  </Wrapper>
);
